import { observer } from "mobx-react-lite";
import Modal from "../../../shared/components/Modal";
import { useAppContext } from "../../../shared/functions/Context";
import showModalFromId from "../../../shared/functions/ModalShow";
import DIALOG_NAMES from "../../dialogs/Dialogs";
import OverideDialog from "../../dialogs/overide-dialog/Overide-Dialog";
import { Overrides } from "./Overides"
import { useEffect } from "react";



export const Overider = observer(() => {
    const { store, api } = useAppContext();
    const onCreate = () => {
        showModalFromId(DIALOG_NAMES.OVERIDE.CREATE_OVERIDE_DIALOG);
    }
    useEffect(() => {
        const getCode = async () => {
            try {
                await api.inventory.overide.getAll();
            } catch (error) {
                console.log(error);
            }
        };
        getCode();
    }, [api.inventory.overide])

    return (
        <div className="uk-section leave-analytics-page">
            <div className="uk-container uk-container-large">
                <div className="section-toolbar uk-margin">
                    <h4 className="section-heading uk-heading">Override Codes</h4>
                    <div className="controls">
                        <div className="uk-inline">
                            {store.inventory.overide.all.length === 3 ? (
                                <button disabled className="uk-button uk-button-primary" style={{ backgroundColor: "lightgrey" }} type="button">
                                    Add Code
                                </button>
                            ) : (
                                <button className="uk-button uk-button-primary" onClick={onCreate} type="button">
                                    Add Code
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                <Overrides overrides={store.inventory.overide.all} />
            </div>
            <Modal modalId={DIALOG_NAMES.OVERIDE.CREATE_OVERIDE_DIALOG}>
                <OverideDialog />
            </Modal>
        </div>
    )
})