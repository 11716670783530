import { makeAutoObservable, toJS } from "mobx";
import AppStore from "../stores/AppStore";


export interface ISalesOrderItems {
    itemName: string,
    itemDescription: string
    itemQty: number,
    itemUnitPrice: string;
    discountPercentage: number
}




export interface SalesOrderAttachments {
    attachmentName: string,
    attachmentFile: string
}

export interface ISalesOrder {
    id: string;
    orderNumber: string;
    supplierID: string,
    supplierName: string,
    submittedBy: string,
    orderItems: ISalesOrderItems[],
    attactment: SalesOrderAttachments[],
    total: number,
    vat: number,
    status: string,
    pop: string
}


export const defaultSalesOrder: ISalesOrder = {
    id: "",
    orderNumber: "",
    supplierID: "",
    supplierName: "",
    submittedBy: "",
    orderItems: [],
    attactment: [],
    total: 0,
    vat: 0,
    status: "",
    pop: ""
};

export default class SalesOrder {
    private purchaseOrder: ISalesOrder;

    constructor(private store: AppStore, purchaseOrder: ISalesOrder) {
        makeAutoObservable(this);
        this.purchaseOrder = purchaseOrder;
    }

    get asJson(): ISalesOrder {
        return toJS(this.purchaseOrder);
    }
}