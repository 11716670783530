import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { useAppContext } from "../../../shared/functions/Context";
import { timestampToDate } from "../../../shared/functions/TimestampToDate";
import { CustomCloseAccordion } from "../../shared/custom-accordion/CustomAccordion";

const ApprovedRequestsItem = observer(() => {
  const { store } = useAppContext();

  const userId = store.user.all.map((u) => {
    return u.asJson.uid
  })

  const groupedRequests = store.leave.requests.allApproved.reduce((acc, item) => {
    if (!acc[item.userId]) {
      acc[item.userId] = [item];
    } else {
      acc[item.userId].push(item);
    }
    return acc;
  }, {});

  const userRequests = useMemo(() => {
    let _requests = [];
    for (const uid of userId) {
      if (groupedRequests[uid] !== undefined)
        _requests.push(groupedRequests[uid]);
    }
    return _requests;
  }, [groupedRequests, userId]);

  return (
    <div>
      {userRequests.map((requestsForUser, index) => (
        <div className="uk-card" key={index}>
          <CustomCloseAccordion title={requestsForUser[0].userName} className="uk-margin-bottom">
            <ul className="list-group list-group-flush">
              <table className="leave-table uk-table uk-table-small uk-table-divider uk-table-middle uk-table-responsive">
                <thead className="table-header">
                  <tr>
                    <th className="uk-table-expand">Leave Type</th>
                    <th className="uk-table-expand">From</th>
                    <th className="uk-table-expand">To</th>
                    <th className="uk-table-expand">Days</th>
                    <th></th>
                  </tr>
                </thead>
                {requestsForUser.map((request, index) => (
                  <tbody className="table-body" key={index}>
                    <tr className="row">
                      <td className="leaveType">{request.type}</td>
                      <td className="from">{timestampToDate(request.from)}</td>
                      <td className="to">{timestampToDate(request.to)}</td>
                      <td className="days">{request.days}</td>
                      <td>{request.filePath && (
                        <a href={request.filePath} target="_balnk">View File</a>
                      )}</td>
                      <td>
                        <div className="uk-inline">
                          <button className="uk-button uk-button-default" type="button"><span data-uk-icon="commenting"></span></button>
                          <div data-uk-dropdown="mode: click">{request.reasonForLeave}</div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </ul>
          </CustomCloseAccordion>
        </div>
      ))}
    </div>
  );
});

export default ApprovedRequestsItem;
