import {
  CollectionReference,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  setDoc,
} from "firebase/firestore";
import ILeaveSetting from "../interfaces/ILeaveSetting";
import AppStore from "../stores/AppStore";
import AppApi from "./AppApi";

export default class LeaveSettingApi {
  collectionRef: CollectionReference;
  constructor(
    private api: AppApi,
    private store: AppStore,
    collectionRef: CollectionReference
  ) {
    this.collectionRef = collectionRef;
  }

  async getAll() {
    const q = query(this.collectionRef);

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const items: ILeaveSetting[] = [];
      querySnapshot.forEach((doc) => {
        items.push({ id: doc.id, ...doc.data() } as ILeaveSetting);
      });

      this.store.leave.settings.load(items);
    });

    return unsubscribe;
  }

  async create(data: ILeaveSetting) {
    const docRef = doc(this.collectionRef);
    data.id = docRef.id;

    await setDoc(docRef, data, { merge: true });
  }

  async update(data: ILeaveSetting) {
    const docRef = doc(this.collectionRef, data.id);
    await setDoc(docRef, data, { merge: true });
  }

  async delete(id: string) {
    const docRef = doc(this.collectionRef, id);
    await deleteDoc(docRef);
    this.store.leave.settings.remove(id);
  }
}
