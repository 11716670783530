import { observer } from "mobx-react-lite";
import React from "react";
import { useNavigate } from "react-router";
import { Payroll } from "../../../shared/models/Payroll";

interface IProps {
  payroll: Payroll;
}

const PayrollItem = observer((props: IProps) => {
  const { payroll } = props;
  const navigate = useNavigate();

  const onView = () => {
    navigate(`/c/payroll/fy/${payroll.folderId}/${payroll.id}`);
  };

  return (
    <div className={`payroll-item uk-card uk-card-default uk-card-small uk-card-hover ${payroll.status}`}
      onClick={() => (payroll.status === "completed" ? onView() : null)} >
      <div
        className="body uk-card-body uk-grid-small uk-child-width-1-2@s uk-child-width-1-4@m"
        data-uk-grid >
        <div>
          <h5 className="title">{payroll.title}</h5>
        </div>
        <div>
          <div className="status">
            <p className="label">Status</p>
            <p className="value">
              <span className="status-dot"></span>
              {payroll.status}
            </p>
          </div>
        </div>
        <div>
          <div className="deadline">
            <p className="label">Deadline</p>
            <p className="value">{payroll.deadline}</p>
          </div>
        </div>
        <div className="more">
          <button className="icon-btn" onClick={(e) => e.stopPropagation()}>
            <span data-uk-icon="more-vertical"></span>
          </button>
          <div
            data-uk-dropdown="mode: click; pos: bottom-right"
            className="dropdown">
            <ul className="menu">
              <li
                className="menu-item"
                onClick={() => payroll.status === "completed" ? onView() : null}>
                {payroll.status === "completed" ? "View" : "Payroll is not completed"}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
});

export default PayrollItem;
