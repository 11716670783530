import React from "react";
import IPayrollEmployee, { IPaymentSummary } from "../../../shared/interfaces/IPayrollEmployee";
interface ISummaryProps {
  isExpanded: boolean;
  paymentSummary: IPaymentSummary;
  isExcluded: boolean;
  onExport: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  employee: IPayrollEmployee;
  setEmployee: React.Dispatch<React.SetStateAction<IPayrollEmployee>>;
}
const EmployeePaymentSummary = (props: ISummaryProps) => {
  const { isExpanded, paymentSummary, onExport, employee } = props;

  return (
    <div className={`summary ${isExpanded ? "expanded uk-margin" : ""}`}>
      <h5 className="label">Summary</h5>
      <table className="uk-table uk-table-small uk-table-divider">
        <tbody>
          <tr>
            <td className="item">Basic Salary</td>
            <td className="uk-text-right">
              N${(paymentSummary.grossIncome || 0).toFixed(2)}
            </td>
          </tr>
          <tr>
            <td className="item">Deductions</td>
            <td className="uk-text-right">
              (-) N${(paymentSummary.totalDeductions || 0).toFixed(2)}
            </td>
          </tr>
          <tr>
            <td className="gap" colSpan={2}></td>
          </tr>
          <tr>
            <td className="item">Net Income</td>
            <td className="uk-text-right">
              N${(paymentSummary.netIncome || 0).toFixed(2)}
            </td>
          </tr>
        </tbody>
      </table>
      <div className="bank-account-details uk-card uk-card-outlined uk-card-body uk-card-small">
        <h5 className="label">Banking Details</h5>
        <table className="uk-table uk-table-small uk-table-divider">
          <tbody>
            <tr>
              <td className="item">Bank Name</td>
              <td className="uk-text-right">{employee.bankAccount.bankName}</td>
            </tr>
            <tr>
              <td className="item">Account Number</td>
              <td className="uk-text-right">{employee.bankAccount.accountNumber}</td>
            </tr>
            <tr>
              <td className="item">Branch</td>
              <td className="uk-text-right">{employee.bankAccount.branchName}</td>
            </tr>
            <tr>
              <td className="item">Branch Code</td>
              <td className="uk-text-right">{employee.bankAccount.branchCode}</td>
            </tr>
            <tr>
              <td className="item">Account Type</td>
              <td className="uk-text-right">{employee.bankAccount.accountType}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="footer uk-margin">
        <button
          className="uk-button footer-button uk-margin-small-right"
          onClick={onExport}
        >
          Export PDF
        </button>
      </div>
    </div>
  );
};

export default EmployeePaymentSummary;
