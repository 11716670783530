import Store from "../Store";
import AppStore from "../AppStore";
import { makeObservable, runInAction } from "mobx";
import { LeaveProfile } from "../../models/LeaveProfile";
import ILeaveProfile from "../../interfaces/ILeaveProfile";

export default class LeaveProfilesStore extends Store<
  ILeaveProfile,
  LeaveProfile
> {
  items = new Map<string, LeaveProfile>();

  constructor(store: AppStore) {
    super(store);
    makeObservable(this, {
      allMe: true,
      getByUid: true
    });
    this.store = store;
  }

  load(items: ILeaveProfile[] = []) {
    runInAction(() => {
      items.forEach((item) =>
        this.items.set(item.id, new LeaveProfile(this.store, item))
      );
    });
  }

  // getById(id: string) {
  //   const all = Array.from(this.items.values());
  //   return all.filter((item) => item.asJson.id === id)
  // }

  get all() {
    return Array.from(this.items.values())
  }

  // get all profiles by uid
  getByUid(uid: string) {
    const all = Array.from(this.items.values());
    return all.filter((item) => item.asJson.userId === uid)

  }

  // get all me
  get allMe() {
    const me = this.store.user.meJson;
    if (!me) return [];
    return this.getByUid(me.uid); //me.uid
  }
}
