import {
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  documentId,
  where,
} from "firebase/firestore";
import { db } from "../database/FirebaseConfig";
import IPayrollEmployee, {
  defaultPayrollEmployee,
} from "../interfaces/IPayrollEmployee";
import AppStore from "../stores/AppStore";
import AppApi from "./AppApi";

export default class PayrollEmployeeApi {
  collectionRef: CollectionReference;
  employeeSubCollectionId: string;

  constructor(
    private api: AppApi,
    private store: AppStore,
    collectionRef: CollectionReference,
    employeeSubCollectionId: string
  ) {
    this.collectionRef = collectionRef;
    this.employeeSubCollectionId = employeeSubCollectionId;
  }

  async getAll(payrollBatchId: string) {
    this.store.payroll.employee.removeAll();

    const collectionRef = collection(this.collectionRef, payrollBatchId, this.employeeSubCollectionId);

    const q = query(collectionRef, orderBy("displayName", "asc"));
    const querySnapshot = await getDocs(q);

    const items = querySnapshot.docs.map((doc) => {
      const item = {
        ...defaultPayrollEmployee,
        id: doc.id,
        ...doc.data(),
      } as IPayrollEmployee;
      return item;
    });
    this.store.payroll.employee.load(items);

    const includedUids = querySnapshot.docs.map((doc) => doc.id);
    const excludedUids = await this.getExcludedUids(includedUids);
    this.getAllExcludedUsers(excludedUids);
  }

  private async getAllExcludedUsers(excludedIds: string[]) {
    if (excludedIds.length === 0) return;

    // get batch of 10 excluded users
    let batch = excludedIds.splice(0, 10);

    // do while
    while (batch.length > 0) {
      const q = query(collection(db, "Users"), where(documentId(), "in", [...batch]));

      const querySnapshot = await getDocs(q);
      const items = querySnapshot.docs.map((doc) => {
        const item = {
          ...defaultPayrollEmployee,
          id: doc.id,
          ...doc.data(),
        } as IPayrollEmployee;
        return item;
      });

      this.store.payroll.employee.load(items);

      // get next batch
      batch = excludedIds.splice(0, 10);
    }
  }

  private async getAllUIDs() {
    const docSnap = await getDoc(doc(db, "Metadata", "uids"));

    let uids: string[] = [];
    if (docSnap.exists()) uids = [...docSnap.data().uidArray];
    else uids = [];

    return uids;
  }

  // Get all uids that do not have a payroll employee
  private async getExcludedUids(includedUids: string[]) {
    const uids = await this.getAllUIDs();
    return uids.filter((uid) => !includedUids.includes(uid));
  }

  async getById(payrollBatchId: string, employeeId: string) {

    const collectionRef = collection(this.collectionRef, payrollBatchId, this.employeeSubCollectionId);

    if (this.store.payroll.employee.size !== 0) {
      this.store.payroll.employee.removeAll();
    }
    const unsubscribe = onSnapshot(doc(collectionRef, employeeId), (doc) => {
      if (!doc.exists) return;
      const item: IPayrollEmployee = { ...defaultPayrollEmployee, id: doc.id, ...doc.data(), } as IPayrollEmployee;
      this.store.payroll.employee.load([item]);
    });
    return unsubscribe;
  }


  async create(payrollBatchId: string, data: IPayrollEmployee) {
    const collectionRef = collection(this.collectionRef, payrollBatchId, this.employeeSubCollectionId);

    const docRef = doc(collectionRef, data.uid);
    data.uid = docRef.id;
    await setDoc(docRef, data, { merge: true });
    this.store.payroll.employee.load([data]);
    return data;
  }

  async duplicateAll(copyFromBatchId: string, copyToBatchId: string) {

    const collectionRef = collection(this.collectionRef, copyFromBatchId, this.employeeSubCollectionId);

    const q = query(collectionRef);
    const querySnapshot = await getDocs(q);

    await Promise.all(
      querySnapshot.docs.map(async (doc) => {
        const employee = {
          ...defaultPayrollEmployee,
          id: doc.id,
          ...doc.data(),
        } as IPayrollEmployee;

        await this.api.payroll.employee.duplicate(copyToBatchId, employee);
      })
    );
  }

  async duplicate(payrollBatchId: string, data: IPayrollEmployee) {
    // var payrollMonth = new Date();
    // var month = payrollMonth.getMonth();
    // var year = payrollMonth.getFullYear();
    // var date = new Date(year, month + 1, 0);

    await this.update(payrollBatchId, {
      ...data,
      payrollId: payrollBatchId,
      payrollMonth: new Date().getTime(),
    });
    return data;
  }

  async update(payrollBatchId: string, data: IPayrollEmployee) {
    const collectionRef = collection(
      this.collectionRef,
      payrollBatchId,
      this.employeeSubCollectionId
    );
    const docRef = doc(collectionRef, data.uid);
    await setDoc(docRef, data, { merge: true });
    this.store.payroll.employee.load([data]);
    return data;
  }

  async deleteAll(payrollBatchId: string) {
    const collectionRef = collection(
      this.collectionRef,
      payrollBatchId,
      this.employeeSubCollectionId
    );
    const q = query(collectionRef);
    const querySnapshot = await getDocs(q);

    await Promise.all(
      querySnapshot.docs.map(async (doc) => {
        await deleteDoc(doc.ref);
      })
    );
  }

  async delete(payrollBatchId: string, payrollEmpId: string) {
    const collectionRef = collection(
      this.collectionRef,
      payrollBatchId,
      this.employeeSubCollectionId
    );

    const docRef = doc(collectionRef, payrollEmpId);
    await deleteDoc(docRef);
    this.store.payroll.employee.remove(payrollEmpId);
  }

  async getByUserId(payrollBatchId: string, userId: string) {
    const collectionRef = collection(
      this.collectionRef,
      payrollBatchId,
      this.employeeSubCollectionId
    );
    const unsubscribe = onSnapshot(doc(collectionRef, userId), (doc) => {
      const item: IPayrollEmployee = {
        ...defaultPayrollEmployee,
        id: doc.id,
        ...doc.data(),
      } as IPayrollEmployee;
      this.store.payroll.employee.load([item]);
    });
    return unsubscribe;
  }
}
