import AppStore from "../AppStore";
import { makeObservable, runInAction } from "mobx";
import Store from "../Store";
import Overide, { IOveride } from "../../models/InventoryOveride";

export default class OverideStore extends Store<IOveride, Overide> {
    items = new Map<string, Overide>();

    constructor(store: AppStore) {
        super(store);
        // makeObservable(this, {
        //     items: true,
        //     selected: true,
        // });
        this.store = store;
    }

    load(items: IOveride[] = []) {
        runInAction(() => {
            items.forEach((item) =>
                this.items.set(item.id, new Overide(this.store, item))
            );
        });
    }
}
