const DIALOG_NAMES = {
  PAYROLL: {
    PAYROLL_DIALOG: "payroll-dialog",
    RUN_PAYROLL_DIALOG: "run-payroll-dialog",
    TAX_RATE_DIALOG: "tax-rate-dialog",
    BENEFITS_DIALOG: "benefits-dialog",
    NEW_PAYROLL_EMPLOYEE_DIALOG: "new-payroll-employee-dialog",
    PAYROLL_APPROVAL_DIALOG: "payroll-approval-dialog",
    PAYROLL_FOLDER_DIALOG: "payroll-folder-dialog",
  },

  CUSTOMER: {
    CUSTOMER_APPROVAL_DIALOG: "customer-approve-dialog",
    CUSTOMER_CREDIT_DIALOG: "customer-credit-dialog",
  },

  TEAM: {
    USER_DIALOG: "user-company-dialog",
    DEPARTMENT_DIALOG: "department-company-dialog",
    REGION_DIALOG: "region-company-dialog",
  },

  LEAVE: {
    LEAVE_PROFILE_DIALOG: "leave-profile-dialog",
    RECORD_LEAVE_DIALOG: "record-leave-dialog",
    ENTER_LEAVE_TYPE: "enter-leave-type",
    REJECT_LEAVE: "reject-leave",
  },
  COMFIRM: {
    DELETE_DIALOG: "comfirm-delete-dialog",
  },
  INVOICING: {
    PARTIALLY_PAID_DIALOG: "partially-paid-invoice-dialog",
    PROOF_OF_PAYMENT__DIALOG: "proof-of-payment-dialog",
    CUSTOMER_DIALOG: "customer-dialog",
  },
  INVENTORY: {
    CREATE_SUPPLIER_DIALOG: "create-supplier-dialog",
    CREATE_PURCHASE_ORDER_DIALOG: "create-purchase-order-dialog",
    PURCHASE_ORDER_VIEW: "purchase-order-view",
    PRODUCT_DIALOG: "product-dialog",
    PRODUCT_CATEGORY_DIALOG: "product-category-dialog",
    PRODUCT_VIEW_DIALOG: "product-view-dialog"
  },
  OVERIDE: {
    CREATE_OVERIDE_DIALOG: "create-overide-dialog"
  }
};

export default DIALOG_NAMES;

export const CANVAS_ID_NAMES = {
  INVOICE: {
    INVOICE_GRID_CANVAS: "invoice-grid-canvas",
  },
};
