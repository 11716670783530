import { observer } from "mobx-react-lite";
import ProductCategoryItem from "./ProductCategoryItem";
import { useAppContext } from "../../../shared/functions/Context";
import ErrorBoundary from "../../shared/error-boundary/ErrorBoundary";

const ProductCategoryList = observer(() => {
  const { store } = useAppContext();

  const productCategories = store.inventory.categories.all;

  return (
    <ErrorBoundary>
      <div className="product-category-list">
        <ErrorBoundary>
          <div className="uk-child-width-1-4@m" data-uk-grid>
            {productCategories.map((productCategory) => (
              <div key={productCategory.asJson.id}>
                <ProductCategoryItem productCategory={productCategory.asJson} />
              </div>
            ))}
          </div>
        </ErrorBoundary>

        {/* Empty & not loading */}
        <ErrorBoundary>
          {!productCategories.length && (
            <div className="no-product-categories">
              <p className="uk-text-center">
                Empty (no data) <span>😔</span>
              </p>
            </div>
          )}
        </ErrorBoundary>
      </div>
    </ErrorBoundary>
  );
});

export default ProductCategoryList;
