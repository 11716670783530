import { observer } from "mobx-react-lite";
import { FormEvent, useEffect, useState } from "react";
import { useAppContext } from "../../../shared/functions/Context";
import { hideModalFromId } from "../../../shared/functions/ModalShow";
import IRegion, { defaultRegion } from "../../../shared/interfaces/IRegion";
import DIALOG_NAMES from "../Dialogs";

const RegionDialog = observer(() => {
  const { api, store, ui } = useAppContext();
  const [loading, setLoading] = useState(false);

  const [region, setRegion] = useState<IRegion>({
    ...defaultRegion,
  });

  const onSave = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (store.region.selected) {
        const $region = await api.region.update(region);
        if ($region) store.region.load([$region]);
        ui.snackbar.load({
          id: Date.now(),
          message: "Region updated!",
          type: "success",
        });
      } else {
        await api.region.create(region);
        ui.snackbar.load({
          id: Date.now(),
          message: "Region created!",
          type: "success",
        });
      }
    } catch (error) {
      ui.snackbar.load({
        id: Date.now(),
        message: "Error! Failed to update region.",
        type: "danger",
      });
    }

    store.region.clearSelected();
    setLoading(false);
    hideModalFromId(DIALOG_NAMES.TEAM.REGION_DIALOG);
  };

  useEffect(() => {
    if (store.region.selected) setRegion(store.region.selected);
    else setRegion({ ...defaultRegion });

    return () => {};
  }, [store.region.selected]);

  return (
    <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
      <button
        className="uk-modal-close-default"
        type="button"
        data-uk-close
      ></button>
      <h3 className="uk-modal-title">Region</h3>
      <div className="dialog-content uk-position-relative">
        <div className="reponse-form">
          <form className="uk-form-stacked" onSubmit={onSave}>
            <div className="uk-margin">
              <label className="uk-form-label" htmlFor="form-stacked-text">
                Region name
              </label>
              <div className="uk-form-controls">
                <input
                  className="uk-input uk-form-small"
                  type="text"
                  placeholder="Region name"
                  value={region.name}
                  onChange={(e) =>
                    setRegion({ ...region, name: e.target.value })
                  }
                  required
                />
              </div>
            </div>
            <div className="footer uk-margin">
              <button className="uk-button secondary uk-modal-close">
                Cancel
              </button>
              <button className="uk-button primary" type="submit">
                Save
                {loading && <div data-uk-spinner="ratio: .5"></div>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
});

export default RegionDialog;
