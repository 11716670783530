import { collection } from "firebase/firestore";
import { db } from "../database/FirebaseConfig";
import AppStore from "../stores/AppStore";
import AppApi from "./AppApi";
import LeaveProfilesApi from "./LeaveProfilesApi";
import LeaveRequestApi from "./LeaveRequestApi";
import LeaveSettingApi from "./LeaveSettingsApi";

export default class LeaveApi {
  private leaveRequestDB = collection(db, "Leave-requests");
  private leaveSettingsDB = collection(db, "Leave-settings");
  private leaveProfilesDB = collection(db, "Leave-profiles");

  requests: LeaveRequestApi;
  settings: LeaveSettingApi;
  profiles: LeaveProfilesApi;

  constructor(api: AppApi, store: AppStore) {
    this.requests = new LeaveRequestApi(api, store);
    this.settings = new LeaveSettingApi(api, store, this.leaveSettingsDB);
    this.profiles = new LeaveProfilesApi(api, store, this.leaveProfilesDB);
  }
}
