import React, { ChangeEvent } from "react";
import { observer } from "mobx-react-lite";
import IPayrollEmployee from "../../../shared/interfaces/IPayrollEmployee";
import IDepartment from "../../../shared/interfaces/IDepartment";
import IRegion from "../../../shared/interfaces/IRegion";
import { dateFormat_YY_MM_DY } from "../../../shared/functions/TimestampToDate";

interface IProps {
  employee: IPayrollEmployee;
  setEmployee: React.Dispatch<
    React.SetStateAction<IPayrollEmployee | undefined>
  >;
  departments: IDepartment[];
  regions: IRegion[];
}

const PersonalDetails = observer((props: IProps) => {
  const { employee, setEmployee, departments, regions } = props;

  const onChange = (name: "employmentDate" | "payrollMonth" | "position" | "salaryGrade", value: string | number | any) => {
    if (name === "employmentDate") {
      employee.employmentDate = value;
    }
    if (name === "payrollMonth") {
      employee.payrollMonth = value;
    }
    if (name === "position") {
      employee.position = value;
    }
    if (name === "salaryGrade") {
      employee.salaryGrade = value;
    }
    setEmployee({ ...employee });
  };

  const onRegionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setEmployee({
      ...employee,
      regionId: e.target.value,
      regionName: e.target.options[e.target.selectedIndex].text,
    });
  };

  const onDepartmentChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setEmployee({
      ...employee,
      departmentId: e.target.value,
      departmentName: e.target.options[e.target.selectedIndex].text,
    });
  };

  return (
    <div className="bank-account-details uk-card uk-card-body uk-card-small">
      <table className="uk-table uk-table-small uk-table-divider">
        <tbody>
          <tr>
            <td className="item">First Name</td>
            <td className="uk-text-right">
              <input
                className="uk-input uk-form-small"
                type="text"
                value={employee.firstName}
                onChange={(e) => setEmployee({ ...employee, firstName: e.target.value })}
              />
            </td>
          </tr>
          <tr>
            <td className="item">Last Name</td>
            <td className="uk-text-right">
              <input
                className="uk-input uk-form-small"
                type="text"
                value={employee.lastName}
                onChange={(e) => setEmployee({ ...employee, lastName: e.target.value })}
              />
            </td>
          </tr>
          <tr>
            <td className="item">Identity Number</td>
            <td className="uk-text-right">
              <input
                className="uk-input uk-form-small"
                type="text"
                value={employee.identityNo}
                onChange={(e) => setEmployee({ ...employee, identityNo: e.target.value })}
              />
            </td>
          </tr>
          <tr>
            <td className="item">Payroll Month</td>
            <td className="uk-text-right">
              <input
                className="uk-input uk-form-small"
                type="date"
                value={dateFormat_YY_MM_DY(employee.payrollMonth)}
                onChange={(e) => setEmployee({ ...employee, payrollMonth: new Date(e.target.value).getTime() })}
              />
            </td>
          </tr>
          <tr>
            <td className="item">Employment Date</td>
            <td className="uk-text-right">
              <input
                className="uk-input uk-form-small"
                type="date"
                value={dateFormat_YY_MM_DY(employee.employmentDate)}
                name={"employmentDate"}
                onChange={(e) => setEmployee({ ...employee, employmentDate: new Date(e.target.value).getTime() })}
              />
            </td>
          </tr>
          <tr>
            <td className="item">Position</td>
            <td className="uk-text-right">
              <input
                className="uk-input uk-form-small"
                type="text"
                value={employee.position}
                name={"position"}
                onChange={(e) => onChange("position", e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td className="item">Department</td>
            <td className="uk-text-right">
              <select
                className="uk-select uk-form-small"
                value={employee.departmentId}
                onChange={onDepartmentChange}
                required
              >
                <option hidden></option>
                {departments.map((d) => (
                  <option value={d.id} key={d.id}>
                    {d.name}
                  </option>
                ))}
              </select>
            </td>
          </tr>
          <tr>
            <td className="item">Region</td>
            <td className="uk-text-right">
              <select
                className="uk-select uk-form-small"
                value={employee.regionId}
                onChange={onRegionChange}
                required
              >
                <option hidden></option>
                {regions.map((r) => (
                  <option value={r.id} key={r.id}>
                    {r.name}
                  </option>
                ))}
              </select>
            </td>
          </tr>
          <tr>
            <td className="item">Salary Grade</td>
            <td className="uk-text-right">
              <input
                className="uk-input uk-form-small"
                type="text"
                value={employee.salaryGrade}
                name={"salaryGrade"}
                onChange={(e) => onChange("salaryGrade", e.target.value)}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
});

export default PersonalDetails;
