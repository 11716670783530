import React, { Dispatch, SetStateAction } from "react";
import ITaxRate from "../../../../shared/interfaces/ITaxRate";

interface IProps {
  taxRate: ITaxRate;
  setTaxRate: Dispatch<SetStateAction<ITaxRate>>;
}
const PayrollForm = (props: IProps) => {
  const { taxRate, setTaxRate } = props;

  return (
    <div className="uk-grid-small uk-child-width-1-1" data-uk-grid>
      <div className="uk-width-1-2">
        <div className="uk-margin">
          <label className="uk-form-label" htmlFor="form-stacked-text">
            Minimum Income
          </label>
          <div className="uk-form-controls">
            <input
              className="uk-input uk-form-small"
              type="text"
              placeholder="Minimum taxable income."
              value={taxRate.taxableIncomeMin}
              onChange={(e) =>
                setTaxRate({
                  ...taxRate,
                  taxableIncomeMin: Number(e.target.value) || 0,
                })
              }
              required
            />
          </div>
        </div>
      </div>

      <div className="uk-width-1-2">
        <div className="uk-margin">
          <label className="uk-form-label" htmlFor="form-stacked-text">
            Maximum Income
          </label>
          <div className="uk-form-controls">
            <input
              className="uk-input uk-form-small"
              type="text"
              placeholder="Maximum taxable income."
              value={taxRate.taxableIncomeMax}
              onChange={(e) =>
                setTaxRate({
                  ...taxRate,
                  taxableIncomeMax: Number(e.target.value) || 0,
                })
              }
              required
            />
          </div>
        </div>
      </div>

      <div>
        <div className="uk-margin">
          <label className="uk-form-label" htmlFor="form-stacked-text">
            Base Rate
          </label>
          <div className="uk-form-controls">
            <input
              className="uk-input uk-form-small"
              type="text"
              placeholder="Base amount."
              value={taxRate.baseRate}
              onChange={(e) =>
                setTaxRate({
                  ...taxRate,
                  baseRate: Number(e.target.value) || 0,
                })
              }
              required
            />
          </div>
        </div>
      </div>

      <div>
        <div className="uk-margin">
          <label className="uk-form-label" htmlFor="form-stacked-text">
            Percentage (%)
          </label>
          <div className="uk-form-controls">
            <input
              className="uk-input uk-form-small"
              type="text"
              placeholder="e.g. 18 % of the amount exceeding NAD50,000"
              value={taxRate.percentage}
              onChange={(e) =>
                setTaxRate({
                  ...taxRate,
                  percentage: Number(e.target.value) || 0,
                })
              }
              required
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayrollForm;
