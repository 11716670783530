import { useEffect, useState } from "react";
import DIALOG_NAMES from "../../dialogs/Dialogs";
import ProductCategoryDialog from "../../dialogs/product-categories/ProductCategoryDialog";
import ErrorBoundary from "../../shared/error-boundary/ErrorBoundary";
import Modal from "../../../shared/components/Modal";
import { LoadingEllipsis } from "../../../shared/components/Loading";
import ProductCategoryList from "./ProductCategoryList";
import { useAppContext } from "../../../shared/functions/Context";
import { observer } from "mobx-react-lite";
import showModalFromId from "../../../shared/functions/ModalShow";
import { useNavigate } from "react-router-dom";

interface ToolBarProps {
  onCreate: () => void;
  onBack: () => void;
}

const ToolBar = (props: ToolBarProps) => {
  const { onCreate, onBack } = props;
  return (
    <div className="section-toolbar uk-margin">
      <h4 className="section-heading uk-heading go-back" onClick={onBack}>
        <span data-uk-icon="chevron-left"></span>Product Categories
      </h4>
      <div className="controls">
        <button
          className="uk-button primary uk-margin-small-right"
          onClick={onCreate}
        >
          Create category
        </button>
      </div>
    </div>
  );
};

const ProductCategories = observer(() => {
  const { api } = useAppContext();
  const [loading, setLoading] = useState(false);

  const handleNewCategory = () => {
    showModalFromId(DIALOG_NAMES.INVENTORY.PRODUCT_CATEGORY_DIALOG);
  };

  const navigate = useNavigate();

  const handleBack = () =>{
    navigate("./");
  }

  useEffect(() => {
    const loadAll = async () => {
      setLoading(true);
      try {
        await api.inventory.categories.getAll();
      } catch (error) {

      }
      setLoading(false);
    };
    loadAll();
  }, [api.inventory.categories]);

  return (
    <ErrorBoundary>
      <div className="admin-settings uk-section uk-section-small">
        <div className="uk-container uk-container-xlarge">
          <div className="sticky-top">
            <ErrorBoundary>
              <ToolBar onCreate={handleNewCategory} onBack={handleBack}/>
            </ErrorBoundary>
          </div>

          <ErrorBoundary>
            {!loading && (
              <div className="uk-margin">
                <ProductCategoryList />
              </div>
            )}
          </ErrorBoundary>

          {/* Loading */}
          <ErrorBoundary>{loading && <LoadingEllipsis />}</ErrorBoundary>
        </div>
      </div>

      {/* Modals */}
      <ErrorBoundary>
        <Modal modalId={DIALOG_NAMES.INVENTORY.PRODUCT_CATEGORY_DIALOG}>
          <ProductCategoryDialog />
        </Modal>
      </ErrorBoundary>
    </ErrorBoundary>
  );
});

export default ProductCategories;