import { runInAction } from "mobx";
import { Snackbar } from "../../models/Snackbar";
import ISnackbar from "../../interfaces/ISnackbar";
import UiStore from "../UiStore";

export default class SnackbarStore {
  protected store: UiStore;
  items = new Map<number, Snackbar>();

  constructor(store: UiStore) {
    this.store = store;
  }

  load(snack: ISnackbar) {
    runInAction(() => {
      this.items.set(snack.id, new Snackbar(snack));
    });
  }

  remove(id: number) {
    runInAction(() => {
      if (this.items.has(id)) this.items.delete(id);
    });
  }

  get snackbars() {
    return Array.from(this.items.values());
  }
}
