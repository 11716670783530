import Store from "../Store";
import AppStore from "../AppStore";
import { makeObservable, runInAction } from "mobx";
import { LeaveSetting } from "../../models/LeaveSetting";
import ILeaveSetting from "../../interfaces/ILeaveSetting";
import IDepartment from "../../interfaces/IDepartment";

export default class LeaveSettingsStore extends Store<
  ILeaveSetting,
  LeaveSetting
> {
  items = new Map<string, LeaveSetting>();
  selectedDepartment: IDepartment | null = null;

  constructor(store: AppStore) {
    super(store);
    makeObservable(this, {
      selectedDepartment: true,
    });
    this.store = store;
  }

  load(items: ILeaveSetting[] = []) {
    runInAction(() => {
      items.forEach((item) =>
        this.items.set(item.id, new LeaveSetting(this.store, item))
      );
    });
  }

  allDeparmentLeaves(departmentId: string) {
    const list = Array.from(this.items.values());
    return list.filter((item) => item.departmentId === departmentId);
  }

  get allCommonLeaves() {
    const list = Array.from(this.items.values());
    return list.filter((item) => item.scope === "COMMON_LEAVE");
  }

  selectDepartment(department: IDepartment) {
    this.selectedDepartment = department;
  }

  clearSelectedDepartment() {
    this.selectedDepartment = null;
  }
}
