import { observer } from "mobx-react-lite";
import { PayrollEmployee } from "../../../shared/models/PayrollEmployee";
import EmployeeItem from "./EmployeeItem";
import { LoadingEllipsis } from "../../../shared/components/Loading";
import NoData from "../../../shared/components/no-data/NoData";

interface IProps {
  loading: boolean;
  payrollId: string;
  employees: PayrollEmployee[];
  status: string;
}
const EmployeesList = observer((props: IProps) => {
  const { payrollId, employees, status, loading } = props;

  if (loading) return (
    <div className="uk-card uk-card-default uk-card-body">
      <LoadingEllipsis />
    </div>
  )

  return (
    <div className="employee-list">
      {employees.map((employee) => (
        <div className="uk-margin-small" key={employee.uid}>
          <EmployeeItem
            key={employee.uid}
            payrollId={payrollId}
            _employee={employee.asJson}
            isExcluded={employee.isExcluded}
            status={status}
          />
        </div>
      ))}
      {!loading && employees.length === 0 && (
        <div className="uk-card uk-card-default uk-card-body">
          <NoData />
        </div>
      )}
    </div>
  );
});

export default EmployeesList;
