import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import Loading from "../../../shared/components/Loading";
import NoData from "../../../shared/components/no-data/NoData";
import { useAppContext } from "../../../shared/functions/Context";
import showModalFromId from "../../../shared/functions/ModalShow";
import DIALOG_NAMES from "../../dialogs/Dialogs";
import PayrollFolderList from "./PayrollFolderList";

const Folders = observer(() => {
  const { api, store } = useAppContext();
  const [loading, setLoading] = useState(false);

  const onCreateFolder = () => {
    store.payrollfolder.clearSelected();
    showModalFromId(DIALOG_NAMES.PAYROLL.PAYROLL_FOLDER_DIALOG);
  };

  useEffect(() => {
    setLoading(true);
    const loadAll = async () => {
      try {
        await api.payrollfolder.getAll();
      } catch (error) { }
    };
    loadAll();
    setLoading(false);
  }, [api.payrollfolder]);


  if (loading)
    return (
      <div className="uk-card uk-card-default uk-card-body">
        <Loading />
      </div>
    )

  return (
    <div className="uk-section folder-page">
      <div className="uk-container uk-container-large">
        <div className="section-toolbar uk-margin uk-flex-between">
          <div>
          </div>
          <div className="controls">
            <button
              className="uk-button primary uk-margin-small-right"
              onClick={onCreateFolder} >
              Create Folder
            </button>
          </div>
        </div>
        {!loading && <PayrollFolderList folders={store.payrollfolder.all} />}
        {store.payrollfolder.all.length === 0 && (
          <div className="uk-card uk-card-default uk-card-body">
            <NoData />
          </div>
        )}
      </div>
    </div>
  );
});

export default Folders;
