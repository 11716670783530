import Store from "../Store";
import AppStore from "../AppStore";
import { runInAction, toJS } from "mobx";
import { PayrollEmployee } from "../../models/PayrollEmployee";
import IPayrollEmployee from "../../interfaces/IPayrollEmployee";

export default class PayrollEmployeesStore extends Store<IPayrollEmployee, PayrollEmployee> {
  items = new Map<string, PayrollEmployee>();

  constructor(store: AppStore) {
    super(store);
    this.store = store;
  }

  load(items: IPayrollEmployee[] = []) {
    runInAction(() => {
      items.forEach((item) =>
        this.items.set(item.uid, new PayrollEmployee(this.store, item))
      );
    });
  }

  get allSortedByDisplayName() {
    return (Array.from(toJS(this.items.values())).sort((a, b) =>
      a.lastName.localeCompare(b.lastName))
    );
  }

  getById(uid: string) {
    return this.items.get(uid);
  }
}
