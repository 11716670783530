import AppStore from "../AppStore";
import BenefitsStore from "../individualStore/BenefitsStore";
import PayrollBatchStore from "../individualStore/PayrollBatchStore";
import PayrollEmployeesStore from "../individualStore/PayrollEmployeesStore";
import TaxRateStore from "../individualStore/TaxRateStore";

export default class PayrollStore {
  batch: PayrollBatchStore;
  employee: PayrollEmployeesStore;
  taxRate: TaxRateStore;
  benefits: BenefitsStore;

  constructor(store: AppStore) {
    this.batch = new PayrollBatchStore(store);
    this.employee = new PayrollEmployeesStore(store);
    this.taxRate = new TaxRateStore(store);
    this.benefits = new BenefitsStore(store);
  }
}
