import React from "react";
import TaxTab from "./TaxTab";

interface IProps {
  tab: "Scheme" | "Rates";
  setTab: React.Dispatch<React.SetStateAction<"Scheme" | "Rates">>;
}
const TaxTabs = (props: IProps) => {
  const { tab, setTab } = props;
  const setRates = () => {
    setTab("Rates");
  };
  // const setScheme = () => {
  //   setTab("Scheme");
  // };

  return (
    <div
      className="uk-grid-small uk-child-width-1-2 uk-grid-match"
      data-uk-grid
    >
      <div>
        <TaxTab
          isActive={tab === "Rates"}
          title="Tax Rates"
          description="A tax rate is the percentage at which an individual or corporation is taxed."
          onClick={setRates}
        />
      </div>
      {/* <div>
        <TaxTab
          isActive={tab === "Scheme"}
          title="Tax Scheme"
          description="A tax scheme is the Income that is subjected to specific tax rules/assumptions/policies, e.g. Medical Aid, House Allowance Tax."
          onClick={setScheme}
        />
      </div> */}
    </div>
  );
};

export default TaxTabs;
