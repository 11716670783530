import {
  CollectionReference,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore";
import ICustomer from "../interfaces/ICustomer";
import AppStore from "../stores/AppStore";
import AppApi from "./AppApi";

export default class CustomerApi {
  collectionRef: CollectionReference;

  constructor(
    private api: AppApi,
    private store: AppStore,
    collectionRef: CollectionReference
  ) {
    this.collectionRef = collectionRef;
  }

  async getAll() {
    const q = query(this.collectionRef, orderBy("createdOn", "desc"));
    const unsubscribe = await onSnapshot(q, (querySnapshot) => {
      const items: ICustomer[] = [];
      querySnapshot.forEach((doc) => {
        items.push({ id: doc.id, ...doc.data() } as ICustomer);
      });
      this.store.invoicing.customer.load(items);
    });
    return unsubscribe;
  }

  async getById(id: string) {
    const unsubscribe = onSnapshot(doc(this.collectionRef, id), (doc) => {
      const item: ICustomer = { id: doc.id, ...doc.data() } as ICustomer;

      this.store.invoicing.customer.load([item]);
    });
    return unsubscribe;
  }

  async create(data: ICustomer) {
    const docRef = doc(this.collectionRef);
    data.id = docRef.id;
    await setDoc(docRef, data, { merge: true });
    return data;
  }

  async update(data: ICustomer) {
    const docRef = doc(this.collectionRef, data.id);
    await setDoc(docRef, data, { merge: true });
    return data;
  }

  async delete(id: string) {
    const docRef = doc(this.collectionRef, id);
    // Delete the customer
    await deleteDoc(docRef);

    // Remove invoice from store
    this.store.invoicing.customer.remove(id);

    // Delete the invoices
    // await this.api.payroll.employee.deleteAll(id);
  }
}
