import { FormEvent, useEffect, useState } from "react";
import { useAppContext } from "../../../shared/functions/Context";
import { hideModalFromId } from "../../../shared/functions/ModalShow";
import DIALOG_NAMES from "../Dialogs";
import { observer } from "mobx-react-lite";
import ProductForm from "./ProductForm";
import { IInventoryProduct, IProductDescriptionFormItems, defaultInventoryProduct, defaultProductDescriptionForm, defaultProductDescriptionFormItems } from "../../../shared/models/InventoryProduct";

const DialogPayroll = observer(() => {
  const { api, store } = useAppContext();
  const [product, setProducts] = useState<IInventoryProduct>({ ...defaultInventoryProduct });
  const [productDescription, setProductDescription] = useState<IProductDescriptionFormItems[]>([]);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    let $product: IInventoryProduct = {
      ...product, description: productDescription
    };

    if ($product.isNonTaxable === false) {
      $product = {
        ...product, sellingPrice: (product.price * 0.15) + product.price
      }
    }else{
      $product = {
        ...product, sellingPrice: product.price
      }
    }

    const selected = store.inventory.products.selected;
    if (selected) await update($product);
    else await create($product);

    setLoading(false);
    onCancel();
  };

  const update = async (data: IInventoryProduct) => {
    try {
      await api.inventory.products.update(data);
    } catch (error) {
      console.log(error);
    }
  };

  const create = async (data: IInventoryProduct) => {
    try {
      await api.inventory.products.create(data);
    } catch (error) {
      console.log(error);
    }
  };

  const onCancel = () => {
    store.inventory.products.clearSelected();
    setProducts({ ...defaultInventoryProduct });
    setProductDescription([])
    hideModalFromId(DIALOG_NAMES.INVENTORY.PRODUCT_DIALOG);
  };

  useEffect(() => {
    if (store.inventory.products.selected) setProducts(store.inventory.products.selected);
    else setProducts({ ...defaultInventoryProduct });
    return () => { };
  }, [store.inventory.products.selected]);

  return (
    <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
      <button className="uk-modal-close-default" type="button" data-uk-close />
      <div className="dialog-content uk-position-relative product-dialog">
        <h3>Product Form</h3>
        <ProductForm
          product={product}
          setInventoryProduct={setProducts}
          productDescription={productDescription}
          setProductDescription={setProductDescription}
          onSubmit={handleSubmit}
          loading={loading}
        />
      </div>
    </div>
  );
});

export default DialogPayroll;
