// custom hooks
import { useAppContext } from "../../../shared/functions/Context";

// modal functions
import showModalFromId from "../../../shared/functions/ModalShow";
import { IInventoryProductCategory } from "../../../shared/models/InventoryProductCategory";
import DIALOG_NAMES from "../../dialogs/Dialogs";


// models and interfaces


// sweet-alert-2
// import swal from 'sweetalert';

interface IProps {
  productCategory: IInventoryProductCategory;
}
const ProductCategoryItem = (props: IProps) => {
  const { store } = useAppContext();

  const { productCategory } = props;

  const handleView = () => {
    store.inventory.categories.select(productCategory); // set selected product
  };

  const handleEdit = () => {
    store.inventory.categories.select(productCategory); // set selected productCategory
    showModalFromId(DIALOG_NAMES.INVENTORY.PRODUCT_CATEGORY_DIALOG); // show modal
  };

  const handleDelete = () => {
    store.inventory.categories.select(productCategory); // set selected product
  };


  return (
    <div className="user uk-card uk-card-default uk-card-body uk-card-small">
      <div>
        <div className="uk-flex uk-flex-middle">
          <h4 className="user-name">
            Name: {productCategory.categoryName}
            <p>ID: {productCategory.categoryId}</p>
          </h4>
        </div>
        <div className="uk-flex uk-flex-middle uk-text-right">
          <div className="controls">
            <button className="uk-button uk-button-primary uk-margin-left" onClick={handleView}>
              <span data-uk-icon="expand"></span>
            </button>
            <button className="uk-button uk-button-secondary uk-margin-small-left" onClick={handleEdit}>
              <span data-uk-icon="pencil"></span>
            </button>
            <button className="uk-button uk-button-danger uk-margin-small-left" onClick={handleDelete} data-uk-icon="icon:trash;">
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCategoryItem;
