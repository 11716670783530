export function CustomNumberFormat(number?: number) {
  return number?.toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
}

export const numberFormat = (value: number) => {
  if (value === 0) return "0";
  if (!value || isNaN(value)) return "-";

  return new Intl.NumberFormat("en-US", {
    style: "decimal",
  }).format(value);
};