import { observer } from "mobx-react-lite";
import Loading from "../../../shared/components/Loading";
import { dateFormat } from "../../../shared/functions/TimestampToDate";
import { LeaveRequest } from "../../../shared/models/LeaveRequest";
import { ChangeEvent, useState } from "react";
import { useAppContext } from "../../../shared/functions/Context";
import useUploadToStorage from "../../shared/hooks/useUploadToStorage";

interface AttachedFileItemProps {
  fileName: string;
  fileURL: string;
  progress?: number;
}

const AttachedFileItem = (props: AttachedFileItemProps) => {
  const { fileName, progress } = props;


  return (
    <div className="file-item uk-card-default uk-card-small">
      <span data-uk-icon="icon: file-text; ratio: 1"></span>
      {fileName}
      {progress && (
        <progress className="uk-progress" value={progress} max="100" style={{ height: "4px" }}>{`${progress} %`}</progress>
      )}
    </div>
  );
};

interface IButtonProps {
  onFileChange: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
  label: string;
  name: string;
}
const FileUploadButton = (props: IButtonProps) => {
  const { onFileChange, name, label } = props;
  return (
    <label className="uk-button text uk-margin-small" style={{ textTransform: "capitalize", borderRadius: 5 }}>
      <input
        type="file"
        accept="*/*"
        name={name}
        id={`upload-${name}`}
        onChange={onFileChange}
        style={{ display: "none" }} />
      <span style={{ color: "inherit" }} data-uk-icon="icon: upload"></span>
      {label}
    </label>
  );
};

interface RequestCardProps {
  request: LeaveRequest;
  onCancel?: (request: LeaveRequest) => void;
  onEdit?: (request: LeaveRequest) => void;
}
const LeaveCard = (props: RequestCardProps) => {
  const { request, onCancel, onEdit } = props;
  const { userName, userRole, type, from, to, days, reasonForLeave: reason, status } = request;

  const { api, ui } = useAppContext();
  const { progress, uploadFile } = useUploadToStorage();

  const [file, setFile] = useState<File>();
  const [loading, setLoading] = useState(false)

  const onSave = async () => {
    setLoading(true)
    try {
      const downloadURL = file ? await uploadFile(file, "/leave-requests") : "";
      request.filePath = downloadURL;
      await api.leave.requests.update(request.asJson)

    } catch (error) { }
    ui.snackbar.load({
      id: Date.now(),
      message: "Error! Failed to Upload.",
      type: "danger",
    });
    setLoading(false)
  }

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!e.target.files) return;
    setFile(e.target.files[0]);
  }

  const onFileRemove = () => {
    setFile(undefined);
  }

  return (
    <div className="request-card uk-card uk-card-default uk-card-small">
      <div className="user">
        <h6 className="name">{userName}</h6>
        <p className="role">{userRole}</p>
      </div>
      <div className="uk-card-body">
        <div className={`leave-type ${status}`}>
          <div className="dot"></div><p>{type}</p>
        </div>
        <div className="leave-date">
          <div className="from">
            <p>
              <span>From</span> {dateFormat(from)}
            </p>
          </div>
          <div className="to">
            <p>
              <span>To</span> {dateFormat(to)}
            </p>
          </div>
          <div className="no-of-days">
            <p>
              <span>Days</span> {days}
            </p>
          </div>
        </div>
        <div className="reason">
          <p>
            <span>Reason</span>
          </p>
          <p>{reason}</p>
        </div>
        <div className="footer">
          {onCancel && (
            <button
              type="button"
              className="uk-button danger uk-margin-small-right"
              onClick={() => onCancel(request)}
            >
              Cancel
            </button>
          )}
          {onEdit && (
            <button
              type="button"
              className="uk-button primary uk-margin-small-right"
              onClick={() => onEdit(request)}
            >
              Edit
            </button>
          )}
          {request.filePath && (
            <a href={request.filePath} target="_balnk">View File</a>
          )}
          {!request.filePath && request.status !== "REJECTED" &&
            <div>
              <div className="uk-width-1-1 uk-margin-small-top">
                <div>
                  <span className="uk-form-label">Supporting Files</span>
                  <br />
                  <FileUploadButton
                    onFileChange={onFileChange}
                    label="Upload"
                    name="supporting-files"
                  />
                </div>
              </div>
              <div className="uk-width-1-1">
                <div className="uk-margin-small">
                  {file && (
                    <AttachedFileItem
                      fileName={file.name}
                      fileURL={""}
                      progress={progress}
                    />
                  )}
                </div>
              </div>
              {file &&
                <div className="controls">
                  <button type="button" className="save" onClick={onSave}>
                    <span data-uk-icon="icon: cloud-upload; ratio: 1"></span>
                  </button>
                  <button type="button" className="delete uk-margin-small-left" onClick={onFileRemove}>
                    <span data-uk-icon="icon: trash; ratio: 1"></span>
                  </button>
                </div>
              }
            </div>
          }
        </div>
      </div>
    </div>
  );
};

interface IGridProps {
  requests: LeaveRequest[];
  loading: boolean;
  onCancel?: (request: LeaveRequest) => void;
  onEdit?: (request: LeaveRequest) => void;
}
const LeaveGrid = observer((props: IGridProps) => {
  const { requests, loading, onCancel, onEdit } = props;

  if (loading) return <Loading />;

  return (
    <div>
      <div className="uk-margin">
        <div className="uk-grid-small uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-grid-match"
          data-uk-grid>
          {requests.map((request, index) => (
            <div key={index}>
              <LeaveCard key={index} request={request} onCancel={onCancel} onEdit={onEdit} />
            </div>
          ))}
        </div>
      </div>
      {requests.length === 0 && (
        <div className="uk-margin">
          <div className="no-orders">
            <p className="uk-text-center">
              Empty (no requests) <span>😔</span>
            </p>
          </div>
        </div>
      )}
    </div>
  );
});

export default LeaveGrid;



