import { makeAutoObservable } from "mobx";
import AppStore from "../stores/AppStore";
import ILeaveRequest, { ILeaveStatus } from "../interfaces/ILeaveRequest";

export class LeaveRequest implements ILeaveRequest {
  id: string;
  type: string;
  typeId: string;
  appliedOn: number;
  from: number;
  to: number;
  reasonForLeave: string;
  reasonForRejection: string;
  status: ILeaveStatus;
  userName: string;
  userId: string;
  userRole: string;
  days: number;
  departmentId: string;
  filePath: string;
  email: string;

  constructor(private store: AppStore, leave: ILeaveRequest) {
    makeAutoObservable(this);
    this.id = leave.id;
    this.type = leave.type;
    this.typeId = leave.typeId;
    this.appliedOn = leave.appliedOn;
    this.from = leave.from;
    this.to = leave.to;
    this.reasonForLeave = leave.reasonForLeave;
    this.reasonForRejection = leave.reasonForRejection;
    this.status = leave.status;
    this.userName = leave.userName;
    this.userId = leave.userId;
    this.userRole = leave.userRole;
    this.days = leave.days;
    this.departmentId = leave.departmentId;
    this.filePath = leave.filePath;
    this.email = leave.email;
  }

  get asJson(): ILeaveRequest {
    return {
      id: this.id,
      type: this.type,
      typeId: this.typeId,
      appliedOn: this.appliedOn,
      from: this.from,
      to: this.to,
      days: this.days,
      reasonForLeave: this.reasonForLeave,
      reasonForRejection: this.reasonForRejection,
      status: this.status,
      userName: this.userName,
      userId: this.userId,
      userRole: this.userRole,
      departmentId: this.departmentId,
      filePath: this.filePath,
      email: this.email,
    };
  }
}
