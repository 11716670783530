import { observer } from "mobx-react-lite";
import { ChangeEvent } from "react";

interface IProps {
  index: number;
  name: string;
  hours: number;
  rate: number;
  amount: number;
  onChange: (index: number) => (e: ChangeEvent<HTMLInputElement>) => void;
  onRemove: (index: number) => void;
}
const PaymentFlexiAmountItem = observer((props: IProps) => {
  const { index, name, hours, rate, onChange, onRemove } = props;

  return (
    <div className="payment-item uk-margin">
      <p className="label">{name}</p>
      <div className="uk-grid-small" data-uk-grid>
        <div className="uk-width-1-4@s">
          <div className="uk-margin">
            <label className="uk-form-label label">Hours</label>
            <input
              className="uk-input uk-form-small"
              type="text"
              placeholder="Input"
              value={hours}
              name={"hours"}
              onChange={onChange(index)}
            />
          </div>
        </div>
        <div className="uk-width-1-4@s uk-flex">
          <div className="uk-margin">
            <label className="uk-form-label label">Rate</label>
            <input
              className="uk-input uk-form-small"
              type="text"
              placeholder="Input"
              value={rate}
              name={"rate"}
              onChange={onChange(index)}
            />
          </div>
        </div>
        <div className="uk-margin-left icon">
          <span
            onClick={() => onRemove(index)}
            data-uk-icon="icon: trash;"
          ></span>
        </div>
      </div>
    </div>
  );
});

export default PaymentFlexiAmountItem;
