import { makeAutoObservable } from "mobx";
import AppStore from "../stores/AppStore";
import ILeaveSetting, { ILeaveScope } from "../interfaces/ILeaveSetting";

export class LeaveSetting implements ILeaveSetting {
  id: string;
  type: string;
  scope: ILeaveScope;
  days: number;
  departmentId: string | null;
  departmentName: string | null;

  constructor(private store: AppStore, setting: ILeaveSetting) {
    makeAutoObservable(this);
    this.id = setting.id;
    this.type = setting.type;
    this.scope = setting.scope;
    this.days = setting.days;
    this.departmentId = setting.departmentId;
    this.departmentName = setting.departmentName;
  }

  get asJson(): ILeaveSetting {
    return {
      id: this.id,
      type: this.type,
      scope: this.scope,
      days: this.days,
      departmentId: this.departmentId,
      departmentName: this.departmentName,
    };
  }
}
