import { makeAutoObservable, toJS } from "mobx";
import AppStore from "../stores/AppStore";

export const defaultSupplier: ISupplier = {
    id: "",
    supplierName: "",
    registrationNo: "",
    vatRegistration: "",
    contactNumber: "",
    contactEmail: "",
    businessAddress: "",
    postalAddress: "",
    bankName: "",
    bankBranch: "",
    bankBranchNo: "",
    accountName: "",
    industry: ""
};

export interface ISupplier {
    id: string;
    supplierName: string;
    registrationNo: string;
    vatRegistration: string;
    contactNumber: string;
    contactEmail: string;
    businessAddress: string;
    postalAddress: string;
    bankName: string;
    bankBranch: string;
    bankBranchNo: string;
    accountName: string;
    industry: string;
}

export default class Supplier {
    private supplier: ISupplier;

    constructor(private store: AppStore, supplier: ISupplier) {
        makeAutoObservable(this);
        this.supplier = supplier;
    }

    get asJson(): ISupplier {
        return toJS(this.supplier);
    }
}