import React from "react";

const TaxSchemes = () => {
  return (
    <div className="tax-schemes uk-margin">
      <h4 className="uk-margin">Tax Schemes</h4>
    </div>
  );
};

export default TaxSchemes;
