import { observer } from "mobx-react-lite";
import { Fragment, ChangeEvent } from "react";
import FileUploadButton from "../../../shared/components/file-upload-btn/FileUploadButton";
import { LeaveSetting } from "../../../shared/models/LeaveSetting";
import ILeaveProfile from "../../../shared/interfaces/ILeaveProfile";
import ILeaveRequest from "../../../shared/interfaces/ILeaveRequest";
import AttachedFileItem from "../../shared/components/attached-file-item/AttachedFileItem";

interface LeaveApplicationFormProps {
  profile: ILeaveProfile;
  timeStampToDateString: (timestamp: number) => string;
  handleDaysChange: (days: number) => void;
  handleFromChange: (timestamp: number) => void;
  handleToChange: (timestamp: number) => void;
  handleFilesChange: (e: any) => Promise<void>;
  handleFileRemove: (fileName: string) => Promise<void>;
  handleLeaveTypeChange: (type: string) => void;
  onSubmit: (e: ChangeEvent<HTMLFormElement>) => Promise<void>;
  resetLeaveRequest: () => void;
  leaveRequest: ILeaveRequest;
  setLeaveRequest: React.Dispatch<React.SetStateAction<ILeaveRequest>>;
  leaveTypes: LeaveSetting[];
  daysLeft: number;
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  loading: boolean;
  progress: number;
}
const LeaveApplicationForm = observer((props: LeaveApplicationFormProps) => {
  const {
    timeStampToDateString,
    handleDaysChange,
    handleToChange,
    handleFilesChange,
    handleFileRemove,
    handleFromChange,
    onSubmit,
    handleLeaveTypeChange,
    resetLeaveRequest,
    leaveRequest,
    setLeaveRequest,
    leaveTypes,
    daysLeft,
    files,
    loading,
    progress
  } = props;

  return (
    <form className="uk-card uk-card-default uk-card-small uk-card-body" onSubmit={onSubmit}>
      <div
        className="uk-grid-small uk-child-width-1-3@m uk-margin"
        data-uk-grid
      >
        <div>
          <div>
            <label className="uk-form-label" htmlFor="">
              From
            </label>
            <div className="uk-form-controls">
              <input
                type="date"
                className="uk-input uk-form-small"
                placeholder="From"
                value={timeStampToDateString(leaveRequest.from)}
                onChange={(e) => handleFromChange(e.target.valueAsNumber)}
                required
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <label className="uk-form-label" htmlFor="">
              To
            </label>
            <div className="uk-from-controls">
              <input
                type="date"
                className="uk-input uk-form-small"
                placeholder="To"
                value={timeStampToDateString(leaveRequest.to)}
                onChange={(e) => handleToChange(e.target.valueAsNumber)}
                required
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <label className="uk-form-label" htmlFor="">
              Number of days
            </label>
            <div className="uk-from-controls">
              <input
                type="number"
                className="uk-input uk-form-small"
                placeholder="Number of days"
                max={daysLeft}
                min={1}
                value={leaveRequest.days}
                onChange={(e) => handleDaysChange(e.target.valueAsNumber)}
                required
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <label className="uk-form-label" htmlFor="leave-type">
              Leave Type
            </label>
            <div className="uk-form-controls">
              <select
                className="uk-select uk-form-small"
                id="leave-type"
                value={leaveRequest.type || ''}
                onChange={(e) => handleLeaveTypeChange(e.target.value)}
                required
              >
                <option value="">Select a leave type</option>
                {leaveTypes.sort((a, b) => a.type.localeCompare(b.type)).map((leaveType) => (
                  <option key={leaveType.id} value={leaveType.type}>
                    {leaveType.type}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="uk-width-1-1">
          <div>
            <label className="uk-form-label" htmlFor="">
              Reason
            </label>
            <div className="uk-from-controls">
              <textarea
                className="uk-textarea uk-form-small"
                placeholder="Reason for leave"
                rows={5}
                value={leaveRequest.reasonForLeave}
                onChange={(e) =>
                  setLeaveRequest({
                    ...leaveRequest,
                    reasonForLeave: e.target.value,
                  })
                }
                required
              />
            </div>
          </div>
        </div>
        <div className="uk-width-1-1">
          <div>
            <span className="uk-form-label">Supporting Files (Optional)</span>
            <br />
            <FileUploadButton
              onFileChange={handleFilesChange}
              label="Upload"
              name="supporting-files"
            />
          </div>
        </div>
        <div className="uk-width-1-1">
          <div
            className="uk-grid-small uk-child-width-1-2@m uk-child-width-1-3@l uk-margin"
            data-uk-grid
          >
            {files &&
              files.map((file, index) => (
                <Fragment key={index}>
                  <AttachedFileItem
                    fileName={file.name}
                    fileURL={""}
                    onDelete={() => handleFileRemove(file.name)}
                    progress={progress}
                  />
                </Fragment>
              ))}
          </div>
        </div>
      </div>
      <div>
        <hr />
      </div>
      <div className="controls">
        <button
          className="uk-button primary uk-margin-small-right" type="submit">
          Apply
          {loading && <div data-uk-spinner="ratio: .5"></div>}
        </button>
        <button
          className="uk-button secondary uk-margin-small-right"
          type="button"
          onClick={resetLeaveRequest}
        >
          Clear
        </button>
      </div>
    </form>
  );
});

export default LeaveApplicationForm;
