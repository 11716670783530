import { observer } from "mobx-react-lite"
import react, { useEffect, useState } from "react"
import Modal from "../../../shared/components/Modal"
import { useAppContext } from "../../../shared/functions/Context"
import showModalFromId from "../../../shared/functions/ModalShow"
import DIALOG_NAMES from "../../dialogs/Dialogs"
import SupplierDialog from "../../dialogs/supplier-dialog/supplier-dialog"
import { Suppliers } from "./Suppliers"



export const Supplier = observer(() => {
    const { store, api } = useAppContext();

    const onCreate = () => {
        showModalFromId(DIALOG_NAMES.INVENTORY.CREATE_SUPPLIER_DIALOG);
    }

    useEffect(() => {
        const getSupplier = async () => {
            await api.inventory.suppliers.getAll();
        }
        getSupplier();
    }, [api.inventory.suppliers])

    return (
        <div className="uk-section leave-analytics-page">
            <div className="uk-container uk-container-large">
                <div className="section-toolbar uk-margin">
                    <h4 className="section-heading uk-heading">Inventory Suppliers</h4>
                    <div className="controls">
                        <div className="uk-inline">
                            <button onClick={onCreate} className="uk-button primary" type="button">
                                Add Supplier
                            </button>
                        </div>
                    </div>
                </div>
                <Suppliers suppliers={store.inventory.supplier.all} />
            </div>
            <Modal modalId={DIALOG_NAMES.INVENTORY.CREATE_SUPPLIER_DIALOG}>
                <SupplierDialog />
            </Modal>
        </div>
    )
})

