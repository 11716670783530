import { collection } from "firebase/firestore";
import { db } from "../database/FirebaseConfig";
import AppStore from "../stores/AppStore";
import AppApi from "./AppApi";
import InventorySupplierApi from "./InventorySupplierApi";

import InventoryProductApi from "./InventoryProductApi";
import InventoryOverideApi from "./InventoryOverideApi";
import InventoryCategoryProductApi from "./InventoryProductCategoryApi";
import InventoryPurchaseOrderApi from "./InventoryPurchaseOrderApi";


export default class InventoryApi {
  private InventorySupplierDB = collection(db, "InventorySuppliers");
  private InventoryOverideDB = collection(db, "InventoryOveride");
  private InventoryPurchaseOrderDB = collection(db, "InventoryPurchaseOrder");

  products: InventoryProductApi;
  suppliers: InventorySupplierApi;
  overide: InventoryOverideApi;
  categories: InventoryCategoryProductApi;
  purchaseOrder: InventoryPurchaseOrderApi;

  constructor(api: AppApi, store: AppStore) {
    this.products = new InventoryProductApi(api, store);
    this.suppliers = new InventorySupplierApi(api, store, this.InventorySupplierDB)
    this.overide = new InventoryOverideApi(api, store, this.InventoryOverideDB)
    this.categories = new InventoryCategoryProductApi(api, store)
    this.purchaseOrder = new InventoryPurchaseOrderApi(api, store, this.InventoryPurchaseOrderDB)
  }
}
