// import pdfMake from "pdfmake/build/pdfmake";
// import pdfFonts from "pdfmake/build/vfs_fonts";
import { LeaveRequest } from "../../../models/LeaveRequest";
import LeaveDocDefinition, { subSectionHeader } from "./LeaveReportDocDefition";

// pdfMake.vfs = pdfFonts.pdfMake.vfs;

declare const pdfMake: any;

const LeaveReportPDFGen = async (
  fromMillis: number,
  toMillis: number,
  requests: LeaveRequest[]
) => {
  // Locale dates
  const from = new Date(fromMillis).toLocaleDateString();
  const to = new Date(toMillis).toLocaleDateString();

  const doc = `from ${from} to ${to}`;

  // Leave requests
  const requestsRef = requests.map((request) => {
    const from = new Date(request.from).toLocaleDateString();
    const to = new Date(request.to).toLocaleDateString();
    const days = request.days.toString();

    return [subSectionHeader(request.userName, request.type, days, from, to)];
  });

  // console.log(requestsRef.toString());

  // const newWindow = window.open(doc);
  pdfMake.createPdf((await LeaveDocDefinition(doc, requestsRef)) as any).open({});
};

export default LeaveReportPDFGen;
