import CommonLeaves from "./CommonLeaves";
import DepartmentLeaves from "./DepartmentLeaves";
import EmployeesWithoutProfile from "./EmployeesWithoutProfile";

const LeaveSettings = () => {
  return (
    <div className="uk-section leave-settings-page">
      <div className="uk-container uk-container-large">
        <div className="uk-margin common-leaves">
          <h4 className="section-heading uk-heading">Common Leaves</h4>
          <CommonLeaves />
        </div>
        <div className="uk-margin department-leaves ">
          <h4 className="section-heading uk-heading">Departmental Leaves</h4>
          <DepartmentLeaves />
        </div>
        <div className="uk-margin department-leaves ">
          <h4 className="section-heading uk-heading"> Employees without profiles</h4>
          <EmployeesWithoutProfile />
        </div>
      </div>
    </div>
  );
};

export default LeaveSettings;
