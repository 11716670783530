export const defaultTaxRate: ITaxRate = {
  id: "",
  baseRate: 0,
  percentage: 0,
  taxableIncomeMin: 0,
  taxableIncomeMax: 0,
};

interface ITaxRate {
  id: string;
  taxableIncomeMin: number;
  taxableIncomeMax: number;
  baseRate: number;
  percentage: number;
}
export default ITaxRate;
