import React, { useCallback, useEffect, useState } from "react";
import TaxTabs from "./TaxTabs";
import TaxRates from "./TaxRates";
import TaxSchemes from "./TaxSchemes";
import { useAppContext } from "../../../shared/functions/Context";
import Loading from "../../../shared/components/Loading";
import showModalFromId from "../../../shared/functions/ModalShow";
import DIALOG_NAMES from "../../dialogs/Dialogs";
import TaxRateDialog from "../../dialogs/payroll/tax-rate-dialog/TaxRateDialog";
import Modal from "../../../shared/components/Modal";
import { observer } from "mobx-react-lite";

interface ToolBarProps {
  onCreateTaxRate: () => void;
}
const ToolBar = (props: ToolBarProps) => {
  const { onCreateTaxRate } = props;

  return (
    <div className="section-toolbar uk-margin">
      <h4 className="section-heading uk-heading">Tax Services</h4>
      <div className="controls">
        <button className="uk-button primary" onClick={() => onCreateTaxRate()}>
          Add Tax Rate
        </button>
      </div>
    </div>
  );
};

const TaxServices = observer(() => {
  const { api, store } = useAppContext();
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState<"Scheme" | "Rates">("Rates");

  const onCreateTaxRate = () => {
    store.payroll.taxRate.clearSelected();
    showModalFromId(DIALOG_NAMES.PAYROLL.TAX_RATE_DIALOG);
  };

  // Get tax rates
  const load = useCallback(async () => {
    setLoading(true);
    await api.payroll.taxRate.getAll();
    setLoading(false);
  }, [api.payroll.taxRate]);

  useEffect(() => {
    load();
    return () => {};
  }, [load]);

  return (
    <>
      <div className="uk-section tax-page">
        <div className="uk-container uk-container-large">
          <ToolBar onCreateTaxRate={onCreateTaxRate} />
          <TaxTabs tab={tab} setTab={setTab} />
          {tab === "Rates" && !loading && (
            <TaxRates taxRates={store.payroll.taxRate.all} />
          )}
          {tab === "Scheme" && !loading && <TaxSchemes />}

          {loading && (
            <div className="uk-card uk-card-default uk-card-body">
              <Loading />
            </div>
          )}
        </div>
      </div>
      <Modal modalId={DIALOG_NAMES.PAYROLL.TAX_RATE_DIALOG}>
        <TaxRateDialog />
      </Modal>
    </>
  );
});

export default TaxServices;
