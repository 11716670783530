import React from "react";
import Loading from "../../../shared/components/Loading";
import { UserModel } from "../../../shared/models/User";
import { IUser } from "../../../shared/interfaces/IUser";

interface IProps {
  employees: UserModel[];
  isLoading: boolean;
  onCreateProfile: (user: IUser) => void;
}
const LeaveEmployees = (props: IProps) => {
  const { employees, isLoading, onCreateProfile } = props;

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="uk-card uk-card-default uk-card-small uk-card-body">
      <div className="company-users uk-margin">
        <div className="uk-overflow-auto">
          <table className="company-users-table uk-table uk-table-small uk-table-divider uk-table-middle uk-table-responsive">
            <thead className="table-header">
              <tr>
                <th>#</th>
                <th>User</th>
                <th className="uk-table-expand">Department</th>
                <th className="uk-table-expand">Role</th>
                <th className="uk-table-expand">Region</th>
                <th className="actions">Actions</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {employees.map((employee, index) => (
                <tr className="row" key={employee.uid}>
                  <td className="id">{index + 1}</td>
                  <td className="customerName">{`${employee.firstName} ${employee.lastName}`}</td>
                  <td className="department">{employee.departmentName}</td>
                  <td className="role">{employee.role}</td>
                  <td className="accessType">{employee.region}</td>
                  <td className="actions">
                    <button
                      className="uk-button text"
                      onClick={() => onCreateProfile(employee.asJson)}
                    >
                      <span data-uk-icon="icon: plus"></span>
                      Create Leave Profile
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {employees.length === 0 &&
          <div className="no-orders">
            <p className="uk-text-center">
              Empty (no data) <span>😔</span>
            </p>
          </div>
        }
      </div>
    </div>
  );
};
export default LeaveEmployees;
