import { override } from "mobx"
import { observer } from "mobx-react-lite"
import Overide from "../../../shared/models/InventoryOveride"
import { useAppContext } from "../../../shared/functions/Context"
import showModalFromId from "../../../shared/functions/ModalShow"
import DIALOG_NAMES from "../../dialogs/Dialogs"



interface IProps {
    override: Overide
}


export const OverideBtns = observer((props: IProps) => {
    const { override } = props;
    const { store, api } = useAppContext();



    const onDelete = async (id: string) => {
        if (window.confirm("Delete")) {
            try {
                await api.inventory.overide.delete(id)
            } catch (error) {
                console.log("Error creating", error)
            }
        }
    }

    const onView = () => {
        store.inventory.overide.select(override.asJson);
        showModalFromId(DIALOG_NAMES.OVERIDE.CREATE_OVERIDE_DIALOG);
    };


    return (
        <div>
            <button onClick={onView} className="uk-button text uk-margin-small-right" type="button">
                <span data-uk-icon="icon: pencil"></span>
                Edit
            </button>
            <button onClick={() => onDelete(override.asJson.id)} className="uk-button text-danger" type="button">
                <span data-uk-icon="icon: trash"></span>
                Remove
            </button>
        </div>
    )
})