import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingEllipsis } from "../../../shared/components/Loading";
import { useAppContext } from "../../../shared/functions/Context";
import IPayrollEmployee, { defaultPayrollEmployee } from "../../../shared/interfaces/IPayrollEmployee";
import BankAccountDetails from "./BankAccountDetails";
import PersonalDetails from "./PersonalDetails";
import PaymentDetails from "./PaymentDetails";
import PaymentSummary from "./PaymentSummary";
import { CustomCloseAccordion, CustomOpenAccordion } from "../../shared/custom-accordion/CustomAccordion";

const ViewEmployeePayroll = observer(() => {
  const navigate = useNavigate();

  const { api, store } = useAppContext();
  const { folderId, payrollId, empId } = useParams();

  const [loading, setLoading] = useState(false);
  const [employee, setEmployee] = useState<IPayrollEmployee | undefined>(undefined);

  const loadDB = async () => {
    setLoading(true);
    if (payrollId && empId) {
      await api.payroll.employee.getById(payrollId, empId);
    }
    setLoading(false);
  };

  const onNavigateBack = () => {
    navigate(`/c/payroll/fy/${folderId}/${payrollId}`)
  };

  useEffect(() => {
    const loadStore = async () => {
      if (payrollId && empId) {
        setLoading(true);
        const _employee = store.payroll.employee.items.get(empId);
        if (_employee) setEmployee({ ...defaultPayrollEmployee, ..._employee.asJson, payrollId });
        else await loadDB();
      }
      setLoading(false);
    };
    loadStore()
  }, [payrollId, empId, store.payroll.employee, setEmployee]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined;

    if (!loading && !employee) {
      timeoutId = setTimeout(() => {
        navigate(`/c/payroll/fy/${folderId}/${payrollId}`);
      }, 2000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [folderId, payrollId, employee, loading, navigate]);

  return (
    <div className="uk-section view-employee-payroll-page">
      <div className="uk-container uk-container-large">
        {employee && (
          <>
            <div className="section-toolbar uk-margin">
              <h4 className="section-heading uk-heading go-back" onClick={onNavigateBack}>
                <span data-uk-icon="chevron-left"></span>
                {employee.firstName} {" "}{employee.lastName}
              </h4>
              <div className="controls" style={{ display: "inherit" }}>
                <div id="save-button-portal"></div>
                <div id="delete-button-portal"></div>
              </div>
            </div>
            <div className="uk-grid uk-grid-small uk-child-width-expand@s" data-uk-grid>
              <div className="uk-grid-item-match">
                <PaymentDetails employee={employee} setEmployee={setEmployee} />
              </div>
              <div className="">
                <CustomCloseAccordion title={"Banking Details"}>
                  <BankAccountDetails
                    employee={employee}
                    setEmployee={setEmployee}
                  />
                </CustomCloseAccordion>
                <CustomCloseAccordion title={"Personal Details"}>
                  <PersonalDetails
                    employee={employee}
                    setEmployee={setEmployee}
                    departments={store.department.all}
                    regions={store.region.all}
                  />
                </CustomCloseAccordion>
                <CustomOpenAccordion title={"Payment Summary"}>
                  <PaymentSummary
                    employee={employee}
                    setEmployee={setEmployee}
                  />
                </CustomOpenAccordion>
              </div>
            </div>
          </>
        )}
        {loading && <LoadingEllipsis />}
      </div>
    </div>
  );
});

export default ViewEmployeePayroll;


  // if (!employee) navigate(`/c/payroll/fy/${folderId}/${payrollId}`);
  // useEffect(() => {
  //   if (!employee) {
  //     navigate(`/c/payroll/fy/${folderId}/${payrollId}`);
  //   }
  // }, [employee, folderId, payrollId]);

// const [subscriptions, setSubscriptions] = useState<Unsubscribe[]>([]);
  // Get the selected payroll.
  // const loadDB = useCallback(async () => {
  //   setLoading(true);
  //   if (payrollId && empId) {
  //     const unsub = await api.payroll.employee.getById(payrollId, empId);
  //     setSubscriptions((subs) => [...subs, unsub]);
  //   }
  //   setLoading(false);
  // }, [payrollId, empId, api.payroll.employee]);

  // // Check if its available locally
  // const loadStore = useCallback(async () => {
  //   if (payrollId && empId) {
  //     setLoading(true);
  //     const _employee = store.payroll.employee.items.get(empId);
  //     if (_employee) setEmployee({ ...defaultPayrollEmployee, ..._employee.asJson, payrollId });
  //     else await loadDB();
  //   }
  //   setLoading(false);
  // }, [store.payroll.employee.items, empId, payrollId, loadDB]);

  // useEffect(() => {
  //   loadStore();
  //   return () => {
  //     subscriptions.forEach((unsubscribe) => unsubscribe());
  //   };
  // }, [loadStore, subscriptions]);



//   iprt	Import react as object
// ftmp	Generate basic react function template
// tmp	Generate react function template with basic import
// uss	use React.useState hook quickly
// usf	use React.useEffect hook quickly
// usre	use React.useReducer hook quickly
// usr	use React.useRef hook quickly
// usm	use React.useMemo hook quickly
// usc	use React.useCallback hook quickly
// uslf	use React.useLayoutEffect hook quickly