import { observer } from "mobx-react-lite";
import { IInventoryProduct } from "../../../shared/models/InventoryProduct";
import { useAppContext } from "../../../shared/functions/Context";

interface IProps {
  product: IInventoryProduct;
  loading: boolean;
}
const ProductForm = observer((props: IProps) => {
  const { store } = useAppContext();
  const { product } = props;
  const supplier = store.inventory.supplier.getById(product.supplier);
  const productCategory = store.inventory.categories.getById(product.productCategory);

  return (
    <div className="uk-grid uk-grid-medium uk-padding-small" data-uk-grid>
      <div className="uk-width-2-3">
        <div className="uk-card uk-card-default uk-padding-small" data-uk-card>
          <h4>Product Details</h4>
          <p>SKU: <span className="detail">{product.sku}</span></p>
          <hr />
          <p>Product Name: <span className="detail">{product.productName}</span></p>
          <hr />
          <p>Category: <span className="detail">{productCategory?.asJson.categoryName}</span></p>
          <hr />
          <p>Quantity (on hand): <span className="detail">{product.quantityOnHand}</span></p>
          <hr />
          <p>Price: <span className="detail">N$ {product.price.toFixed(2)}</span></p>
        </div>
      </div>
      <div className="uk-width-1-3">
        <div className="uk-grid uk-grid-small" data-uk-grid>
          <div className="uk-card uk-card-default uk-padding-small uk-width-1-1" data-uk-card>
            <h4>Product Description</h4>
            {
              product.description?.map(description => (
                <>
                  <p>{description.description}: <span className="detail">{description.value}</span></p>
                  <hr />
                </>
              ))
            }
          </div>
        </div>
        <div className="uk-grid uk-grid-small">
          <div className="uk-card uk-card-secondary uk-padding-small uk-width-1-1" data-uk-card>
            <h4>Supplier Details</h4>
            <p>Supplier Name: <span className="detail">{supplier?.asJson.supplierName}</span></p>
            <hr />
            <p>Registration No.: <span className="detail">{supplier?.asJson.registrationNo}</span></p>
            <hr />
            <p>VAT No.: <span className="detail">{supplier?.asJson.vatRegistration}</span></p>
            <hr />
            <p>Contact Number: <span className="detail">{supplier?.asJson.contactNumber}</span></p>
            <hr />
            <p>Email: <span className="detail">{supplier?.asJson.contactEmail}</span></p>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ProductForm;
