import { observer } from "mobx-react-lite";
import { ChangeEvent } from "react";
import { CustomNumberFormat } from "../../../shared/functions/CustomNumberFormat";

interface IProps {
  index: number;
  name: string;
  amount: number;
  taxable: boolean;
  percentage: number;
  finalAmount: number;
  percentageAmount: number;
  onChange: (
    index: number
  ) => (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  onRemove: (index: number) => void;
}
const PaymentFixedAmountItem = observer((props: IProps) => {
  const {
    index,
    name,
    amount,
    taxable,
    percentage,
    onChange,
    onRemove,
  } = props;

  const reAmount = amount - amount * (percentage / 100);
  var subAmount = amount - reAmount;

  return (
    <div className="payment-item uk-margin">
      <div className="uk-grid-small" data-uk-grid>
        <div className="uk-width-1-3@l">
          <div className="uk-margin">
            <label className="uk-form-label label">{name}</label>
            

            <input
              className="uk-input uk-form-small"
              type="number"
              value={amount}
              name={"amount"}
              onChange={onChange(index)}
            />
          </div>
        </div>
        {taxable && (
          <div className="uk-width-1-3@l">
            <div className="uk-margin">
              <small>N$ {CustomNumberFormat(subAmount)}</small>
              <div className="uk-inline">
                <span className="uk-form-icon">%</span>
                <input
                  className="uk-input uk-form-small"
                  type="number"
                  aria-label="Not clickable icon"
                  value={percentage}
                  name={"percentage"}
                  onChange={onChange(index)}
                />
              </div>
            </div>
          </div>
        )}
        <div className="uk-width-1-3@l checkdel">
          <div>
            <input
              data-uk-tooltip="Is this item taxable?"
              className="uk-checkbox chekbox"
              type="checkbox"
              name="taxable"
              checked={taxable}
              onChange={onChange(index)}
            />
          </div>
          <div className="uk-margin-left">
            <div className="icon">
              <span
                data-uk-tooltip="Delete item"
                onClick={() => onRemove(index)}
                data-uk-icon="icon: trash;"
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default PaymentFixedAmountItem;