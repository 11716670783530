import { FormEvent, useEffect, useState } from "react";
import { useAppContext } from "../../../../shared/functions/Context";
import { hideModalFromId } from "../../../../shared/functions/ModalShow";
import IPayroll, {defaultPayroll} from "../../../../shared/interfaces/IPayroll";
import DIALOG_NAMES from "../../Dialogs";
import { observer } from "mobx-react-lite";
import PayrollForm from "./PayrollForm";
import { useParams } from "react-router-dom";

const DialogPayroll = observer(() => {
  const { api, store} = useAppContext();
  const [payroll, setPayroll] = useState<IPayroll>({ ...defaultPayroll });
  const [loading, setLoading] = useState(false);
  const { folderId } = useParams<{ folderId: string }>();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!folderId) return;

    setLoading(true);
    const $payroll: IPayroll = {
      ...payroll,
      folderId: folderId,
    };
    const selected = store.payroll.batch.selected;
    if (selected) await update($payroll);
    else await create($payroll);

    setLoading(false);
    onCancel();
  };

  const update = async (data: IPayroll) => {
    try {
      await api.payroll.batch.update(data);
    } catch (error) {
      console.log(error);
    }
  };

  const create = async (data: IPayroll) => {
    try {
      await api.payroll.batch.create(data);
    } catch (error) {
      console.log(error);
    }
  };

  const onCancel = () => {
    store.payroll.batch.clearSelected();
    setPayroll({ ...defaultPayroll });
    hideModalFromId(DIALOG_NAMES.PAYROLL.PAYROLL_DIALOG);
  };

  useEffect(() => {
    if (store.payroll.batch.selected) setPayroll(store.payroll.batch.selected);
    else setPayroll({ ...defaultPayroll });
    return () => {};
  }, [store.payroll.batch.selected]);

  return (
    <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
      <button className="uk-modal-close-default" type="button" data-uk-close />
      <div className="dialog-content uk-position-relative payroll-dialog">
        <PayrollForm
          payroll={payroll}
          setPayroll={setPayroll}
          onSubmit={handleSubmit}
          loading={loading}
        />
      </div>
    </div>
  );
});

export default DialogPayroll;
