import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
    labels: ['Category 1', 'Category 2', 'Category 3'],
    datasets: [
        {
            label: 'Total revenue N$',
            data: [500000.00, 700000.00, 400000.00],
            backgroundColor: [
                'white',
                'grey',
                'lightgrey',
            ],
            borderColor: [
                'black',
                'black',
                'black',
            ],
            borderWidth: 1,
        },
    ],
};


export const SalesTrends = () => {
    return (
        <Pie data={data} />
    )
}