import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../shared/components/Loading";
import Modal from "../../../shared/components/Modal";
import NoData from "../../../shared/components/no-data/NoData";
import { useAppContext } from "../../../shared/functions/Context";
import showModalFromId from "../../../shared/functions/ModalShow";
import DIALOG_NAMES from "../../dialogs/Dialogs";
import DialogPayroll from "../../dialogs/payroll/payroll-dialog/PayrollDialog";
import PayrollList from "./PayrollList";

interface ToolBarProps {
  onCreate: () => void;
  onBack: () => void;
}

const ToolBar = (props: ToolBarProps) => {
  const { onCreate, onBack } = props;
  return (
    <div className="section-toolbar uk-margin">
      <h4 className="section-heading uk-heading go-back" onClick={onBack}>
        <span data-uk-icon="chevron-left"></span>Payrolls
      </h4>
      <div className="controls">
        <button
          className="uk-button primary uk-margin-small-right"
          onClick={onCreate}
        >
          Create payroll
        </button>
      </div>
    </div>
  );
};

const Payrolls = observer(() => {
  const { api, store, ui } = useAppContext();
  const [loading, setLoading] = useState(true);
  const { folderId } = useParams();
  const navigate = useNavigate();

  const payrolls = store.payroll.batch.all.filter((p) => p.asJson.folderId === folderId);

  const onCreate = () => {
    store.payroll.batch.clearSelected();
    showModalFromId(DIALOG_NAMES.PAYROLL.PAYROLL_DIALOG);
  };
  const onBack = () => {
    navigate(`/c/payroll/fy/`);
  };

  useEffect(() => {
    if (!folderId) return;
    const loadAll = async () => {
      setLoading(true);
      try {
        await api.payroll.batch.getByFolderId(folderId);
        await api.payroll.taxRate.getAll();
      } catch (error) {
        ui.snackbar.load({
          id: Date.now(),
          message: "Error! Failed to load the payrolls",
          type: "danger",
        });
      }
      setLoading(false);
    };
    loadAll();
  }, [api.payroll.batch, ui, api.payroll.taxRate, folderId]);

  return (
    <>
      <div className="uk-section payroll-page">
        <div className="uk-container uk-container-large">
          <ToolBar onBack={onBack} onCreate={onCreate} />

          {!loading && <PayrollList payrolls={payrolls} />}
          {loading && (
            <div className="uk-card uk-card-default uk-card-body">
              <Loading />
            </div>
          )}
          {!loading && payrolls.length === 0 && (
            <div className="uk-card uk-card-default uk-card-body">
              <NoData />
            </div>
          )}
        </div>
      </div>
      <Modal modalId={DIALOG_NAMES.PAYROLL.PAYROLL_DIALOG}>
        <DialogPayroll />
      </Modal>
    </>
  );
});

export default Payrolls;
