import { observer } from "mobx-react-lite";
import { ChangeEvent, Dispatch, FormEvent, SetStateAction, useState } from "react";
import { IInventoryProduct, IProductDescriptionFormItems } from "../../../shared/models/InventoryProduct";
import { useAppContext } from "../../../shared/functions/Context";
import ErrorBoundary from "../../shared/error-boundary/ErrorBoundary";
import ProductDescriptionItem from "./ProductDescriptionItem";
import NumberFormat from 'react-number-format';

interface IProps {
  product: IInventoryProduct;
  productDescription: IProductDescriptionFormItems[];
  setInventoryProduct: Dispatch<SetStateAction<IInventoryProduct>>;
  setProductDescription: Dispatch<SetStateAction<IProductDescriptionFormItems[]>>;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  loading: boolean;
}
const ProductForm = observer((props: IProps) => {
  const { product, productDescription, setProductDescription, setInventoryProduct, onSubmit, loading } = props;
  const { store } = useAppContext();

  const [isNonTaxable, setIsNonTaxable] = useState<Boolean>(false);

  const productCategories = store.inventory.categories.all;
  const suppliers = store.inventory.supplier.all;

  const onAddItem = () => {
    const newItem: IProductDescriptionFormItems = {
      description: "",
      value: "",
    };
    const data = [...productDescription];
    data.push(newItem);
    setProductDescription(data);
  };

  const onItemChange = (index: number) => (e: ChangeEvent<HTMLInputElement>) => {

    const inputName = e.target.name as | "description" | "value";

    const data = [...productDescription];

    if (inputName === "description") {
      data[index][inputName] = e.target.value;
    }
    if (inputName === "value") {
      data[index][inputName] = (e.target.value);
    }
    setProductDescription(data);
  };

  const onItemRemove = (index: number) => {
    const data = [...productDescription];
    data.splice(index, 1);
    setProductDescription(data);
  };

  return (
    <form
      className="uk-form-stacked uk-grid-small uk-child-width-1-1"
      data-uk-grid
      onSubmit={onSubmit}
    >
      <div className="uk-grid-small uk-child-width-1-1" data-uk-grid>
        <div className="uk-width-1-2">
          <div className="uk-margin">
            <label className="uk-form-label" htmlFor="form-stacked-text">
              SKU
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input uk-form-small"
                type="text"
                placeholder="Product SKU"
                value={product.sku}
                onChange={(e) =>
                  setInventoryProduct({ ...product, sku: e.target.value })
                }
                required
              />
            </div>
          </div>
        </div>
        <div className="uk-width-1-2">
          <div className="uk-margin">
            <label className="uk-form-label" htmlFor="form-stacked-text">
              Product Category
            </label>
            <div className="uk-form-controls">
              <select id="product-catgeory" className="uk-select uk-form-small" value={product.productCategory} onChange={(e) => setInventoryProduct({ ...product, productCategory: e.target.value })}>
                <option disabled value="">Select the product category</option>
                {
                  productCategories.length === 0 &&
                  <option value="">No options</option>
                }
                {productCategories.map(category => (
                  <option key={category.asJson.id} value={category.asJson.id}>{category.asJson.categoryName}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="uk-width-1-2">
          <div className="uk-margin">
            <label className="uk-form-label" htmlFor="form-stacked-text">
              Product Name
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input uk-form-small"
                type="text"
                placeholder="Product Name"
                value={product.productName}
                onChange={(e) =>
                  setInventoryProduct({ ...product, productName: e.target.value })
                }
                required
              />
            </div>
          </div>
        </div>
        {/* <div className="uk-width-1-2">
          <div className="uk-margin">
            <label className="uk-form-label" htmlFor="form-stacked-text">
              Colour
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input uk-form-small"
                type="text"
                placeholder="Product Colour"
                value={product.productColor}
                onChange={(e) =>
                  setInventoryProduct({ ...product, productColor: e.target.value })
                }
                required
              />
            </div>
          </div>
        </div> */}
        {/* <div className="uk-width-1-2">
          <div className="uk-margin">
            <label className="uk-form-label" htmlFor="form-stacked-text">
              Size
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input uk-form-small"
                type="text"
                placeholder="Product Size"
                value={product.productSize}
                onChange={(e) =>
                  setInventoryProduct({ ...product, productSize: e.target.value })
                }
                required
              />
            </div>
          </div>
        </div> */}
        {/* <div className="uk-width-1-2">
          <div className="uk-margin">
            <label className="uk-form-label" htmlFor="form-stacked-text">
              Unit of Measurement
            </label>
            <div className="uk-form-controls">

              <select id="product-unitOfMeasure" className="uk-select uk-form-small" value={product.unitOfMeasurement}
                onChange={(e) =>
                  setInventoryProduct({ ...product, unitOfMeasurement: e.target.value })
                }>
                <option value="mg">mg</option>
                <option value="g">g</option>
                <option value="Kg">Kg</option>
                <hr />
                <option value="mm">mm</option>
                <option value="cm">cm</option>
                <option value="m">m</option>
                <hr />
                <option value="ml">ml</option>
                <option value="l">l</option>
              </select>
            </div>
          </div>
        </div> */}
        <div className="uk-width-1-2">
          <div className="uk-margin">
            <label className="uk-form-label" htmlFor="form-stacked-text">
              Price {!product.isNonTaxable && product.price !== null &&
              <small className="uk-text-danger"> (Price incl. VAT N${(product.price * 0.15)+ product.price})</small>}
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input uk-form-small"
                type="number"
                placeholder="Unit price"
                value={product.price}
                onChange={(e) =>
                  setInventoryProduct({ ...product, price: e.target.valueAsNumber})
                }
                required
              />
            </div>
          </div>
        </div>
        <div className="uk-width-1-2">
          <div className="uk-margin">
            <label className="uk-form-label" htmlFor="form-stacked-text">
              QTY on Hand
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input uk-form-small"
                type="number"
                placeholder="Quantity on hand"
                value={product.quantityOnHand}
                onChange={(e) =>
                  setInventoryProduct({ ...product, quantityOnHand: e.target.valueAsNumber })
                }
                required
              />
            </div>
          </div>
        </div>

        {/* <div className="uk-width-1-2">
          <div className="uk-margin">
            <label className="uk-form-label" htmlFor="form-stacked-text">
              Description
            </label>
            <div className="uk-form-controls">
              <textarea
                className="uk-textarea uk-form-small"
                placeholder="Description"
                value={product.productDescription}
                onChange={(e) =>
                  setInventoryProduct({
                    ...product,
                    productDescription: e.target.value,
                  })
                }
                required
              />
            </div>
          </div>
        </div> */}
        <div className="uk-width-1-2">
          <div className="uk-margin">
            <label className="uk-form-label" htmlFor="form-stacked-text">
              Product Supplier
            </label>
            <div className="uk-form-controls">
              <select id="product-supplier" className="uk-select uk-form-small" value={product.supplier}
                onChange={(e) =>
                  setInventoryProduct({ ...product, supplier: e.target.value })
                }>
                <option disabled value="">Select the product supplier</option>
                {
                  suppliers.length === 0 &&
                  <option value="">No options</option>
                }
                {suppliers.map(supplier => (
                  <option key={supplier.asJson.id} value={supplier.asJson.id}>{supplier.asJson.supplierName}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <ErrorBoundary>
          <div className="uk-width-1-1">
            <label htmlFor="">Product Description</label>
            {productDescription.map((item, index) => (
              <div key={index}>
                <ProductDescriptionItem
                  index={index}
                  description={item.description}
                  value={item.value}
                  onChange={onItemChange} onRemove={onItemRemove}
                />
              </div>
            ))}
            <br />
            <div>
              <button className="uk-button uk-button-primary uk-margin-small-left" onClick={onAddItem} type="button">Add a description</button>
            </div>
          </div>
        </ErrorBoundary>
        <div className="uk-width-1-2">
          <div className="uk-form-controls">
            <input
              className="uk-checkbox uk-margin-small-right"
              id="product-is-taxable-toggle"
              type="checkbox"
              // {product.isNonTaxable? "checked" : ""}
              // value={product.isNonTaxable}
              onChange={(e) =>
                // setIsNonTaxable(e.target.checked ? true : false)
                setInventoryProduct({
                  ...product, isNonTaxable: e.target.checked ? true : false
                }) 
              }
            />
            VAT exempted
          </div>
        </div>
        <div className="footer">
          <button className="uk-button secondary uk-modal-close" type="button">Cancel</button>
          <button className="uk-button uk-button-primary" type="submit">Save{loading && <div data-uk-spinner="ratio: .5"></div>}</button>
        </div>
      </div>
    </form>
  );
});

export default ProductForm;
