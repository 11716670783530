declare const UIkit: any;

export const hideModalFromId = (id: string) => {
  const element = document.querySelector(`#${id}`);
  UIkit.modal(element).hide();
};

export default function showModalFromId(id: string) {
  const element = document.querySelector(`#${id}`);
  UIkit.modal(element).show();
}

export const hideOffcanvasFromId = (id: string) => {
  const element = document.querySelector(`#${id}`);
  UIkit.offcanvas(element).hide();
};

export function showOffcanvasFromId(id: string) {
  const element = document.querySelector(`#${id}`);
  UIkit.offcanvas(element).show();
}


export const uploadFilesProgress = (file: File | undefined): void => {


  if (!file) return;

  const bar = document.getElementById('js-progressbar') as HTMLProgressElement;

  UIkit.upload('.js-upload', {
    url: '',
    multiple: true,

    beforeSend: function () { },
    beforeAll: function () { },
    load: function () { },
    error: function () { },
    complete: function () { },

    loadStart: function (e: { total: number; loaded: number; }) {
      bar.removeAttribute('hidden');
      bar.max = e.total;
      bar.value = e.loaded;
    },

    progress: function (e: { total: number; loaded: number; }) {
      bar.max = e.total;
      bar.value = e.loaded;
    },

    loadEnd: function (e: { total: number; loaded: number; }) {
      bar.max = e.total;
      bar.value = e.loaded;
    },

    completeAll: function () {
      setTimeout(function () {
        bar.setAttribute('hidden', 'hidden');
      }, 1000);
      alert('Upload Completed');
    }

  });
};
