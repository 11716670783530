import { observer } from "mobx-react-lite";
import { Payroll } from "../../../shared/models/Payroll";
import PayrollItem from "./PayrollItem";

interface IProps {
  payrolls: Payroll[];
}

const PayrollList = observer((props: IProps) => {
  const { payrolls } = props;

  const sorted = payrolls.sort((a, b) => b.deadlineMs - a.deadlineMs);

  return (
    <div className="payroll-list">
      {sorted.map((payroll) => (
        <div className="uk-margin-small" key={payroll.id}>
          <PayrollItem key={payroll.id} payroll={payroll} />
        </div>
      ))}
    </div>
  );
});

export default PayrollList;
