import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import Loading from "../../../shared/components/Loading";
import NoData from "../../../shared/components/no-data/NoData";
import { useAppContext } from "../../../shared/functions/Context";
import PayrollFolderList from "./PayrollFolderList";


const EmployeeFolders = observer(() => {
  const { api, store, ui } = useAppContext();
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    const loadAll = async () => {
      setLoading(true);
      try {
        await api.payrollfolder.getAll();
      } catch (error) {
        ui.snackbar.load({
          id: Date.now(),
          message: "Error! Failed to load the folders",
          type: "danger",
        });
      }
      setLoading(false);
    };
    loadAll();
  }, [api.payrollfolder, ui]);

  return (
    <div className="uk-section folder-page">
      <div className="uk-container uk-container-large">
        <div className="section-toolbar uk-margin uk-flex-between">
          <div>
            <h4 className="section-heading uk-heading">Years</h4>
          </div>
        </div>
        {loading && (
          <div className="uk-card uk-card-default uk-card-body">
            <Loading />
          </div>
        )}
        {!loading && <PayrollFolderList folders={store.payrollfolder.all} />}
        {!loading && store.payrollfolder.all.length === 0 && (
          <div className="uk-card uk-card-default uk-card-body">
            <NoData />
          </div>
        )}
      </div>
    </div>
  );
});

export default EmployeeFolders;
