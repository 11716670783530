import {
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../database/FirebaseConfig";
import IPayroll from "../interfaces/IPayroll";
import IPayrollFolder from "../interfaces/IPayrollFolder";
import AppStore from "../stores/AppStore";
import AppApi from "./AppApi";

export default class PayrollFolderApi {
  collectionRef: CollectionReference;
  constructor(
    private api: AppApi,
    private store: AppStore,
    collectionRef: CollectionReference
  ) {
    this.collectionRef = collectionRef;
  }

  async getAll() {
    const q = query(this.collectionRef);

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const items: IPayrollFolder[] = [];
      querySnapshot.forEach((doc) => {
        items.push({ ...doc.data(), id: doc.id } as IPayrollFolder);
      });

      this.store.payrollfolder.load(items);
    });

    return unsubscribe;
  }

  async create(payrollfolder: IPayrollFolder) {
    const docRef = doc(this.collectionRef);
    payrollfolder.id = docRef.id;
    await setDoc(docRef, payrollfolder, { merge: true });
    return payrollfolder;
  }

  // async update(payrollfolder: IPayrollFolder) {
  //   await setDoc(doc(this.collectionRef, payrollfolder.id), payrollfolder);
  //   return payrollfolder;
  // }

  // update item
  async update(item: IPayrollFolder) {
    try {
      await updateDoc(doc(this.collectionRef, item.id), {
        ...item,
      });
      this.store.payrollfolder.load([item]);
    } catch (error) {
      console.log(error);
    }
  }

  async delete(id: string) {
    const isNotEmpty = await this.folderHasPayrolls(id);
    if (isNotEmpty) {
      window.alert("This folder has payrolls");
    }
    const docRef = doc(this.collectionRef, id);
    await deleteDoc(docRef);
    this.store.payrollfolder.remove(id);
  }

  async folderHasPayrolls(folderId: string) {
    const $query = query(
      collection(db, "Payroll"),
      where("folderId", "==", folderId)
    );
    const querySnapshot = await getDocs($query);
    const payrolls = querySnapshot.docs.map((doc) => {
      return { ...doc.data(), id: doc.id } as IPayroll;
    });
    return payrolls.length !== 0 ? true : false;
  }
}
