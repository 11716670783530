import React, { ChangeEvent, Fragment, useState } from "react";
import { useAppContext } from "../../../shared/functions/Context";
import showModalFromId from "../../../shared/functions/ModalShow";
import useAutoSave from "../../../shared/hooks/useAutoSave";
import { LeaveSetting } from "../../../shared/models/LeaveSetting";
import IDepartment from "../../../shared/interfaces/IDepartment";
import ILeaveSetting from "../../../shared/interfaces/ILeaveSetting";
import DIALOG_NAMES from "../../dialogs/Dialogs";

interface LeaveItemProps {
  leave: LeaveSetting;
  onUpdate: (data: ILeaveSetting) => void;
  onDelete: (id: string) => void;
}
const LeaveItem = (props: LeaveItemProps) => {
  const { leave, onUpdate, onDelete } = props;
  const [days, setdays] = useState(leave.days);
  const { autoSave } = useAutoSave({ timeout: 2000 });

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const daysNum = parseInt(e.target.value) || 0;
    setdays(daysNum);

    const $leave = leave.asJson;
    $leave.days = daysNum;
    autoSave(() => onUpdate($leave));
  };

  return (
    <div>
      <div className="leave-card uk-card uk-card-small">
        <div className="header">
          <h5 className="type">{leave.type}</h5>
          <button className="remove" onClick={() => onDelete(leave.id)}>
            <span data-uk-icon="trash"></span>
          </button>
        </div>
        <div className="no-of-days">
          <input type="text" value={days} onChange={onChange} />
          <span>Days</span>
        </div>
      </div>
    </div>
  );
};

interface LeaveListProps {
  department?: IDepartment;
  leaveItems: LeaveSetting[];
}
const LeaveList = (props: LeaveListProps) => {
  const { api, store, ui } = useAppContext();
  const { department, leaveItems } = props;

  const showNewLeaveModal = () => {
    if (department) store.leave.settings.selectDepartment(department);
    else store.leave.settings.clearSelectedDepartment();
    showModalFromId(DIALOG_NAMES.LEAVE.ENTER_LEAVE_TYPE);
  };

  const onDeleteLeave = (id: string) => {
    if (!window.confirm("Delete leave?")) return;
    api.leave.settings.delete(id);
  };

  const onUpdateLeave = async (data: ILeaveSetting) => {
    await api.leave.settings.update(data);
    ui.snackbar.load({
      id: Date.now(),
      message: "Saved!",
      type: "default",
      timeoutInMs: 1000,
    });
  };

  return (
    <div className="uk-margin">
      <div
        className="uk-grid-small uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-4@m uk-child-width-1-6@l uk-grid-match"
        data-uk-grid
      >
        {leaveItems.map((leave, index) => (
          <Fragment key={index}>
            <LeaveItem
              leave={leave}
              onDelete={onDeleteLeave}
              onUpdate={onUpdateLeave}
            />
          </Fragment>
        ))}
        <div>
          <div className="add-leave-card">
            <button onClick={showNewLeaveModal}>
              <span data-uk-icon="icon: plus-circle; ratio: 2"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaveList;
