import { observer } from "mobx-react-lite";
import { FormEvent, useEffect, useState } from "react";
import { useAppContext } from "../../../../shared/functions/Context";
import { hideModalFromId } from "../../../../shared/functions/ModalShow";
import ITaxRate, { defaultTaxRate } from "../../../../shared/interfaces/ITaxRate";
import DIALOG_NAMES from "../../Dialogs";
import TaxRateForm from "./TaxRateForm";

const TaxRateDialog = observer(() => {
  const { api, store, ui } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [taxRate, setTaxRate] = useState<ITaxRate>({ ...defaultTaxRate });

  const onSave = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    if (store.payroll.taxRate.selected) {
      try {
        await api.payroll.taxRate.update(taxRate);
        ui.snackbar.load({
          id: Date.now(),
          message: "Tax rate updated!",
          type: "success",
        });
      } catch (error) {
        ui.snackbar.load({
          id: Date.now(),
          message: "Error! Failed to update tax rate.",
          type: "danger",
        });
      }
    } else {
      try {
        await api.payroll.taxRate.create(taxRate);
        ui.snackbar.load({
          id: Date.now(),
          message: "Tax rate created!",
          type: "success",
        });
      } catch (error) {
        ui.snackbar.load({
          id: Date.now(),
          message: "Error! Failed to create tax rate.",
          type: "danger",
        });
      }
    }

    store.payroll.taxRate.clearSelected();
    setLoading(false);
    setTaxRate(defaultTaxRate);
    hideModalFromId(DIALOG_NAMES.PAYROLL.TAX_RATE_DIALOG);
  };

  useEffect(() => {
    if (store.payroll.taxRate.selected)
      setTaxRate(store.payroll.taxRate.selected);
    else setTaxRate({ ...defaultTaxRate });
  }, [store.payroll.taxRate.selected]);

  return (
    <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
      <button
        className="uk-modal-close-default"
        type="button"
        data-uk-close
      ></button>

      <h3 className="uk-modal-title">TaxRate</h3>

      <div className="dialog-content uk-position-relative">
        <div className="reponse-form">
          <form className="uk-form-stacked" onSubmit={onSave}>
            <TaxRateForm taxRate={taxRate} setTaxRate={setTaxRate} />
            <div className="footer uk-margin">
              <button className="uk-button secondary uk-modal-close">
                Cancel
              </button>
              <button className="uk-button primary" type="submit">
                Save
                {loading && <div data-uk-spinner="ratio: .5"></div>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
});

export default TaxRateDialog;
