import { observer } from "mobx-react-lite";
import { IPayrollStatus } from "../../../../shared/interfaces/IPayroll";

interface ToolBarProps {
  title: string;
  status: IPayrollStatus;
  navigateBack: () => void;
  openRunDialog: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}
export const RunToolBar = observer((props: ToolBarProps) => {
  const { title, navigateBack, openRunDialog, status } = props;

  return (
    <div className="section-toolbar uk-margin">
      <h4 className="section-heading uk-heading go-back" onClick={navigateBack}>
        <span data-uk-icon="chevron-left"></span>
        {title}
      </h4>
      <div className="controls">
        {status === "in-progress" && (
          <button
            className="uk-button primary uk-margin-small-right"
            onClick={openRunDialog}
          >
            Run payroll
          </button>
        )}
        {/* {status === "draft" && (
          <button className="uk-button uk-margin-small-right" disabled data-uk-tooltip="The payroll is not completed.">
            Run Payroll
          </button>
        )}
        {status === "completed" && (
          <button className="uk-button uk-margin-small-right" disabled data-uk-tooltip="The payroll is already completed.">
            Run Payroll
          </button>
        )} */}
      </div>
    </div>
  );
});
