import Store from "../Store";
import AppStore from "../AppStore";
import { runInAction } from "mobx";
import InventoryProduct, { IInventoryProduct } from '../../models/InventoryProduct';

export default class InventoryProductStore extends Store<
  IInventoryProduct,
  InventoryProduct
> {
  items = new Map<string, InventoryProduct>();

  constructor(store: AppStore) {
    super(store);
    this.store = store;
  }

  load(items: IInventoryProduct[] = []) {
    runInAction(() => {
      items.forEach((item) =>
        this.items.set(item.id, new InventoryProduct(this.store, item))
      );
    });
  }
}
