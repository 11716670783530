import { lazy, Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { AppContext, useAppContext } from "./shared/functions/Context";
import { LoadingEllipsis } from "./shared/components/Loading";
import AppApi from "./shared/apis/AppApi";
import AppStore from "./shared/stores/AppStore";
import UiStore from "./shared/stores/UiStore";
import SnackbarManager from "./shared/components/snackbar/SnackbarManager";
import useNetwork from "./shared/hooks/useNetwork";
import { observer } from "mobx-react-lite";
import { USER_ROLES } from "./shared/constants/USER_ROLES";
import Dashboard from "./logged_in_admin/dashboard/Dashboard";
import FAQ from "./logged_in_admin/faq/FAQ";
import LeaveAnalytics from "./logged_in_admin/leave/leave-analytics/LeaveAnalytics";
import LeaveRequests from "./logged_in_admin/leave/leave-requests/LeaveRequests";
import LeaveSettings from "./logged_in_admin/leave/leave-settings/LeaveSettings";
import Folders from "./logged_in_admin/payroll/payroll-folders/Folders";
import Payrolls from "./logged_in_admin/payroll/payrolls/Payrolls";
import TaxServices from "./logged_in_admin/payroll/tax/TaxServices";
import ViewEmployeePayroll from "./logged_in_admin/payroll/view-employee-payroll/ViewEmployeePayroll";
import ViewPayroll from "./logged_in_admin/payroll/view-payroll/ViewPayroll";
import Settings from "./logged_in_admin/profiles/Settings";
import Departments from "./logged_in_admin/team/departments/Departments";
import Employees from "./logged_in_admin/team/employees/Employees";
import Regions from "./logged_in_admin/team/regions/Regions";
import Apply from "./logged_in_client/leave/Apply";
import MyApplications from "./logged_in_client/leave/MyApplications";
import EmployeePayroll from "./logged_in_client/payroll/view-payroll/EmployeePayroll";
import EmployeePayrolls from "./logged_in_client/payroll/payrolls/EmployeePayrolls";
import EmployeeFolders from "./logged_in_client/payroll/payroll-folders/EmployeeFolders";
import EmployeeDashboard from "./logged_in_client/dashboard/EmployeeDashboard";
import PrivateRoute from "./logged_in_admin/shared/PrivateRoute";
import { Supplier } from "./logged_in_admin/inventory/suppliers/Supplier";
import { Overider } from "./logged_in_admin/team/overide/Overide";
import { SupplierView } from "./logged_in_admin/inventory/suppliers/SupplierView";
import "../src/shared/sass/styles.scss"
import AppearanceSettings from "./logged_in_admin/team/Appearence/AppearenceSettings";
import ProductCategories from "./logged_in_admin/inventory/product-category/ProductCategories";
import Products from "./logged_in_admin/inventory/product/Products";
import { PurchaseOrder } from "./logged_in_admin/inventory/purchases/PurchaseOrder";
import { SalesOrder } from "./logged_in_admin/inventory/sales-orders/SalesOrder";
import { StokeManagement } from "./logged_in_admin/inventory/stock-management/StockManagemen";
import { Manage } from "./logged_in_admin/inventory/manage/Manage";


const SignIn = lazy(() => import("./logged_out/sign_in/SignIn"));
const LoggedIn = lazy(() => import("./logged_in_admin/LoggedIn"));

const PrivateLoggedIn = () => (
  <PrivateRoute>
    <LoggedIn />
  </PrivateRoute>
);

const ADMIN_USER_ROUTES = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="c" element={<PrivateLoggedIn />}>
          <Route path={`dashboard`} element={<Dashboard />} />
          <Route path={`payroll/fy`} element={<Folders />} />
          <Route path={`payroll/fy/:folderId`} element={<Payrolls />} />
          <Route path={`payroll/fy/:folderId/:payrollId/`} element={<ViewPayroll />} />
          <Route path={`payroll/fy/:folderId/:payrollId/:empId`} element={<ViewEmployeePayroll />} />
          <Route path={`payroll/tax`} element={<TaxServices />} />
          <Route path={`leave/analytics`} element={<LeaveAnalytics />} />
          <Route path={`leave/requests`} element={<LeaveRequests />} />
          <Route path={`leave/settings`} element={<LeaveSettings />} />
          <Route path={`inventory/items`} element={<Products />} />
          <Route path={`inventory/categories`} element={<ProductCategories />} />
          <Route path={`inventory/stock`} element={<StokeManagement />} />
          <Route path={`inventory/manage`} element={<Manage />} />
          <Route path={`inventory/supplier`} element={<Supplier />} />
          <Route path={`inventory/purchase-order`} element={<PurchaseOrder />} />
          <Route path={`inventory/sales-order`} element={<SalesOrder />} />
          <Route path={`inventory/supplier/:id`} element={<SupplierView />} />
          <Route path={`admin/overide`} element={<Overider />} />
          <Route path={`admin/employees`} element={<Employees />} />
          <Route path={`admin/departments`} element={<Departments />} />
          <Route path={`admin/regions`} element={<Regions />} />
          <Route path={`admin/appearance`} element={<AppearanceSettings />} />
          <Route path={`settings`} element={<Settings />} />
          <Route path={`FAQ`} element={<FAQ />} />
          <Route path="*" element={<Navigate to="dashboard" />} />
        </Route>
        <Route path="/" element={<SignIn />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};


const GENERAL_MANAGER_ROUTES = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="c" element={<PrivateLoggedIn />}>
          <Route path={`dashboard`} element={<Dashboard />} />
          <Route path={`payroll/fy`} element={<Folders />} />
          <Route path={`payroll/fy/:folderId`} element={<Payrolls />} />
          <Route path={`payroll/fy/:folderId/:payrollId/`} element={<ViewPayroll />} />
          <Route path={`payroll/fy/:folderId/:payrollId/:empId`} element={<ViewEmployeePayroll />} />
          <Route path={`payroll/tax`} element={<TaxServices />} />
          <Route path={`leave/analytics`} element={<LeaveAnalytics />} />
          <Route path={`leave/requests`} element={<LeaveRequests />} />
          <Route path={`leave/settings`} element={<LeaveSettings />} />
          <Route path={`team/employees`} element={<Employees />} />
          <Route path={`team/departments`} element={<Departments />} />
          <Route path={`team/regions`} element={<Regions />} />
          <Route path={`settings`} element={<Settings />} />
          <Route path={`FAQ`} element={<FAQ />} />
          <Route path="*" element={<Navigate to="dashboard" />} />
        </Route>
        <Route path="/" element={<SignIn />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};

const EMPLOYEE_USER_ROUTES = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="c" element={<PrivateLoggedIn />}>
          <Route path={`dashboard`} element={<EmployeeDashboard />} />
          <Route path={`payroll/fy`} element={<EmployeeFolders />} />
          <Route path={`payroll/fy/:folderId`} element={<EmployeePayrolls />} />
          <Route path={`payroll/fy/:folderId/:payrollId`} element={<EmployeePayroll />} />
          <Route path={`leave/apply`} element={<Apply />} />
          <Route path={`leave/my-applications`} element={<MyApplications />} />
          <Route path={`settings`} element={<Settings />} />
          <Route path={`faq`} element={<FAQ />} />
          <Route path={`payroll/fy`} element={<Folders />} />
          <Route path={`payroll/fy`} element={<Folders />} />
          <Route path="*" element={<Navigate to="dashboard" />} />
        </Route>
        <Route path="/" element={<SignIn />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};

// Lotsengineering2022 or @2022
// new transnamib.unicomms.app c8R7Dn9ZvGvEyDd

const MainRoutes = observer(() => {
  const { store } = useAppContext();
  const role = store.user.role;

  useNetwork();

  const adminRoles = [USER_ROLES.ADMIN, USER_ROLES.MANAGER, USER_ROLES.GENERAL_MANAGER,
  USER_ROLES.MANAGING_DIRECTOR, USER_ROLES.BOARD_MEMBER, USER_ROLES.DIRECTOR,
  USER_ROLES.HUMAN_RESOURCE, USER_ROLES.SUPERVISOR];

  if (adminRoles.includes(role)) {
    return <ADMIN_USER_ROUTES />;
  } else if (role === USER_ROLES.EMPLOYEE || role === USER_ROLES.INTERN) {
    return <EMPLOYEE_USER_ROUTES />;
  }
  return <EMPLOYEE_USER_ROUTES />;
});

const App = () => {
  const store = new AppStore();
  const api = new AppApi(store);
  const ui = new UiStore();

  return (
    <div className="app">
      <AppContext.Provider value={{ store, api, ui }}>
        <Suspense fallback={<LoadingEllipsis />}>
          <MainRoutes />
        </Suspense>
        <SnackbarManager />
      </AppContext.Provider>
    </div>
  );
};
export default App;
