import { makeAutoObservable } from "mobx";
import AppStore from "../stores/AppStore";
import IBenefits from "../interfaces/IBenefits";

export class Benefits implements IBenefits {
    id: string;
    title: string;
    description: string;

    constructor(private store: AppStore, benefit: IBenefits) {
        makeAutoObservable(this);
        this.id = benefit.id;
        this.title = benefit.title;
        this.description = benefit.description;
    }

    get asJson(): IBenefits {
        return {
            id: this.id,
            title: this.title,
            description: this.description
        };
    }
}
