import { makeAutoObservable, toJS } from "mobx";
import AppStore from "../stores/AppStore";


export interface IPurchaseOrderItems {
    itemId: number | string,
    itemName: string,
    itemQty: number,
    itemDescription: string,
    itemUnitPrice: number,
    discountPercentage: 0
}




export interface PurchaseOrderAttachments {
    attachmentName: string,
    attachmentFile: string
}

export interface IPurchaseOrder {
    id: string;
    orderNumber: string;
    supplierId: string,
    supplierName: string,
    submittedBy: string,
    orderItems: IPurchaseOrderItems[],
    attactment: PurchaseOrderAttachments[],
    total: number,
    vat: number,
    status: string,
    invoice:string
}


export const defaultPurchaseOrder: IPurchaseOrder = {
    id: "",
    orderNumber: "",
    supplierId: "",
    supplierName: "",
    submittedBy: "",
    orderItems: [],
    attactment: [],
    total: 0,
    vat: 0,
    status: "",
    invoice: ""
};

export default class PurchaseOrder {
    private purchaseOrder: IPurchaseOrder;

    constructor(private store: AppStore, purchaseOrder: IPurchaseOrder) {
        makeAutoObservable(this);
        this.purchaseOrder = purchaseOrder;
    }

    get asJson(): IPurchaseOrder {
        return toJS(this.purchaseOrder);
    }
}