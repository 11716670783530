import { doc, getDoc } from "firebase/firestore";
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../../shared/database/FirebaseConfig";
import Loading from "../../../shared/components/Loading";
import { ISupplier, defaultSupplier } from "../../../shared/models/InventorySupplier";
import { useAppContext } from "../../../shared/functions/Context";




export const SupplierView = observer(() => {
    const [supplier, setSupplier] = useState<ISupplier | undefined>({ ...defaultSupplier });
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { store, api } = useAppContext();

    const back = () => {
        navigate("/c/inventory/supplier")
    }

    useEffect(() => {
        const getSupplier = async () => {
            if (!id) { window.alert("Cannot find "); }
            else {
                setLoading(true)
                await api.inventory.suppliers.getAll();
                const supplier = store.inventory.supplier.getById(id)
                setSupplier(supplier?.asJson)
                setLoading(false)
            }
        }
        getSupplier();
    }, [api.inventory.suppliers, id, store.inventory.supplier])




    return (
        <div>
            {loading ?
                <> <Loading /> </>
                :
                <>
                    <div className="uk-section leave-analytics-page">
                        <div className="uk-container uk-container-large">
                            <div className="section-toolbar uk-margin">
                                <h4 className="section-heading uk-heading"></h4>
                                <div className="controls">
                                    <div className="uk-inline">
                                        <button onClick={back} className="uk-button primary" type="button">
                                            back
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="company-users uk-margin ">
                                <div className="uk-overflow-auto">
                                    <div className="section-toolbar uk-margin">
                                        <h4 className="section-heading uk-heading">Supplier Details</h4>
                                        <div className="controls">
                                            <div className="uk-inline">
                                            </div>
                                        </div>
                                    </div>
                                    <table className="company-users-table uk-table uk-table-small uk-table-divider uk-table-middle uk-table-responsive">
                                        <thead className="table-header">
                                            <tr>
                                                <th className="uk-table-expand">Supplier Name</th>
                                                <th className="uk-table-expand">Registration No</th>
                                                <th className="uk-table-expand">VAT Registration</th>
                                                <th className="uk-table-expand">Contact No.</th>
                                                <th className="uk-table-expand">Contact Email</th>
                                                <th className="uk-table-expand">Business Address</th>
                                                <th className="uk-table-expand">Postal Address</th>
                                            </tr>
                                        </thead>

                                        <tbody className="table-body">
                                            <tr>
                                                <td>{supplier?.supplierName}</td>
                                                <td>{supplier?.registrationNo}</td>
                                                <td>{supplier?.vatRegistration}</td>
                                                <td>{supplier?.contactNumber}</td>
                                                <td>{supplier?.contactEmail}</td>
                                                <td>{supplier?.businessAddress}</td>
                                                <td>{supplier?.postalAddress}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="uk-overflow-auto uk-margin">
                                    <div className="section-toolbar uk-margin">
                                        <h4 className="section-heading uk-heading">Banking Details</h4>
                                        <div className="controls">
                                            <div className="uk-inline">
                                            </div>
                                        </div>
                                    </div>
                                    <table className="company-users-table uk-table uk-table-small uk-table-divider uk-table-middle uk-table-responsive">
                                        <thead className="table-header">
                                            <tr>
                                                <th className="uk-table-expand">Branch Name</th>
                                                <th className="uk-table-expand">Bank Branch</th>
                                                <th className="uk-table-expand">Bank Branch No.</th>
                                                <th className="uk-table-expand">Account No</th>
                                            </tr>
                                        </thead>

                                        <tbody className="table-body">
                                            <tr>
                                                <td>{supplier?.bankBranch}</td>
                                                <td>{supplier?.bankName}</td>
                                                <td>{supplier?.bankBranchNo}</td>
                                                <td>{supplier?.accountName}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </>
            }
        </div>
    )
})