import { makeAutoObservable } from "mobx";
import IPayrollFolder from "../interfaces/IPayrollFolder";
import AppStore from "../stores/AppStore";

export class PayrollFolder implements IPayrollFolder {
  id: string;
  year: number;

  constructor(private store: AppStore, folder: IPayrollFolder) {
    makeAutoObservable(this);
    this.id = folder.id;
    this.year = folder.year;
  }

  get asJson(): IPayrollFolder {
    return {
      id: this.id,
      year: this.year,
    };
  }
}
