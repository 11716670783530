import { makeAutoObservable } from "mobx";
import AppStore from "../stores/AppStore";

import ICustomer, {
  ICompanyProfile,
  IBankingProfile,
} from "../interfaces/ICustomer";

export class Customer implements ICustomer {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  cellphone: string;
  customerDocs: string;
  createdOn: number;
  companyProfile: ICompanyProfile;
  bankingProfile: IBankingProfile;

  constructor(private store: AppStore, customer: ICustomer) {
    makeAutoObservable(this);
    this.id = customer.id;
    this.firstName = customer.firstName;
    this.lastName = customer.lastName;
    this.email = customer.email;
    this.cellphone = customer.cellphone;
    this.customerDocs = customer.customerDocs;
    this.createdOn = customer.createdOn;
    this.companyProfile = customer.companyProfile;
    this.bankingProfile = customer.bankingProfile;
  }

  get asJson(): ICustomer {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      cellphone: this.cellphone,
      customerDocs: this.customerDocs,
      createdOn: this.createdOn,
      companyProfile: this.companyProfile,
      bankingProfile: this.bankingProfile,
    };
  }
}
