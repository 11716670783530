import AppStore from "../AppStore";
import OverideStore from "../individualStore/InventoryOveride";
import InventoryProductCategoryStore from "../individualStore/InventoryProductCategoryStore";
import InventoryProductStore from "../individualStore/InventoryProductStore";
import InventoryPurchaseOrderStore from "../individualStore/InventoryPurchaseOrder";
import InventorySalesOrderStore from "../individualStore/InventorySalesOrder";
import SupplierStore from "../individualStore/supplierStore";

export default class InventoryStore {
    supplier: SupplierStore;
    products: InventoryProductStore;
    overide: OverideStore;
    categories: InventoryProductCategoryStore;
    purchaseOrder: InventoryPurchaseOrderStore;
    salesOrder: InventorySalesOrderStore;

    constructor(store: AppStore) {
        this.supplier = new SupplierStore(store);
        this.products = new InventoryProductStore(store);
        this.overide = new OverideStore(store);
        this.categories = new InventoryProductCategoryStore(store);
        this.purchaseOrder = new InventoryPurchaseOrderStore(store);
        this.salesOrder = new InventorySalesOrderStore(store);
    }
}
