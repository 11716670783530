import AppStore from "../AppStore";
import LeaveProfilesStore from "../individualStore/LeaveProfilesStore";
import LeaveRequestStore from "../individualStore/LeaveRequestStore";
import LeaveSettingsStore from "../individualStore/LeaveSettingsStore";

export default class LeaveStore {
  requests: LeaveRequestStore;
  settings: LeaveSettingsStore;
  profiles: LeaveProfilesStore;

  constructor(store: AppStore) {
    this.requests = new LeaveRequestStore(store);
    this.settings = new LeaveSettingsStore(store);
    this.profiles = new LeaveProfilesStore(store);
  }
}
