import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import { useAppContext } from "../../../shared/functions/Context";
import ILeaveProfile from "../../../shared/interfaces/ILeaveProfile";
import { IUser } from "../../../shared/interfaces/IUser";
import LeaveEmployees from "../leave-analytics/LeaveEmployees";

const EmployeesWithoutProfile = observer(() => {
    const { api, store, ui } = useAppContext();
    const [isloadingEmp, setisLoadingEmp] = useState(false);
    const year = new Date().getFullYear();
    const profiles = store.leave.profiles.all;
    const employees = store.user.all;

    const employeesWithoutProfiles = employees.filter((emp) => {
        const profileExists = profiles.map((profile) => profile.userId).includes(emp.uid);
        return !profileExists;
    });

    const onCreateProfile = async (user: IUser) => {
        if (!window.confirm("Do you want to create this profile?")) return;

        const profileExists = profiles.map((profile) => profile.userId).includes(user.uid);
        if (profileExists) {
            ui.snackbar.load({
                id: Date.now(),
                message: "Error! Profile already exist",
                type: "danger",
            });
            return;
        }
        const profile: ILeaveProfile = {
            id: `${user.uid}_${year}`,
            userId: user.uid,
            userName: `${user.firstName} ${user.lastName}`,
            departmentId: user.departmentId,
            userRole: user.role,
            leavesTaken: [],
        };
        await api.leave.profiles.update(profile);
    };

    const loadEmployees = useCallback(async () => {
        try {
            setisLoadingEmp(true);
            await api.auth.loadAll();
            setisLoadingEmp(false);
        } catch (error) { }
    }, [api.auth]);

    const load = useCallback(async () => {
        try {
            await api.leave.settings.getAll();
            await api.leave.profiles.getAll();
        } catch (error) { }

    }, [api.leave.profiles, api.leave.settings]);

    useEffect(() => {
        load();
        loadEmployees();
    }, [load, loadEmployees]);

    return (
        <div className="uk-section leave-analytics-page">
            <div className="uk-container uk-container-large">
                <div className="uk-margin">
                    <LeaveEmployees
                        employees={employeesWithoutProfiles}
                        isLoading={isloadingEmp}
                        onCreateProfile={onCreateProfile}
                    />
                </div>
            </div>
        </div>
    );
});

export default EmployeesWithoutProfile;
