import { observer } from "mobx-react-lite";
import react, { useState } from "react"
import Loading from "../../../shared/components/Loading";
import Supplier from "../../../shared/models/InventorySupplier"
import { useAppContext } from "../../../shared/functions/Context";
import { SupplierTable } from "./SupplierTable";


interface IProps {
    suppliers: Supplier[]
}


export const Suppliers = observer((props: IProps) => {
    const [loading, setLoading] = useState(true);
    const { suppliers } = props;

    setTimeout(() => {
        setLoading(false);
    }, 2000);

    return (
        <div className="uk-card uk-card-default uk-card-small uk-card-body">
            {loading ? (
                <Loading />
            ) : (
                <div className="company-users uk-margin">
                    <div className="uk-overflow-auto">
                        <table className="company-users-table uk-table uk-table-small uk-table-divider uk-table-middle uk-table-responsive">
                            <thead className="table-header">
                                <tr>
                                    <th className="uk-table-expand">Name</th>
                                    <th className="uk-table-expand">Registration No</th>
                                    <th className="uk-table-expand">VAT Registration</th>
                                    <th className="uk-table-expand">Phone</th>
                                    <th className="uk-table-expand">Email</th>
                                    <th className="actions">Actions</th>
                                </tr>
                            </thead>

                            <tbody className="table-body">
                                {suppliers.map((sup) => (
                                    <tr key={sup.asJson.id}>
                                        <SupplierTable key={sup.asJson.id} supplier={sup.asJson} />
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {suppliers.length === 0 &&
                        <div className="no-orders">
                            <p className="uk-text-center">
                                Empty (no data) <span>😔</span>
                            </p>
                        </div>
                    }
                </div>
            )}

        </div>
    )
})