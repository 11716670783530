import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../shared/functions/Context";
import { PayrollFolder } from "../../../shared/models/PayrollFolder";
import { observer } from "mobx-react-lite";

interface IFolderItemProps {
  folder: PayrollFolder;
}
const PayrollFolderItem = observer((props: IFolderItemProps) => {
  const { folder } = props;
  const { store } = useAppContext();
  const navigate = useNavigate();

  const onView = () => {
    store.payrollfolder.select(folder.asJson);
    navigate(`/c/payroll/fy/${folder.id}`);
  };


  return (
    <div className="folder-card-body">
      <div className="folder-file">
        <div className="folder-image" onClick={onView}>
          <img
            src={process.env.PUBLIC_URL + "/icons/folder_icon.svg"}
            width="1700"
            height="1100"
            alt=""
          />
        </div>
      </div>
      <div className="folder-title">
        <h4 data-uk-tooltip={folder.year}>{folder.year}</h4>
        <span></span>
      </div>
    </div>
  );
});
export default PayrollFolderItem;
