import IPayrollEmployee from "../../../interfaces/IPayrollEmployee";
// import pdfMake from "pdfmake/build/pdfmake";
// import pdfFonts from "pdfmake/build/vfs_fonts";
import {
  heading2,
  marginTopBottom,
  fixedDataTable,
  detailsTable,
  flexiDataTable,
  totalsTable,
  getBase64ImageFromURL,
  leaveDataTable,
} from "./PaySlipDocDefinition";
import { LeaveProfile } from "../../../models/LeaveProfile";
import { dateFormat_YY_MM_DY } from "../../TimestampToDate";
// import { moneyToText } from "../../moneyToText";
import { numberToWord } from "../../moneyToWord";
import { useAppContext } from "../../Context";


// import * as pdfMake from "pdfmake/build/pdfmake";
// import * as pdfFonts from 'pdfmake/build/vfs_fonts';
// (pdfMake as any).vfs = pdfFonts.pdfMake.vfs;


declare const pdfMake: any;

const image = process.env.PUBLIC_URL + "/lotsblack.png";

const textFontSize = 8;

const GeneratePayslipPDF = async (employee: IPayrollEmployee, leave: LeaveProfile[]) => {

  const documentName = `${employee.lastName} ${employee.firstName}`;
  // leave
  const leavesDays = leave.map((e) => {
    return [e.leavesTaken.map((e) => leaveDataTable(e.type, e.days))];
  });
  // fixed datas
  const fixedEarningsDocRef = employee.fixedSalary.map((e) => {
    return [fixedDataTable(e.name, e.amount)];
  });

  const fixedBonusDocRef = employee.fixedBonus.map((e) => {
    return [fixedDataTable(e.name, e.amount)];
  });

  const fixedDeductionsDocRef = employee.fixedDeductions.map((e) => {
    return [fixedDataTable(e.name, e.amount)];
  });

  // const fixedExpensesDocRef = employee.fixedExpenses.map((e) => {
  //   return [fixedDataTable(e.name, e.amount)];
  // });

  // flexis data
  const flexiEarningsDocRef = employee.flexiSalary.map((e) => {
    return [flexiDataTable(e.name, e.hours, e.rate, e.amount)];
  });

  const flexiBonusDocRef = employee.flexiBonus.map((e) => {
    return [flexiDataTable(e.name, e.hours, e.rate, e.amount)];
  });

  const flexiDeductionsDocRef = employee.flexiDeductions.map((e) => {
    return [flexiDataTable(e.name, e.hours, e.rate, e.amount)];
  });

  // const flexiExpensesDocRef = employee.flexiExpenses.map((e) => {
  //   return [flexiDataTable(e.name, e.hours, e.rate, e.amount)];
  // });
  const moneyInWord = parseFloat(employee.paySummary.netIncome.toFixed(0));

  var docDefinition = {
    background: function () {
      return {
        canvas: [
          {
            type: "rect",
            x: 0,
            y: 0,
            w: 595.28,
            h: 841.89,
            color: "#fff",
          },
        ],
      };
    },
    content: [
      // {
      //   columns: [
      //     {
      //       style: "brandLogo",
      //       image: await getBase64ImageFromURL(imageLogo),
      //       fit: [90, 90],
      //     },
      //   ],
      // },
      marginTopBottom(),
      detailsTable(
        `${employee.firstName} ${employee.lastName}`,
        `${employee.bankAccount.bankName}`,
        `${employee.identityNo}`,
        `${employee.bankAccount.accountNumber}`,
        `${employee.departmentName}`,
        `${employee.bankAccount.branchName}`,
        `${employee.position}`,
        `${employee.bankAccount.accountType}`,
        `${dateFormat_YY_MM_DY(employee.payrollMonth)}`,
        `${employee.bankAccount.branchCode}`
      ),
      marginTopBottom(),
      heading2("Earnings"),
      ...fixedEarningsDocRef,
      ...flexiEarningsDocRef,
      ...fixedBonusDocRef,
      ...flexiBonusDocRef,
      totalsTable("Gross Salary", employee.paySummary.grossIncome),
      marginTopBottom(),
      heading2("Deductions"),
      fixedDataTable("Professional Tax", employee.paySummary.professionalTax),
      ...fixedDeductionsDocRef,
      ...flexiDeductionsDocRef,
      totalsTable("Total Deductions", employee.paySummary.totalDeductions),
      marginTopBottom(),
      totalsTable("Net Pay", employee.paySummary.netIncome),
      // moneyToText(moneyInWord),
      numberToWord(moneyInWord, " and"),
      marginTopBottom(),
      heading2("Leave Days"),
      ...leavesDays,
      marginTopBottom(),
      // {
      //   columns: [
      //     {
      //       style: "brandText",
      //       text: `Legion Business Operation Consulting And Technologies
      //        Amfeld 20 Ausspannplatz Windhoek, Namibia`,
      //     },
      //   ],
      // },
    ],
    styles: {
      brandText: {
        fontSize: 8,
        fillColor: "#00FF00",
      },
      detailTable: {
        color: "#616161",
        fontSize: textFontSize,
      },
      title2: {
        bold: true,
        color: "#616161",
        fillColor: "#aaaaaa",
        fontSize: 8,
      },
      tableHead: {
        fillColor: "#aaaaaa",
        color: "#616161",
        bold: true,
        fontSize: 8,
      },
      totalTable: {
        color: "#616161",
        bold: true,
        fontSize: 8,
      },
      rateSTable: {
        fillColor: "#616161",
        fontSize: 8,
      },
      mapTable: {
        color: "#616161",
        fontSize: textFontSize,
      },
    },
  };
  // const newWindow = window.open(documentName);
  pdfMake.createPdf(docDefinition).download(documentName);
};
export default GeneratePayslipPDF;
