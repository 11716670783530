// custom hooks
import { collection, getDocs } from "@firebase/firestore";
import { db } from "../../../shared/database/FirebaseConfig";
import { useAppContext } from "../../../shared/functions/Context";

// modal functions
import showModalFromId from "../../../shared/functions/ModalShow";
import InventoryProduct from "../../../shared/models/InventoryProduct";
import DIALOG_NAMES from "../../dialogs/Dialogs";
import { useEffect, useState } from "react";

interface IProps {
  index: number,
  product: InventoryProduct;
}
const ProductItem = (props: IProps) => {
  const { api, store } = useAppContext();

  const { index, product } = props;
  const [codes, setCodes] = useState<[]>([]);

  const handleView = () => {
    store.inventory.products.select(product.asJson); // set selected product
    showModalFromId(DIALOG_NAMES.INVENTORY.PRODUCT_VIEW_DIALOG); // show modal
  };

  const handleEdit = () => {
    store.inventory.products.select(product.asJson); // set selected product
    showModalFromId(DIALOG_NAMES.INVENTORY.PRODUCT_DIALOG); // show modal
  };

  const handleDelete = async () => {
    let code: any = prompt("Enter your code:");
    if (codes.length > 0 && codes.some((c: string) => code.includes(c))) {
      if (window.confirm("Delete")) {
        try {
          store.inventory.products.select(product.asJson); // set selected product
          await api.inventory.products.delete(product.asJson)
        } catch (error) {
          console.log("Error creating", error)
        }
      }
    } else {
      alert("error.");
    }
  };

  useEffect(() => {
    const getCode = async () => {
      const colRef = collection(db, "InventoryOveride");
      const docsSnap = await getDocs(colRef);
      const list: any = [];
      docsSnap.forEach((doc) => {
        const code = doc.data().code;
        list.push(code);
      })
      console.log(list);
      setCodes(list);
    }
    getCode();
  }, [])

  return (
    <tr className="row" key={product.asJson.id}>
      <td className="id">{index + 1}</td>
      <td className="customerName">{product.asJson.sku}</td>
      <td className="customerName">{product.asJson.productName}</td>
      <td className="department">{product.category?.asJson.categoryName}</td>
      <td className="accessType">N$ {product.asJson.price.toFixed(2)}</td>
      <td className="accessType">N$ {product.asJson.sellingPrice.toFixed(2)}</td>
      <td className="accessType">{product.asJson.quantityOnHand}</td>
      <td className="actions">
        <button className="uk-button uk-button-primary uk-margin-small-right" onClick={handleView}>View</button>
        <button className="uk-button uk-button-secondary uk-margin-small-right" onClick={handleEdit}>Edit</button>
        <button className="uk-button uk-button-danger uk-margin-small-right" onClick={handleDelete}>Delete</button>
      </td>
    </tr>
  );
};

export default ProductItem;

