import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useAppContext } from "../../../shared/functions/Context";
import showModalFromId from "../../../shared/functions/ModalShow";
import { Payroll } from "../../../shared/models/Payroll";
import DIALOG_NAMES from "../../dialogs/Dialogs";
import { CustomNumberFormat } from "../../../shared/functions/CustomNumberFormat";
import { FailedAction, SuccessfulAction } from "../../../shared/models/Snackbar";
import { dateFormat } from "../../../shared/functions/TimestampToDate";

interface IProps {
  payroll: Payroll;
}
const PayrollItem = observer((props: IProps) => {
  const { api, store, ui } = useAppContext();
  const { payroll } = props;
  const { id, title, deadline,updatedAt, status, folderId } = payroll;

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // const approved = approval.filter((a) => a.status === "approved");

  const onView = () => {
    store.payroll.batch.select(payroll.asJson);
    navigate(`/c/payroll/fy/${folderId}/${id}`);
  };

  const onEdit = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    store.payroll.batch.select(payroll.asJson);
    showModalFromId(DIALOG_NAMES.PAYROLL.PAYROLL_DIALOG);
  };

  const onDuplicate = async (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    try {
      setLoading(true);
      await api.payroll.batch.duplicate(payroll.asJson);
      SuccessfulAction(ui);
      setLoading(false);
    } catch (error) {
      FailedAction(ui);
    }
  };

  const onDelete = async (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.stopPropagation();
    if (!window.confirm("Delete Payroll ?")) return;
    try {
      setLoading(true);
      await api.payroll.batch.delete(payroll.id);
      SuccessfulAction(ui);
    } catch (error) {
      FailedAction(ui);
    }
    setLoading(false);
  };

  return (
    <div
      className={`payroll-item uk-card-small ${status}`}
      onClick={onView}
    >
      <div
        className="body uk-card-body uk-grid-small uk-child-width-1-2@s uk-child-width-1-6@m"
        data-uk-grid
      >
        <div>
          <h5 className="title">{title}</h5>
        </div>
        <div>
          <div className="status">
            <p className="label">Status</p>
            <p className="value">
              <span className="status-dot"></span>
              {` ${status}`}
            </p>
          </div>
        </div>
        {/* <div>
          <div className="amount">
            <p className="label">Completion Steps</p>
            <p className="value">{`${approved.length || 0} / ${approval.length || 0
              }`}</p>
          </div>
        </div> */}

        <div>
          <div className="amount">
            <p className="label">Amount</p>
            <p className="value">N${CustomNumberFormat(payroll.amount)}</p>
          </div>
        </div>
        <div>
          <div className="deadline">
            <p className="label">Last Updated</p>
            <p className="value">{dateFormat(updatedAt)}</p>
          </div>
        </div>
        <div>
          <div className="deadline">
            <p className="label">Deadline</p>
            <p className="value">{deadline}</p>
          </div>
        </div>

        <div className="more">
          <button className="icon-btn" onClick={(e) => e.stopPropagation()}>
            <span data-uk-icon="more-vertical"></span>
          </button>
          <div
            data-uk-dropdown="mode: click; pos: bottom-right"
            className="dropdown"
          >
            <ul className="menu">
              {loading && <span data-uk-spinner="ratio: .5" />}
              <li className="menu-item" onClick={onEdit}>
                Edit
              </li>
              <li className="menu-item" onClick={onDuplicate}>
                Duplicate
              </li>
              <li className="menu-item" onClick={onDelete}>
                Delete
              </li>
            </ul>
          </div>
        </div>

      </div>
    </div>
  );
});

export default PayrollItem;
