import {
  CollectionReference,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  setDoc,
} from "firebase/firestore";
import IRegion from "../interfaces/IRegion";
import AppStore from "../stores/AppStore";
import AppApi from "./AppApi";

export default class RegionApi {
  collectionRef: CollectionReference;
  constructor(
    private api: AppApi,
    private store: AppStore,
    collectionRef: CollectionReference
  ) {
    this.collectionRef = collectionRef;
  }

  async getAll() {
    const q = query(this.collectionRef);

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const items: IRegion[] = [];
      querySnapshot.forEach((doc) => {
        items.push({ ...doc.data(), id: doc.id } as IRegion);
      });

      this.store.region.load(items);
    });

    return unsubscribe;
  }

  async create(region: IRegion) {
    const docRef = doc(this.collectionRef);
    region.id = docRef.id;
    await setDoc(docRef, region, { merge: true });
    return region;
  }

  async update(region: IRegion) {
    await setDoc(doc(this.collectionRef, region.id), region);
    return region;
  }

  async delete(id: string) {
    const isNotEmpty = await this.api.auth.doesRegionHasUsers(id);
    if (isNotEmpty) throw new Error("Containes users");

    const docRef = doc(this.collectionRef, id);
    await deleteDoc(docRef);
    this.store.region.remove(id);
  }
}
