import AppStore from "../stores/AppStore";
import { collection } from "firebase/firestore";
import { db } from "../database/FirebaseConfig";
import InvoicingApi from "./InvoicingApi";
import DepartmentApi from "./DepartmentApi";
import MailApi from "./MailApi";
import PayrollFolderApi from "./PayrollFolderApi";
import RegionApi from "./RegionApi";
import UserApi from "./UserApi";
import LeaveApi from "./LeaveApi";
import PayrollApi from "./PayrollApi";
import InventoryApi from "./InventoryApi";
import SystemSettingsApi from "./SystemSettingsApi";

export default class AppApi {
  // private mailUri = "https://unicomms.app/php/payroll.php?";

  private mailUri = "https://leave.lotsinsights.com/php/mailer.php?";

  // private mailUri = "https://unicomms.app/php/mailer.php?";
  private departmentDB = collection(db, "Departments");
  private regionDB = collection(db, "Regions");
  private payrollFolderDB = collection(db, "Folders");

  // api endpoints
  leave: LeaveApi;
  department: DepartmentApi;
  payroll: PayrollApi;
  auth: UserApi;
  mail: MailApi;
  invoicing: InvoicingApi;
  region: RegionApi;
  payrollfolder: PayrollFolderApi;
  inventory: InventoryApi;
  namibianRegion: unknown;

  // settings
  settings: SystemSettingsApi;

  constructor(private store: AppStore) {
    this.leave = new LeaveApi(this, this.store);
    this.payroll = new PayrollApi(this, this.store);
    this.department = new DepartmentApi(this, this.store, this.departmentDB);
    this.auth = new UserApi(this, this.store);
    this.mail = new MailApi(this, this.store, this.mailUri);
    this.invoicing = new InvoicingApi(this, this.store);
    this.region = new RegionApi(this, this.store, this.regionDB);
    this.payrollfolder = new PayrollFolderApi(this, this.store, this.payrollFolderDB);
    this.inventory = new InventoryApi(this, this.store)
    this.settings = new SystemSettingsApi(this, store);
  }
}
