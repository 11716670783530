import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';



ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);


export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        title: {
            display: true,
            text: 'Sales Turn Over',
        },
    },
};

const labels = ['Jan', 'Feb', 'March', 'Apr', 'May', 'Jun', 'Jul, Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const data = {
    labels,
    datasets: [
        {
            fill: true,
            label: 'Sales Turn Over Rate',
            data: [220, 344, 266, 366, 666, 566, 266, 366, 466, 566, 666, 766],
            backgroundColor: 'rgba(0, 0, 0, 0.9)',

        },
    ],
};



export const SalesTurnOver = () => {
    return (
        <Line options={options} data={data} />
    )
}







// export const data = {
//     labels,
//     datasets: [
//         {
//             fill: true,
//             label: 'Dataset 2',
//             data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
//             borderColor: 'rgb(53, 162, 235)',
//             backgroundColor: 'rgba(53, 162, 235, 0.5)',
//         },
//     ],
// };

// export function App() {
//     return <Line options={options} data={data} />;
// }
