import Store from "../Store";
import AppStore from "../AppStore";
import {runInAction } from "mobx";
import { PayrollFolder } from "../../models/PayrollFolder";
import IPayrollFolder from "../../interfaces/IPayrollFolder";

export default class PayrollFolderStore extends Store<
  IPayrollFolder,
  PayrollFolder
> {
  items = new Map<string, PayrollFolder>();

  constructor(store: AppStore) {
    super(store);
    this.store = store;
  }

  load(items: IPayrollFolder[] = []) {
    runInAction(() => {
      items.forEach((item) =>
        this.items.set(item.id, new PayrollFolder(this.store, item))
      );
    });
  }
}
