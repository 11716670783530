import { observer } from "mobx-react-lite";
import { ChangeEventHandler, FormEvent, useEffect, useMemo, useRef, useState } from "react";
import { useAppContext } from "../../../shared/functions/Context";
import LeaveReportPDFGen from "../../../shared/functions/PDF/leave/LeaveReportPDFGen";
import { LeaveProfile } from "../../../shared/models/LeaveProfile";
import ErrorBoundary from "../../shared/error-boundary/ErrorBoundary";
import { CustomCloseAccordion } from "../../shared/custom-accordion/CustomAccordion";
import { Department } from "../../../shared/models/Department";
import LeaveProfileBarGraph from "./LeaveProfileBarGraph";
import { LoadingEllipsis } from "../../../shared/components/Loading";

const LeaveAnalytics = observer(() => {
  const { api, store } = useAppContext();

  const firstRenderRef = useRef(true);
  const [isloadingEmp, setisLoadingEmp] = useState(false);
  const [loading, setLoading] = useState(false);

  const year = new Date().getFullYear();
  const [search, setSearch] = useState<string>(`${year}`);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  const profiles = store.leave.profiles.all;
  const departments = store.department.all;

  const years = Array.from({ length: year - 2019 }, (_, index) => 2020 + index);
  const options = years.map((year) => ({
    label: year.toString(),
    value: year.toString(),
  }));

  const onSearch: ChangeEventHandler<HTMLSelectElement> = (event) => {
    setSearch(event.target.value);
  };

  const sortProfile = (a: LeaveProfile, b: LeaveProfile) => {
    return (a.asJson.userName || "").localeCompare(b.asJson.userName || "");
  };

  const sortDepartment = (a: Department, b: Department) => {
    return (a.asJson.name || "").localeCompare(b.asJson.name || "");
  };

  const filteredProfiles = useMemo(() => {
    const _profiles = profiles.filter((p) => p.id.endsWith(search));
    return search ? profiles.filter((p) => p.id.endsWith(search)) : _profiles;
  }, [search, profiles]);

  const groupedByDepartment = () => {
    const grouped = departments.sort(sortDepartment).map((dep) => {
      return {
        id: dep.asJson.id,
        department: dep.asJson.name,
        profiles: filteredProfiles.sort(sortProfile).filter((profile) => {
          return (profile.asJson.departmentId === dep.asJson.id);
        }),
      };
    });
    return grouped;
  };

  const onExportReport = async (from: number, to: number) => {
    try {
      setLoading(true);
      await api.leave.requests.getAllFromTo(from, to);
      const requests = store.leave.requests.all;
      await LeaveReportPDFGen(from, to, requests);
    } catch (error) {
      window.alert("Error exporting report");
      console.log(error);

    }
    setLoading(false);
  };

  const onExport = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const fromMillis = new Date(from).getTime();
    const toMillis = new Date(to).getTime();
    onExportReport(fromMillis, toMillis);
  };

  useEffect(() => {

    if (!firstRenderRef.current) return;

    const loadData = async () => {
      setisLoadingEmp(true);
      try {
        await api.leave.settings.getAll();
        await api.leave.profiles.getAll();
        await api.auth.loadAll();
        await api.department.getAll();

        firstRenderRef.current = false;
        setisLoadingEmp(false);

      } catch (error) { }

    };
    loadData();

    // console.log("Ruddex");

  }, [api.leave.profiles, api.leave.settings, api.auth, api.department]);


  if (isloadingEmp) return (
    <LoadingEllipsis />
  )

  return (
    <div className="uk-section leave-analytics-page">
      <div className="uk-container uk-container-large">
        <div className="section-toolbar uk-margin">
          <h4 className="section-heading uk-heading">Employee leave profiles of {search}</h4>
          <div className="controls">
            <div className="uk-margin uk-margin-right uk-width-1-1">
              <select className="uk-select uk-form-small"
                value={search}
                aria-label="Select"
                onChange={onSearch}>
                {options.map((option, index) => (
                  <option key={index} value={option.value}>{option.label}</option>
                ))}
              </select>
            </div>
            <div className="uk-inline">
              <button className="uk-button primary" type="button">
                Export Report
              </button>
              <form data-uk-dropdown="mode: click" onSubmit={onExport}>
                <div className="uk-margin">
                  <label htmlFor="from-report">From</label>
                  <br />
                  <input
                    id="from-report"
                    type="date"
                    className="uk-input uk-form-small"
                    value={from}
                    onChange={(e) => setFrom(e.target.value)}
                    required
                  />
                </div>
                <div className="uk-margin">
                  <label htmlFor="to-report">To</label>
                  <br />
                  <input
                    id="to-report"
                    type="date"
                    className="uk-input uk-form-small"
                    value={to}
                    onChange={(e) => setTo(e.target.value)}
                    required
                  />
                </div>
                <button className="uk-button primary uk-margin" type="submit">
                  Export PDF
                  {loading && <div data-uk-spinner="ratio: .5"></div>}
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="uk-margin">
          {groupedByDepartment().map((dep, index) => (
            <CustomCloseAccordion title={dep.department} key={index}>
              <ErrorBoundary>
                <div className="section-toolbar uk-margin uk-margin-top">
                  <div className="controls">
                    <div className="uk-margin">
                      <select className="uk-select uk-form-small uk-width-2-3"
                        value={search}
                        aria-label="Select"
                        onChange={onSearch}>
                        {options.map((option, index) => (
                          <option key={index} value={option.value}>{option.label}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                {dep.profiles.map((profile, index) => (
                  <CustomCloseAccordion title={profile.userName} key={index}>
                    <LeaveProfileBarGraph profile={profile.asJson} />
                  </CustomCloseAccordion>
                ))}
              </ErrorBoundary>
            </CustomCloseAccordion>
          ))}
        </div>
      </div>
    </div>
  );
});

export default LeaveAnalytics;
