import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { useAppContext } from "../../../shared/functions/Context";
import IPayrollEmployee from "../../../shared/interfaces/IPayrollEmployee";
import EmployeeItemPaymentSummary from "./EmployeeItemPaymentSummary";
import { CustomNumberFormat } from "../../../shared/functions/CustomNumberFormat";
import { FailedAction, SuccessfulAction } from "../../../shared/models/Snackbar";
import GeneratePayslipPDF from "../../../shared/functions/PDF/payroll/GeneratePayslipPDF";
import { LoadingEllipsis } from "../../../shared/components/Loading";

interface IProps {
  payrollId: string;
  isExcluded: boolean;
  _employee: IPayrollEmployee;
  status: string;
}

const EmployeeItem = observer((props: IProps) => {
  const { payrollId, isExcluded, _employee, status } = props;
  const { api, store, ui } = useAppContext();

  const [employee, setEmployee] = useState<IPayrollEmployee | undefined>(undefined);

  const [exporting, setExporting] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [loading, setloading] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);

  const year = new Date().getFullYear()
  const userId = _employee.uid.concat(`_${year}`);
  const leave = store.leave.profiles.getByUid(userId)
  const imageLogo = store.settings.theme?.logoUrl || "";

  const navigate = useNavigate();
  const location = useLocation();

  const onViewSummary = () => {
    setIsExpanded(!isExpanded);
  };

  const onEdit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    const path = location.pathname;
    if (employee) navigate(path + "/" + employee.uid);
  };

  const onExclude = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();

    if (!employee) return;

    setloading(true);
    const $employee = { ...employee, isExcluded: true, payrollId };
    try {
      await api.payroll.employee.update(payrollId, $employee);
      SuccessfulAction(ui);
    } catch (error) {
      FailedAction(ui);
    }
    setloading(false);
  };

  const onInclude = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();

    if (!employee) return;

    setloading(true);
    const $employee = { ...employee, isExcluded: false, payrollId };
    try {
      await api.payroll.employee.update(payrollId, $employee);
      SuccessfulAction(ui);
    } catch (error) {
      FailedAction(ui);
    }
    setloading(false);
  };

  const onDelete = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    try {
      setDeleteLoader(true);
      if (!employee) return;
      await api.payroll.employee.delete(payrollId, _employee.uid);

      SuccessfulAction(ui);
    } catch (error) {
      FailedAction(ui);
    }
    setDeleteLoader(false);
  };

  const onExport = async () => {
    setExporting(true)
    if (employee) {
      try {
        await GeneratePayslipPDF(employee, leave);
      } catch (error) { }
    } else {
      alert("Employee is not defined.");
    }
    setExporting(false)
  };

  useEffect(() => {
    const loadData = async () => {
      await api.leave.profiles.getAll()
    }
    loadData()
    setEmployee({ ..._employee });
  }, [_employee, api.leave.profiles]);

  return (
    <>
      {employee && (
        <div className={`employee-item uk-card uk-card-${isExcluded ? "excluded" : "included"} uk-card-small uk-card-body`}
          onClick={onViewSummary}>
          <div className="position-relative">
            {exporting && (<LoadingEllipsis />)}
            <div
              className="uk-grid-small uk-child-width-1-2 uk-child-width-1-3@m uk-child-width-1-4@l"
              data-uk-grid
            >
              <div className="employee">
                <p className="label">Employee</p>
                <p className="value">
                  {employee.firstName} {" "}  {employee.lastName}
                </p>
              </div>
              <div className="position">
                <p className="label">Position</p>
                <p className="value">{employee.position}</p>
              </div>
              <div className="salary_grade">
                <p className="label">Salary Grade</p>
                <p className="value">{employee.salaryGrade}</p>
              </div>
              <div className="net_income">
                <p className="label">Net Income</p>
                <p className="value">
                  N${CustomNumberFormat(employee.paySummary.netIncome)}
                </p>
              </div>
            </div>
            <div className="more">
              <span
                className={`icon ${isExpanded ? "expanded" : ""}`}
                data-uk-icon="chevron-down"
              ></span>
            </div>
          </div>
          <EmployeeItemPaymentSummary
            key={employee.uid}
            status={status}
            loading={loading}
            deleteLoader={deleteLoader}
            isExpanded={isExpanded}
            paymentSummary={employee.paySummary}
            isExcluded={isExcluded}
            onEdit={onEdit}
            onInclude={onInclude}
            onExclude={onExclude}
            onExport={onExport}
            onDelete={onDelete}
          />
        </div>
      )}
    </>
  );
});

export default EmployeeItem;
