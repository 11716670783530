import Modal from "../../../shared/components/Modal"
import { useAppContext } from "../../../shared/functions/Context";
import showModalFromId from "../../../shared/functions/ModalShow";
import DIALOG_NAMES from "../../dialogs/Dialogs"
import { useEffect, useState } from "react";
import { addDoc, collection, doc, getDoc, getDocs, onSnapshot, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../shared/database/FirebaseConfig";
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import bg from "./assets/order2.svg"
import supplier from "./assets/supplier.svg"
import Loading from "../../../shared/components/Loading";
import { ISalesOrder } from "../../../shared/models/InventorySalesOrder";



interface PurchaseOrderItem {
    itemId: number | string,
    itemName: string,
    itemQty: number,
    itemDescription: string,
    itemUnitPrice: number,
    discountPercentage: 0
}



export const SalesOrder = () => {
    const { store, api } = useAppContext();


    useEffect(() => {
        const getData = async () => {
            await api.inventory.products.getAll();
            await api.inventory.suppliers.getAll();
        }
        getData();
    }, [api.inventory.products, api.inventory.suppliers])


    const [createPurchase, setCreateSalesOrder] = useState<PurchaseOrderItem[]>([]);



    const handleRemoveProduct = (index: number) => {
        setCreateSalesOrder((prevPurchase) => {
            const updatedPurchase = [...prevPurchase];
            updatedPurchase.splice(index, 1);
            return updatedPurchase;
        });
    };

    const [addedQtyList, setAddedQtyList] = useState([]); // State variable for quantity values

    const updateAddedQty = (index: any, value: any) => {
        const newList: any = [...addedQtyList];
        newList[index] = value;
        setAddedQtyList(newList);
    };

    const currentUser = store.user.me;
    const [loading, setLoading] = useState(false)
    const [orderNumber, setOrderNumber] = useState(0)



    useEffect(() => {
        function generateRandomNumber() {
            const randomNumber = Math.floor(Math.random() * 900000000) + 100000000;
            setOrderNumber(randomNumber);
        }
        generateRandomNumber();
    }, []);




    const [totalAmount, setTotalAmount] = useState(0);


    useEffect(() => {
        const getNetTotal = () => {
            const netPrice = createPurchase.reduce((total, item) => {
                return total + item.itemQty * item.itemUnitPrice;
            }, 0);
            setTotalAmount(netPrice);
        };
        getNetTotal();
    }, [createPurchase, totalAmount]);



    const createSalesOrder = async (e: any) => {
        e.preventDefault();
        const PurchaseOrderData = {
            purchaseOrderId: "",
            orderNumber: orderNumber,
            submiitedBy: currentUser?.asJson.uid,
            total: totalAmount,
            status: "Pending",
            orderItems: createPurchase,
            pop: "",
            attachment: [],
        };
        setLoading(true);
        const docRef = doc(collection(db, "InventorySalesOrder"));
        PurchaseOrderData.purchaseOrderId = docRef.id;
        await setDoc(docRef, PurchaseOrderData, { merge: true });
        setCreateSalesOrder([]);
        getPurchase();
        setLoading(false);
        const randomNumber = Math.floor(Math.random() * 900000000) + 100000000;
        setOrderNumber(randomNumber);
    }

    const [purchaseO, setPurchaseO] = useState<ISalesOrder[]>([]);

    const getPurchase = async () => {
        setLoading(true);
        const colRef = collection(db, "InventorySalesOrder");
        const docsSnap = await getDocs(colRef);
        const list: any = [];
        docsSnap.forEach((doc) => {
            list.push(doc.data());
        })
        setPurchaseO(list);
        setLoading(false);
    }

    const [purchaseId, setPurchaseId] = useState("")

    const onView = (id: string) => {
        setPurchaseId(id)
        showModalFromId(DIALOG_NAMES.INVENTORY.PURCHASE_ORDER_VIEW);
    }

    const [viewedPurchseOrder, setViewedPurchasedOrder] = useState<any>([])

    const [viewLoading, setViewLoading] = useState(false)



    useEffect(() => {
        const viewPurchasedOrder = async () => {
            if (purchaseId) {
                setViewLoading(true)
                const colRef = doc(db, "InventorySalesOrder", purchaseId);
                const docSnap = await getDoc(colRef);
                if (docSnap.exists()) {
                    setViewedPurchasedOrder(docSnap.data());
                }
            }
            setViewLoading(false)
        }
        viewPurchasedOrder();
        getPurchase();
    }, [purchaseId])

    const [newStatus, setNewStatus] = useState("");

    const [statusLoader, setStatusLoader] = useState(false)

    const statusUpdate = async () => {
        if (window.confirm(newStatus)) {
            try {
                setStatusLoader(true);
                const docRef = doc(db, "InventorySalesOrder", purchaseId);

                // Update the status in the Purchase Order document
                await updateDoc(docRef, { status: newStatus });

                // Create a log entry in the Logs subcollection

                const actionDateTime = new Date().toISOString();
                const formattedDate = new Date(actionDateTime).toLocaleDateString();
                const formattedTime = new Date(actionDateTime).toLocaleTimeString();

                const logData = {
                    person: currentUser?.asJson.email,
                    orderId: purchaseId,
                    status: newStatus,
                    actionDateTime: {
                        date: formattedDate,
                        time: formattedTime,
                    },
                };
                // Get a reference to the Logs subcollection for the specific purchase order
                const logsCollectionRef = collection(docRef, "Logs");

                // Add the log entry to the Logs subcollection
                await addDoc(logsCollectionRef, logData);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setViewedPurchasedOrder(docSnap.data());
                }
            } catch (error) {
                console.log("Error creating", error);
            }
            getPurchase();
            setStatusLoader(false);
        }
    };

    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [log, setLog] = useState<any[]>([])
    const [loadLogData, setLogDataLoad] = useState(false);
    const [totalNetPrice, setTotalNetPrice] = useState(0);


    const getLogsData = async () => {
        try {
            setLogDataLoad(true)
            const docRef = collection(db, "InventorySalesOrder", purchaseId, "Logs");
            const querySnapshot = await getDocs(docRef);
            const logsData = querySnapshot.docs.map((doc) => doc.data());
            setLog(logsData); // Set the log data in the state
            setLogDataLoad(false)
        } catch (error) {
            console.log("Error retrieving logs data", error);
            setLog([]); // Set an empty array in case of an error
        }
    };

    const [submitedUser, setSumitedUser] = useState<string[]>([])


    useEffect(() => {
        const getUser = async () => {
            if (viewedPurchseOrder.submiitedBy && store.user.all.length > 0) {
                const user = store.user.all.find((user) => user.uid === viewedPurchseOrder.submiitedBy);
                if (user) {
                    const userName = user.email;
                    setSumitedUser([userName]);
                }
            }
        };
        getUser();
    }, [store.inventory.supplier.all, store.user.all, viewedPurchseOrder.submiitedBy, viewedPurchseOrder.supplierId]);


    const [selectedStatus, setSelectedStatus] = useState('');

    const handleFilterByStatus = (status: string) => {
        setSelectedStatus(status);
    };

    const [orderSearch, setOrderSearch] = useState("")

    const filteredPurchaseO = selectedStatus
        ? purchaseO.filter(
            (purchase) =>
                purchase.status === selectedStatus &&
                purchase.orderNumber &&
                purchase.orderNumber.toString().includes(orderSearch)
        )
        : purchaseO.filter((purchase) =>
            purchase.orderNumber &&
            purchase.orderNumber.toString().includes(orderSearch)
        );



    // Handler for order number search input
    const handleOrderSearch = (event: any) => {
        setOrderSearch(event.target.value);
    };



    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file && file.type === 'application/pdf') {
            setSelectedFile(file);
        } else {
            setSelectedFile(null);
            console.log('Invalid file type. Please select a PDF file.');
        }
    };

    const storage = getStorage();


    const [progress, setProgress] = useState(0)

    const handleUpload = async () => {
        if (selectedFile) {
            try {
                const storageRef = ref(storage, selectedFile.name);
                const uploadTask = uploadBytesResumable(storageRef, selectedFile);
                uploadTask.on('state_changed',
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log(`Upload progress: ${progress}%`);
                        setProgress(progress)
                    },
                    (error) => {
                        console.log('Error uploading file:', error);
                    },
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref)
                            .then((fileUrl) => {
                                const docId = purchaseId; // Replace with the actual document ID
                                const docRef = doc(db, 'InventorySalesOrder', docId);
                                return updateDoc(docRef, { pop: fileUrl });
                            })
                            .then(() => {
                                console.log('Document updated successfully.');
                                alert('Uploaded');
                                const viewPurchasedOrder = async () => {
                                    if (purchaseId) {
                                        setViewLoading(true)
                                        const colRef = doc(db, "InventorySalesOrder", purchaseId);
                                        const docSnap = await getDoc(colRef);
                                        if (docSnap.exists()) {
                                            setViewedPurchasedOrder(docSnap.data());
                                        }
                                    }
                                    setViewLoading(false)
                                }
                                viewPurchasedOrder();
                            })
                            .catch((error) => {
                                console.log('Error updating document:', error);
                            });
                    }
                );
            } catch (error) {
                console.log('Error uploading file:', error);
            }
        } else {
            console.log('No file selected. Please choose a PDF file.');
        }
    };

    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    return (
        <div className="uk-section leave-analytics-page sales-order">
            <div className="uk-container uk-container-large">
                <div className="section-toolbar uk-margin">
                    <h4 className="section-heading uk-heading">SALES ORDERS</h4>
                    <div className="controls">
                        {/* <div className="uk-inline">
                            <button className="uk-button primary" type="button" data-uk-toggle="target: #offcanvas-flip">
                                Audit Trail
                            </button>
                        </div> */}
                    </div>
                </div>
                <div className="uk-child-width-1-1@m uk-grid-small uk-grid-match" data-uk-grid>
                    <div>
                        <div style={{ backgroundPosition: "right", backgroundSize: "15%", backgroundRepeat: "no-repeat" }} className="uk-card uk-card-default uk-card-body">
                            <div className="uk-grid-small uk-child-width-1-1@m" data-uk-grid>
                            </div>
                            <table className="uk-table uk-table-small uk-table-divider">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Description</th>
                                        <th>Unit Price</th>
                                        <th>Current quantity</th>
                                        <th>Adjust quantity</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {store.inventory.products.all.map((prod, index: any) => (
                                        <tr key={prod.asJson.id}>
                                            <td>{prod.asJson.productName}</td>
                                            <td>{prod.asJson.productDescription}</td>
                                            <td>N${prod.asJson.price?.toFixed(2)}</td>
                                            <td>10</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    min={0}
                                                    max={1000}
                                                    className="uk-input"
                                                    style={{ height: "2rem", width: "6rem", background: "transparent" }}
                                                    onChange={(e: any) => updateAddedQty(index, e.target.value)}
                                                    value={addedQtyList[index] || ""} // Use the corresponding quantity value from the state
                                                />
                                            </td>
                                            <td>
                                                <button
                                                    className="uk-button primary"
                                                    type="button"
                                                    onClick={() => {

                                                        const quantity = parseInt(addedQtyList[index]);

                                                        // Check if quantity is empty, zero, or not entered
                                                        if (!quantity || isNaN(quantity) || quantity === 0) {
                                                            alert('Please enter a valid quantity.');
                                                            return; // Stop further execution
                                                        }


                                                        const newItem: PurchaseOrderItem = {
                                                            itemId: prod.asJson.id,
                                                            itemName: prod.asJson.productName,
                                                            itemDescription: prod.asJson.productDescription,
                                                            itemQty: parseInt(addedQtyList[index]),
                                                            itemUnitPrice: prod.asJson.price,
                                                            discountPercentage: 0,
                                                        };

                                                        const existingProduct = createPurchase.find((item) => item.itemId === newItem.itemId);
                                                        if (existingProduct) {
                                                            alert('Product already exists in the list!');
                                                            updateAddedQty(index, ""); // Clear the quantity value for the current product
                                                            return; // Stop further execution
                                                        }

                                                        setCreateSalesOrder((prevItems) => [...prevItems, newItem]);
                                                        updateAddedQty(index, ""); // Clear the quantity value for the current product
                                                    }}
                                                >
                                                    Add Product
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div>
                        {createPurchase.map((prod) => prod).length === 0 ?
                            <></>
                            :
                            <>
                                <div className="uk-card uk-card-default uk-card-body">
                                    <div className="section-toolbar uk-margin">
                                        <h4 className="section-heading uk-heading">Sales Order</h4>
                                        <div className="controls">
                                            <div className="uk-inline">
                                                {createPurchase.map((purchase) => purchase).length > 0 &&
                                                    <button className="uk-button primary" type="button" onClick={createSalesOrder} >
                                                        {loading ? <>loading...</> : <>Create Order</>}
                                                    </button>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                    <table className="uk-table uk-table-small uk-table-divider">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Description</th>
                                                <th>Quantity</th>
                                                <th>Unit Price</th>
                                                <th>Total Price</th>
                                                <th style={{ textAlign: "right" }}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {createPurchase.map((prod, index) => (
                                                <tr key={index}>
                                                    <td>{prod.itemName}</td>
                                                    <td>{prod.itemDescription}</td>
                                                    <td>{prod.itemQty}</td>
                                                    <td>N${prod.itemUnitPrice?.toFixed(2)}</td>
                                                    <td>N${(prod.itemUnitPrice * prod.itemQty).toFixed(2)}</td>
                                                    <td style={{ textAlign: "right" }}>
                                                        <button
                                                            className="uk-button danger"
                                                            type="button"
                                                            onClick={() => handleRemoveProduct(index)}
                                                        >
                                                            Remove
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                            {createPurchase.map((prod) => prod).length === 0 && <p style={{ color: "red" }}>No products</p>}
                                        </tbody>
                                    </table>
                                    <h4 style={{ fontWeight: "600", color: "grey" }}>NET TOTAL: N${totalAmount?.toFixed(2)}</h4>
                                </div>
                            </>
                        }

                    </div>


                    <div>
                        <div className="uk-card uk-card-default uk-card-body">
                            <div className="section-toolbar uk-margin">
                                <h4 className="section-heading uk-heading">Sales Order list</h4>
                            </div>
                            <div className="uk-inline uk-margin">
                                <button
                                    className={`uk-button primary ${selectedStatus === '' ? 'selected' : ''}`}
                                    type="button"
                                    onClick={() => handleFilterByStatus('')}
                                >
                                    All
                                </button>
                                <button
                                    className={`uk-button primary uk-margin-left ${selectedStatus === 'Pending' ? 'selected' : ''}`}
                                    type="button"
                                    onClick={() => handleFilterByStatus('Pending')}
                                >
                                    Pending
                                </button>
                                <button
                                    className={`uk-button primary uk-margin-left ${selectedStatus === 'Hold' ? 'selected' : ''}`}
                                    type="button"
                                    onClick={() => handleFilterByStatus('Hold')}
                                >
                                    Hold
                                </button>
                                <button
                                    className={`uk-button primary uk-margin-left ${selectedStatus === 'Received' ? 'selected' : ''}`}
                                    type="button"
                                    onClick={() => handleFilterByStatus('Paid')}
                                >
                                    Paid
                                </button>
                                <button
                                    className={`uk-button primary uk-margin-left ${selectedStatus === 'Fulfilled' ? 'selected' : ''}`}
                                    type="button"
                                    onClick={() => handleFilterByStatus('Fulfilled')}
                                >
                                    Fulfilled
                                </button>
                            </div>
                            <div>
                                <h4 style={{ fontWeight: "700", color: "grey" }}>{selectedStatus}</h4>
                            </div>
                            {loading ? <Loading /> :
                                <>
                                    <input
                                        style={{ width: "20%" }}
                                        type="text"
                                        placeholder="Search by order number"
                                        className="uk-input uk-margin"
                                        value={orderSearch}
                                        onChange={handleOrderSearch}
                                    />
                                    <div className="uk-child-width-1-3@m uk-grid-small uk-grid-match" data-uk-grid>
                                        {filteredPurchaseO.map((purchase: any) => (
                                            <div>
                                                <div style={{ backgroundImage: `url(${bg})`, backgroundPosition: "right", backgroundSize: "20%", backgroundRepeat: "no-repeat" }} className="uk-card uk-card-default uk-card-body uk-card-custom">
                                                    <h3 className="" style={{ fontWeight: "800", color: "grey" }}>Order No. {purchase.orderNumber}</h3>
                                                    <div className="uk-inline">
                                                        <button className="uk-button primary" type="button" onClick={() => onView(purchase.purchaseOrderId)}>
                                                            View Order
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}


                                        {filteredPurchaseO.length === 0 && <p style={{ color: "red" }}>No sales orders</p>}
                                    </div>
                                </>
                            }

                        </div>
                    </div>
                </div>
            </div>
            <Modal modalId={DIALOG_NAMES.INVENTORY.PURCHASE_ORDER_VIEW}>
                <div className="uk-modal-dialog uk-modal-body margin-auto-vertical" style={{ width: "100%" }}>
                    <button
                        className="uk-modal-close-default"
                        type="button"
                        data-uk-close
                    />
                    {viewLoading ?
                        <Loading />
                        :
                        <div style={{ backgroundImage: `url(${supplier})`, backgroundPosition: "right", backgroundSize: "20%", backgroundRepeat: "no-repeat" }} >

                            <div className="uk-inline">
                                <button className="uk-button primary" type="button" data-uk-toggle="target: #offcanvas-flip" onClick={getLogsData}>
                                    Audit Trail
                                </button>
                            </div>
                            <br />
                            <br />
                            <br />
                            {viewedPurchseOrder.status !== "Fulfilled" && (
                                <>
                                    <label htmlFor="">Update Status</label><br />
                                    <select
                                        name=""
                                        id=""
                                        className="uk-input uk-form-small"
                                        style={{ width: "10%" }}
                                        value={newStatus}
                                        onChange={(e) => setNewStatus(e.target.value)}
                                    >
                                        <option value="">Select</option>

                                        {viewedPurchseOrder.pop === "" ? (
                                            <>
                                                <option value="Pending">Pending</option>
                                                <option value="Hold">Hold</option>
                                            </>
                                        ) : (
                                            <>
                                                {viewedPurchseOrder.status === "Paid" ? (
                                                    <>
                                                        <option value="Fulfilled">Fulfilled</option>
                                                    </>
                                                ) : (
                                                    <>
                                                        <option value="Paid">Paid</option>
                                                    </>
                                                )
                                                }
                                            </>
                                        )}
                                    </select>
                                    {newStatus !== "" &&
                                        <button className="uk-button primary uk-margin-left" type="button" onClick={statusUpdate}>Update Status</button>
                                    }
                                </>
                            )}
                            {viewedPurchseOrder.status === "Fulfilled" && <p style={{ color: "green", fontWeight: "600" }}>Sales Order Completed Successfully</p>}
                            <br />
                            <br />
                            <div className="uk-child-width-1-1@m uk-grid-small uk-grid-match" data-uk-grid>
                                <div>
                                    <div className="uk-card uk-card uk-card-body" >
                                        <p className="" style={{ fontWeight: "600", color: "grey" }}>Order Number: {viewedPurchseOrder.orderNumber}</p>
                                        <p className="" style={{ fontWeight: "600", color: "grey" }}>Net Price: N$ {viewedPurchseOrder.total?.toFixed(2)}</p>
                                        <p className="" style={{ fontWeight: "600", color: "grey" }} >Submitted by: {submitedUser}</p>
                                        {statusLoader ?
                                            <p>updating status....</p> :
                                            <>
                                                <p className="" style={{ fontWeight: "600", color: "grey" }}>Status: {viewedPurchseOrder.status}</p>
                                            </>
                                        }
                                        {viewedPurchseOrder.pop === "" && (
                                            <>
                                                <div className="uk-margin" data-uk-margin>
                                                    <div uk-form-custom="target: true">
                                                        <input
                                                            type="file"
                                                            accept="application/pdf"
                                                            aria-label="Custom controls"
                                                            onChange={handleFileChange}
                                                        />
                                                        <input
                                                            className="uk-input uk-form-width-medium"
                                                            type="text"
                                                            placeholder="Select POP"
                                                            aria-label="Custom controls"
                                                            disabled
                                                        />
                                                    </div>
                                                    <button className="uk-button primary uk-margin-left uk-button-default" onClick={handleUpload}>
                                                        Upload POP
                                                    </button>
                                                </div>
                                                <progress
                                                    style={{ width: "50%" }}
                                                    id="js-progressbar"
                                                    className="uk-progress"
                                                    value={progress}
                                                    max="100"
                                                ></progress>
                                            </>
                                        )}
                                        {viewedPurchseOrder.pop !== "" && <a target="blank" href={viewedPurchseOrder.pop}>view POP</a>}
                                    </div>
                                </div>
                            </div>
                            <div className="uk-card" style={{ marginTop: "4rem" }}>
                                <table className="uk-table uk-table-small uk-table-divider">
                                    <thead>
                                        <tr>
                                            <th>Product Name</th>
                                            <th>Product Description</th>
                                            <th>Product Quantity</th>
                                            <th>Product Unit Price</th>
                                            <th>Product Total Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {viewedPurchseOrder?.orderItems?.map((pur: any, index: any) => (
                                            <tr key={index}>
                                                <td>{pur.itemName}</td>
                                                <td>{pur.itemDescription}</td>
                                                <td>{pur.itemQty}</td>
                                                <td>N${pur.itemUnitPrice?.toFixed(2)}</td>
                                                <td>N${(pur.itemQty * pur.itemUnitPrice).toFixed(2)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                </div>
            </Modal>
            <div id="offcanvas-flip" data-uk-offcanvas="flip: true; overlay: true" data-bg-close={false}>
                <div className="uk-offcanvas-bar">
                    <button className="uk-offcanvas-close" type="button" data-uk-close onClick={() => onView(purchaseId)}></button>

                    <h3>LOGS</h3>
                    {loadLogData ? <p>Loading logs.....</p> :
                        <>
                            {log.map((log: any, Index: number) => (
                                <ul className="uk-list uk-list-divider uk-list-striped" key={Index}>
                                    <li > Status changed to <strong>{log.status}</strong>  by  <strong>{log.person}</strong>.
                                        <br />
                                        Date: {log.actionDateTime.date}
                                        <br />
                                        Time: {log.actionDateTime.time}
                                    </li>
                                </ul>
                            ))}
                            {log.map((log: any) => log).length === 0 && <p style={{ color: "red" }}>No logs</p>}
                        </>
                    }
                </div>
            </div>
        </div >
    )
}