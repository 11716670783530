export const defaultPayroll: IPayroll = {
  id: "",
  folderId: "",
  title: "",
  description: "",
  deadline: "",
  deadlineMs: Date.now(),
  amount: 0,
  status: "draft",
  createdAt: Date.now(),
  updatedAt: Date.now(),
  approval: [],
};

export const defaultApproval: IApprovalInput = {
  status: "pending",
  email: "",
  reason: "",
};


export interface IApprovalInput {
  status: IApprovalStatus; //pending | approved
  email: string; //who to approve
  reason: string; //reason for rejection a payroll
}

export type IPayrollStatus = "draft" | "approved" | "completed" | "in-progress";
export type IApprovalStatus = "pending" | "approved" | "rejected";

interface IPayroll {
  id: string;
  folderId: string;
  title: string;
  description: string;
  deadline: string;
  deadlineMs: number;
  amount: number;
  status: IPayrollStatus; // "draft"  | "approved" | "completed" | "in-progress";
  createdAt: number;
  updatedAt: number;
  approval: IApprovalInput[];
}
export default IPayroll;
