import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import Loading, { LoadingEllipsis } from "../../../shared/components/Loading";
import { useAppContext } from "../../../shared/functions/Context";
import GeneratePayslipPDF from "../../../shared/functions/PDF/payroll/GeneratePayslipPDF";
import IPayrollEmployee, { defaultPayrollEmployee, } from "../../../shared/interfaces/IPayrollEmployee";
import EmployeePaymentSummary from "./EmployeePaymentSummary";

const EmployeePayroll = observer(() => {

  const { api, store } = useAppContext();
  let { payrollId } = useParams<{ payrollId: string }>();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [employee, setEmployee] = useState<IPayrollEmployee>({ ...defaultPayrollEmployee });

  const me = store.user.meJson;
  const payroll = store.payroll.batch.items.get(payrollId!);
  const leave = store.leave.profiles.getByUid(employee.uid)
  // const imageLogo = store.settings.theme?.logoUrl || "";

  const onViewSummary = () => {
    setIsExpanded(!isExpanded);
  };

  const onExport = async () => {
    setExporting(true)
    if (employee) {
      await GeneratePayslipPDF(employee, leave);
    } else {
      alert("Employee is not defined.");
    }
    setExporting(false)
  };

  useEffect(() => {
    const loadStore = () => {
      if (!me || !payrollId) return;
      const _employee = store.payroll.employee.getById(me.uid);
      if (_employee && _employee.payrollId === payrollId) {
        setEmployee({ ..._employee.asJson, payrollId });
      }
    };
    loadStore();
  }, [payrollId, store.payroll.employee, store.payroll.employee.all.length, me,]);

  useEffect(() => {
    setLoading(true);
    const loadDB = async () => {
      if (!me || !payrollId) return;
      store.payroll.employee.remove(me.uid);
      await api.payroll.employee.getById(payrollId, me.uid);
    };
    if (payroll) loadDB(); else navigate("/c/payroll/fy")
    setLoading(false);
  }, [api.payroll.employee, payrollId, payroll, store.payroll.employee, me, navigate]);

  if (loading) return (
    <div className="uk-card uk-card-default uk-card-body">
      <Loading />
    </div>
  )

  if (employee.payrollId !== payrollId)
    return (
      <div className="uk-section employee-not-found-page">
        <div className="uk-container uk-container-large">
          <div className="uk-card uk-card-default uk-card-body">
            <h5>
              <span className="uk-margin-right uk-text-primary go-back-text"
                onClick={() => navigate("/c/payroll/fy")} >
                Seems like your data does not exit on this payroll.
              </span>
            </h5>
          </div>
        </div>
      </div>
    );

  return (
    <div className="uk-section view-payroll-page">
      <div className="uk-container uk-container-large">
        {payroll && (
          <div>
            <div className="section-toolbar uk-margin">
              <h4 className="section-heading uk-heading go-back" onClick={() => navigate("/c/payroll/fy")}>
                <span data-uk-icon="chevron-left"></span>
                {payroll.title}
              </h4>
            </div>
            <div className="employee-list">
              <div className={`employee-item uk-card uk-card-hover uk-card-${employee.isExcluded ? "outlined excluded" : "default"} uk-card-small uk-card-body`}
                onClick={onViewSummary} >
                <div className="position-relative">
                  {exporting && (<LoadingEllipsis />)}
                  <div className="uk-grid-small uk-child-width-1-2 uk-child-width-1-3@m uk-child-width-1-4@l"
                    data-uk-grid>
                    <div className="employee">
                      <p className="label">Employee</p>
                      <p className="value">
                        {employee.firstName} {employee.lastName}
                      </p>
                    </div>
                    <div className="position">
                      <p className="label">Position</p>
                      <p className="value">{employee.position}</p>
                    </div>
                    <div className="salary_grade">
                      <p className="label">Salary Grade</p>
                      <p className="value">{employee.salaryGrade}</p>
                    </div>
                    <div className="net_income">
                      <p className="label">Net Income</p>
                      <p className="value">
                        N${employee.paySummary.netIncome.toFixed(2)}
                      </p>
                    </div>
                  </div>
                  <div className="more">
                    <span
                      className={`icon ${isExpanded ? "expanded" : ""}`}
                      data-uk-icon="chevron-down"
                    ></span>
                  </div>
                </div>
                <EmployeePaymentSummary
                  isExpanded={isExpanded}
                  paymentSummary={employee.paySummary}
                  isExcluded={employee.isExcluded}
                  onExport={onExport}
                  employee={employee}
                  setEmployee={setEmployee}
                />
              </div>
            </div>
          </div>
        )}

      </div>
    </div>
  );
});

export default EmployeePayroll;
