import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore, Timestamp } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
apiKey: "AIzaSyCuQIePjO8Qzd9mp4byG09a3WkHXVRLlCY",
authDomain: "accounting-a5b6c.firebaseapp.com",
projectId: "accounting-a5b6c",
storageBucket: "accounting-a5b6c.appspot.com",
messagingSenderId: "273206915942",
appId: "1:273206915942:web:ed86dcf63638a56e27cea7",
measurementId: "G-C2PB639F89",
};

const app = initializeApp(firebaseConfig);
const firebaseAuthworkerApp = initializeApp(firebaseConfig, "authWorker");

export const analytics = getAnalytics(app);
export const storage = getStorage(app);
export const db = getFirestore(app);

export const auth = getAuth(app);
export const authWorker = getAuth(firebaseAuthworkerApp);

export const timestamp = Timestamp;


//developing
// apiKey: "AIzaSyBHb83Y1Oi4hEkPFnkMnv1p47igIVSLDkM",
// authDomain: "lots-accounting-app.firebaseapp.com",
// projectId: "lots-accounting-app",
// storageBucket: "lots-accounting-app.appspot.com",
// messagingSenderId: "609459541468",
// appId: "1:609459541468:web:426df5f309d59e9fafab19",
// measurementId: "G-25DL9TQRVZ",


//lots database
// apiKey: "AIzaSyCuQIePjO8Qzd9mp4byG09a3WkHXVRLlCY",
// authDomain: "accounting-a5b6c.firebaseapp.com",
// projectId: "accounting-a5b6c",
// storageBucket: "accounting-a5b6c.appspot.com",
// messagingSenderId: "273206915942",
// appId: "1:273206915942:web:ed86dcf63638a56e27cea7",
// measurementId: "G-C2PB639F89",

// dinapama database
// apiKey: "AIzaSyARAmBEZXLMQB9D4HPwWDmKcmmd2oOc1RI",
// authDomain: "dinapama-app.firebaseapp.com",
// projectId: "dinapama-app",
// storageBucket: "dinapama-app.appspot.com",
// messagingSenderId: "900138151131",
// appId: "1:900138151131:web:223b7a5fcd1090a0ccf0fe",
// measurementId: "G-4DMPKNF8L7"

