import DepartmentStore from "./individualStore/DepartmentStore";
import LeaveStore from "./groupStore/LeaveStore";
import PayrollStore from "./groupStore/PayrollStore";
import UserStore from "./individualStore/UserStore";
import InvoicingStore from "./groupStore/InvoicingStore";
import FileViewerStore from "./individualStore/FileViewerStore";
import RegionStore from "./individualStore/RegionStore";
import PayrollFolderStore from "./individualStore/PayrollFolderStore";
import InventoryStore from "./groupStore/InventoryStore";
import SystemThemeStore from "./individualStore/SystemThemeStore";

export default class AppStore {
  leave = new LeaveStore(this);
  department = new DepartmentStore(this);
  user = new UserStore(this);
  payroll = new PayrollStore(this);
  invoicing = new InvoicingStore(this);
  //
  fileViewer = new FileViewerStore(this);
  region = new RegionStore(this);
  payrollfolder = new PayrollFolderStore(this);

  // inventory
  inventory = new InventoryStore(this);

  // System Settings
  settings = new SystemThemeStore(this);
}
