import { observer } from "mobx-react-lite";
import { useAppContext } from "../../../shared/functions/Context";
import showModalFromId from "../../../shared/functions/ModalShow";
import { TaxRate } from "../../../shared/models/TaxRate";
import DIALOG_NAMES from "../../dialogs/Dialogs";

interface IItemProps {
  taxRate: TaxRate;
}

const TaxRateItem = observer((props: IItemProps) => {
  const { api, store, ui } = useAppContext();
  const { taxRate } = props;

  const onEdit = () => {
    store.payroll.taxRate.select(taxRate.asJson);
    showModalFromId(DIALOG_NAMES.PAYROLL.TAX_RATE_DIALOG);
  };

  const onRemove = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();

    if (!window.confirm("Delete tax rate?")) return;

    try {
      await api.payroll.taxRate.delete(taxRate.id);
      store.payroll.taxRate.remove(taxRate.id);
      ui.snackbar.load({
        id: Date.now(),
        message: "Rate deleted!",
        type: "success",
      });
    } catch (error) {
      ui.snackbar.load({
        id: Date.now(),
        message: "Error! Failed to remove tax rate.",
        type: "danger",
      });
    }
  };

  return (
    <>
      <div className="tax-rate-item uk-card uk-card-small uk-card-body">
        <div className="position-relative">
          <div
            className="uk-grid-small uk-child-width-1-2 uk-child-width-1-3@m uk-child-width-1-4@l"
            data-uk-grid
          >
            <div className="uk-width-expand@m tax-rate">
              <p className="label">Taxable Income</p>
              <p className="value">
                {taxRate.taxableIncomeMin.toFixed(2)} -{" "}
                {taxRate.taxableIncomeMax.toFixed(2)}
              </p>
            </div>
            <div className="uk-width-1-4@m base-rate">
              <p className="label">Base Rate ($)</p>
              <p className="value">{taxRate.baseRate.toFixed(2)}</p>
            </div>
            <div className="uk-width-expand@m percentage">
              <p className="label">Percentage (%)</p>
              {taxRate.percentage ? (
                <p className="value">
                  {taxRate.percentage}% of the amount exceeding N$
                  {(taxRate.taxableIncomeMin - 1).toFixed(2)}
                </p>
              ) : (
                <p className="value">Not Taxable.</p>
              )}
            </div>

            <div className="uk-width-auto@m more">
              <button className="icon-btn" onClick={(e) => e.stopPropagation()}>
                <span data-uk-icon="more-vertical"></span>
              </button>
              <div
                data-uk-dropdown="mode: click; pos: bottom-right"
                className="dropdown"
              >
                <ul className="menu">
                  <li className="menu-item" onClick={onEdit}>
                    Edit
                  </li>

                  <div className="menu-item" onClick={onRemove}>
                    Delete
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default TaxRateItem;
