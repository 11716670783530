import { observer } from "mobx-react-lite";
import { useAppContext } from "../../../shared/functions/Context";
import showModalFromId from "../../../shared/functions/ModalShow";
import ILeaveProfile from "../../../shared/interfaces/ILeaveProfile";
import DIALOG_NAMES from "../../dialogs/Dialogs";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

interface LeaveProps {
    profile: ILeaveProfile;
}
const LeaveProfileBarGraph = observer((props: LeaveProps) => {
    const { api, store, ui } = useAppContext();

    const { profile } = props;
    const { id, userName, userRole, leavesTaken, departmentId } = profile;

    // Get leave settings for the user i.e. Common Leaves + Departmental Leaves
    const commonLeaves = store.leave.settings.allCommonLeaves;
    const departmentLeaves = store.leave.settings.allDeparmentLeaves(departmentId);
    const leaveTypes = [...commonLeaves, ...departmentLeaves];

    const leaveAnalytics = leaveTypes.map((leaveType, index) => {
        return {
            key: index,
            id: leaveType.id,
            type: leaveType.type,
            days: leaveType.days,
            taken: leavesTaken.find((took) => took.type === leaveType.type)?.days || 0,
        };
    });


    const onUpdate = () => {
        const daysTaken = leaveTypes.map((leaveType) => {
            return {
                id: leaveType.id,
                type: leaveType.type,
                days:
                    leavesTaken.find((took) => took.type === leaveType.type)?.days || 0,
            };
        });

        const $profile: ILeaveProfile = {
            ...profile,
            leavesTaken: daysTaken,
        };
        store.leave.profiles.select($profile);

        // console.log("Profile => ", $profile);

        showModalFromId(DIALOG_NAMES.LEAVE.LEAVE_PROFILE_DIALOG);
    };

    const onRemove = async () => {
        if (!window.confirm("Delete leave profile?")) return;

        try {
            await api.leave.profiles.delete(id);
            store.leave.profiles.remove(id);
            ui.snackbar.load({
                id: Date.now(),
                message: "Success! Profile removed.",
                type: "success",
            });
        } catch (error) {
            ui.snackbar.load({
                id: Date.now(),
                message: "Error! Failed to remove profile.",
                type: "danger",
            });
        }
    };

    const options = {
        elements: {
            bar: {
                borderWidth: 1,
            },
        },
        plugins: {
            legend: {
                position: 'top' as const,
            },
        },

    };

    const data2 = {
        labels: leaveAnalytics.map((leave) => leave.type),
        datasets: [
            {
                label: 'Taken',
                fill: false,
                lineTension: 0.0,
                backgroundColor: 'red',
                pointHitRadius: 20,
                data: leaveAnalytics.map((leave) => leave.taken),
            },
            {
                label: 'Remaining',
                fill: false,
                lineTension: 0.0,
                backgroundColor: 'rgb(41, 33, 116,0.5)',
                borderColor: 'rgb(41, 33, 116,0.5)',
                pointHitRadius: 20,
                data: leaveAnalytics.map((leave) => (leave.days - leave.taken))
            }
        ]
    };

    return (
        <div className="leave-profile-card uk-card uk-card-small">
            <div className="user">
                <div>
                    <h6 className="name">{userName}</h6>
                    <p className="role">{userRole}</p>
                </div>
                <div className="more uk-inline">
                    <button className="uk-button uk-button-default" type="button">
                        <span data-uk-icon="more-vertical"></span>
                    </button>
                    <div className="dropdown"
                        data-uk-dropdown="mode: click; pos: bottom-right">
                        <ul>
                            <li onClick={onUpdate}>
                                <span>Update</span>
                                <span className="icon" data-uk-icon="pencil"></span>
                            </li>
                            <li onClick={onRemove}>
                                <span>Remove</span>
                                <span className="icon" data-uk-icon="trash"></span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="uk-card-body">
                <div className="graph-comp">
                    <div className="wrapper">
                        <Bar data={data2} options={options} />
                    </div>
                </div>
            </div>
        </div>
    );
});

export default LeaveProfileBarGraph;