import { useCallback, useEffect, useState } from "react";
import { useAppContext } from "../../../shared/functions/Context";
import ILeaveProfile, {
  defaultLeaveProfile,
} from "../../../shared/interfaces/ILeaveProfile";

const useProfile = (): [ILeaveProfile | null, boolean] => {
  const { api, store } = useAppContext();

  const [profile, setProfile] = useState<ILeaveProfile | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const user = store.user.meJson;

  const loadProfile = useCallback(async () => {
    setLoading(true);

    if (!user) return;
    // Profile id - not the best.
    const profileId = `${user.uid}_${new Date(Date.now()).getFullYear()}`;
    const $profile = await api.leave.profiles.getProfile(profileId);

    if ($profile) setProfile($profile);
    else
      setProfile({
        ...defaultLeaveProfile,
        userId: user.uid,
        userName: `${user.firstName} ${user.lastName}`,
        departmentId: user.departmentId,
        id: profileId,
      });

    setLoading(false);
  }, [api.leave.profiles, user]);

  useEffect(() => {
    loadProfile();
  }, [loadProfile]);

  return [profile, loading];
};

export default useProfile;
