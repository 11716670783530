import { observer } from "mobx-react-lite";
import { FormEvent, useEffect, useState } from "react";
import { useAppContext } from "../../../shared/functions/Context";
import { hideModalFromId } from "../../../shared/functions/ModalShow";
import DIALOG_NAMES from "../Dialogs";
import { IOveride } from "../../../shared/models/InventoryOveride";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../shared/database/FirebaseConfig";

const OverideDialog = observer(() => {
    const { api, store, ui } = useAppContext();
    const [loading, setLoading] = useState(false);



    const defaultOveride: IOveride = {
        id: "",
        code: "",
        manager: ""
    };


    const [overide, setOveride] = useState<IOveride>({
        ...defaultOveride,
    });

    const resetOveride = () => {
        setOveride({ ...defaultOveride });
    };



    const onSave = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);

        // Update API
        try {
            if (store.inventory.overide.selected) {
                const supp = await api.inventory.overide.update(overide);
                if (supp) await store.inventory.overide.load([supp]);
                ui.snackbar.load({
                    id: Date.now(),
                    message: "overide updated!",
                    type: "success",
                });
            } else {
                await api.inventory.overide.create(overide);
                // if (supp) await store.inventory.overide.load([supp]);
                ui.snackbar.load({
                    id: Date.now(),
                    message: "overide created!",
                    type: "success",
                });
            }
            resetOveride();
            getManager();
        } catch (error) {
            ui.snackbar.load({
                id: Date.now(),
                message: "Error! Failed to update overide.",
                type: "danger",
            });
        }

        store.inventory.overide.clearSelected();
        setLoading(false);

        hideModalFromId(DIALOG_NAMES.OVERIDE.CREATE_OVERIDE_DIALOG);
    };

    const [manager, setManager] = useState<string[]>([]);

    const getManager = async () => {
        const colRef = collection(db, "InventoryOveride");
        const docsSnap = await getDocs(colRef);
        const list: string[] = [];
        docsSnap.forEach((doc) => {
            const code = doc.data().manager;
            list.push(code);
        });
        console.log(list);
        setManager(list);
    };

    useEffect(() => {
        getManager();
    }, []);








    useEffect(() => {
        if (store.inventory.overide.selected) setOveride(store.inventory.overide.selected);
        else setOveride({ ...defaultOveride });

        return () => { };
    }, [store.inventory.overide.selected]);

    useEffect(() => {
        const getUser = async () => {
            await api.auth.loadAll();
        }
        getUser();

    }, [])



    return (
        <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button
                className="uk-modal-close-default"
                type="button"
                data-uk-close
            ></button>

            <h3 className="uk-modal-title">Add Override Code</h3>

            <div className="dialog-content uk-position-relative">
                <div className="reponse-form">
                    <form className="uk-form-stacked" onSubmit={onSave}>
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-stacked-text">
                                Code
                            </label>
                            <div className="uk-form-controls">
                                <input
                                    className="uk-input uk-form-small"
                                    type="text"
                                    // placeholder="overide name"
                                    value={overide.code}
                                    onChange={(e) =>
                                        setOveride({ ...overide, code: e.target.value })
                                    }
                                    required
                                />
                            </div>
                        </div>
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-stacked-text">
                                Manager
                            </label>
                            <div className="uk-form-controls">
                                <select
                                    className="uk-select uk-form-small"
                                    value={overide.manager}
                                    onChange={(e) => setOveride({ ...overide, manager: e.target.value })}
                                    required
                                >
                                    <option value="">Select a Manager</option>
                                    {store.user.all
                                        .filter((user) => user.role.includes('GM'))
                                        .filter((user) => !manager.includes(user.email))
                                        .map((user) => (
                                            <option key={user.uid} value={user.email}>{user.email}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        {/* Dialog Footer */}
                        <div className="footer uk-margin">
                            <button className="uk-button secondary uk-modal-close">
                                Cancel
                            </button>
                            <button className="uk-button primary" type="submit">
                                Save
                                {loading && <div data-uk-spinner="ratio: .5"></div>}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
});

export default OverideDialog;
