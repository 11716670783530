import { FormEvent, useEffect, useState } from "react";
import { useAppContext } from "../../../shared/functions/Context";
import { hideModalFromId } from "../../../shared/functions/ModalShow";
import DIALOG_NAMES from "../Dialogs";
import { observer } from "mobx-react-lite";
import ProductForm from "./ProductViewCard";
import { IInventoryProduct, defaultInventoryProduct } from "../../../shared/models/InventoryProduct";

const DialogPayroll = observer(() => {
  const { api, store} = useAppContext();
  const [product, setProducts] = useState<IInventoryProduct>({ ...defaultInventoryProduct });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (store.inventory.products.selected) setProducts(store.inventory.products.selected);
    else setProducts({ ...defaultInventoryProduct });
    return () => {};
  }, [store.inventory.products.selected]);

  return (
    <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-width-2-3">
      <button className="uk-modal-close-default" type="button" data-uk-close />
      <div className="dialog-content uk-position-relative product-dialog">
        <ProductForm
          product={product}
          loading={loading}
        />
      </div>
    </div>
  );
});

export default DialogPayroll;
