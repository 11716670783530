import { observer } from "mobx-react-lite";
import Modal from "../../../shared/components/Modal"
import { useAppContext } from "../../../shared/functions/Context";
import showModalFromId from "../../../shared/functions/ModalShow";
import DIALOG_NAMES from "../../dialogs/Dialogs"
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { IPurchaseOrder, IPurchaseOrderItems, defaultPurchaseOrder } from "../../../shared/models/InventoryPurchaseOrder";
import { addDoc, collection, doc, getDoc, getDocs, setDoc, updateDoc } from "firebase/firestore";
import { db, storage } from "../../../shared/database/FirebaseConfig";
import Loading from "../../../shared/components/Loading";
import bg1 from "./assets/order.svg"
import supplier from "./assets/supplier.svg"
import { useNavigate } from "react-router-dom";
import { getDownloadURL, getStorage, ref, uploadBytes, uploadBytesResumable } from "firebase/storage";
import Supplier, { ISupplier } from "../../../shared/models/InventorySupplier";





export const PurchaseOrder = observer(() => {
    const { store, api } = useAppContext();
    const [addedQty, setAddedQty] = useState("")
    const currentUser = store.user.me;
    const [loading, setLoading] = useState(false)
    const [orderNumber, setOrderNumber] = useState(0)
    const [supplierId, setSupplierId] = useState("");
    const [orderType, setOrderType] = useState("");
    const [createPurchase, setCreatePurchase] = useState<IPurchaseOrderItems[]>([]);
    const [productName, setProductName] = useState('');
    const [productQuantity, setProductQuantity] = useState(0);
    const [productDescription, setProductDescription] = useState('');
    const [addedQtyList, setAddedQtyList] = useState([]); // State variable for quantity values
    const [purchaseOrder, setPurchaseOrder] = useState<IPurchaseOrder>({
        ...defaultPurchaseOrder,
    });
    const [purchaseO, setPurchaseO] = useState<IPurchaseOrder[]>([]);
    const [purchaseId, setPurchaseId] = useState("")
    const [viewedPurchseOrder, setViewedPurchasedOrder] = useState<any>([])
    const [viewLoading, setViewLoading] = useState(false)
    const [newStatus, setNewStatus] = useState("");
    const [statusLoader, setStatusLoader] = useState(false)
    const [submitedUser, setSumitedUser] = useState<any>([])
    const [submitedSupplier, setSumitedSupplier] = useState<any>([])
    const storage = getStorage();
    const [progress, setProgress] = useState(0)
    const [selectedStatus, setSelectedStatus] = useState('');
    const [orderSearch, setOrderSearch] = useState("")
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [supplierPrice, setSupplierPrice] = useState(0)
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [log, setLog] = useState<any[]>([])
    const [loadLogData, setLogDataLoad] = useState(false);
    const [totalNetPrice, setTotalNetPrice] = useState(0);
    const [supplierQuery, setSupplierQuery] = useState("");



    useEffect(() => {
        const totalNetPrice = viewedPurchseOrder?.orderItems?.reduce((accumulator: any, pur: any) => {
            const productNetPrice = pur.unitItemPrice * pur.itemQty;
            return accumulator + productNetPrice;
        }, 0);
        setTotalNetPrice(totalNetPrice)
    }, [viewedPurchseOrder?.orderItems])











    useEffect(() => {
        const getData = async () => {
            await api.inventory.products.getAll();
            await api.inventory.suppliers.getAll();
        }
        getData();
    }, [api.inventory.products, api.inventory.suppliers])




    const onIdChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setPurchaseOrder({ ...purchaseOrder, supplierName: e.target.value });
        setSupplierId(e.target.value);
    }



    const handleAddProduct = () => {
        if (productName.trim() === '' || productQuantity === 0 || productDescription.trim() === '') {
            alert("please fill in the form")
            return;
        }
        const newProduct: IPurchaseOrderItems = {
            itemId: 0,
            itemName: productName,
            itemQty: productQuantity,
            itemDescription: productDescription,
            itemUnitPrice: 0,
            discountPercentage: 0,
        };

        setCreatePurchase((prevPurchase) => [...prevPurchase, newProduct]);
        setProductName("");
        setProductQuantity(0);
        setProductDescription("");
    };

    const handleRemoveProduct = (index: number) => {
        setCreatePurchase((prevPurchase) => {
            const updatedPurchase = [...prevPurchase];
            updatedPurchase.splice(index, 1);
            return updatedPurchase;
        });
    };

    const updateAddedQty = (index: any, value: any) => {
        const newList: any = [...addedQtyList];
        newList[index] = value;
        setAddedQtyList(newList);
    };



    useEffect(() => {
        function generateRandomNumber() {
            const randomNumber = Math.floor(Math.random() * 900000000) + 100000000;
            setOrderNumber(randomNumber);
        }
        generateRandomNumber();
    }, []);



    const createPurchaseOrder = async (e: any) => {
        e.preventDefault();
        const PurchaseOrderData = {
            purchaseOrderId: "",
            orderNumber: orderNumber,
            supplierId: supplierId,
            submiitedBy: currentUser?.asJson.uid,
            total: 0,
            status: "Pending",
            orderItems: createPurchase,
            attachment: [],
            invoice: ""
        };
        setLoading(true);
        const docRef = doc(collection(db, "InventoryPurchaseOrder"));
        PurchaseOrderData.purchaseOrderId = docRef.id;
        await setDoc(docRef, PurchaseOrderData, { merge: true });
        setCreatePurchase([]);
        getPurchase();
        setLoading(false);
        setAddedQty("");
        const randomNumber = Math.floor(Math.random() * 900000000) + 100000000;
        setOrderNumber(randomNumber);
    }


    const getPurchase = async () => {
        setLoading(true);
        const colRef = collection(db, "InventoryPurchaseOrder");
        const docsSnap = await getDocs(colRef);
        const list: any = [];
        docsSnap.forEach((doc) => {
            list.push(doc.data());
        })
        setPurchaseO(list);
        setLoading(false);
    }


    const onView = (id: string) => {
        setPurchaseId(id)
        showModalFromId(DIALOG_NAMES.INVENTORY.PURCHASE_ORDER_VIEW);
    }



    useEffect(() => {
        const viewPurchasedOrder = async () => {
            if (purchaseId) {
                setViewLoading(true)
                const colRef = doc(db, "InventoryPurchaseOrder", purchaseId);
                const docSnap = await getDoc(colRef);
                if (docSnap.exists()) {
                    setViewedPurchasedOrder(docSnap.data());
                }
            }
            setViewLoading(false)
        }
        viewPurchasedOrder();
        getPurchase();
    }, [purchaseId])




    const statusUpdate = async () => {
        if (window.confirm(newStatus)) {
            try {
                setStatusLoader(true);
                const docRef = doc(db, "InventoryPurchaseOrder", purchaseId);

                // Update the status in the Purchase Order document
                await updateDoc(docRef, { status: newStatus });
                if (newStatus === "Received") {
                    await updateDoc(docRef, { total: totalNetPrice });
                }

                // Create a log entry in the Logs subcollection

                const actionDateTime = new Date().toISOString();
                const formattedDate = new Date(actionDateTime).toLocaleDateString();
                const formattedTime = new Date(actionDateTime).toLocaleTimeString();

                const logData = {
                    person: currentUser?.asJson.email,
                    orderId: purchaseId,
                    status: newStatus,
                    actionDateTime: {
                        date: formattedDate,
                        time: formattedTime,
                    },
                };
                // Get a reference to the Logs subcollection for the specific purchase order
                const logsCollectionRef = collection(docRef, "Logs");

                // Add the log entry to the Logs subcollection
                await addDoc(logsCollectionRef, logData);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setViewedPurchasedOrder(docSnap.data());
                }
            } catch (error) {
                console.log("Error creating", error);
            }
            getPurchase();
            setStatusLoader(false);
        }
    };


    const getLogsData = async () => {
        try {
            setLogDataLoad(true)
            const docRef = collection(db, "InventoryPurchaseOrder", purchaseId, "Logs");
            const querySnapshot = await getDocs(docRef);
            const logsData = querySnapshot.docs.map((doc) => doc.data());
            setLog(logsData); // Set the log data in the state
            setLogDataLoad(false)
        } catch (error) {
            console.log("Error retrieving logs data", error);
            setLog([]); // Set an empty array in case of an error
        }
    };






    useEffect(() => {
        const getUser = async () => {
            if (viewedPurchseOrder.submiitedBy && store.user.all.length > 0) {
                const user = store.user.all.find((user) => user.uid === viewedPurchseOrder.submiitedBy);
                if (user) {
                    const userName = user.email;
                    setSumitedUser([userName]);
                }
            }
        };
        const getUSupplier = async () => {
            if (viewedPurchseOrder.submiitedBy && store.user.all.length > 0) {
                const user = store.inventory.supplier.all.find((supplier) => supplier.asJson.id === viewedPurchseOrder.supplierId);
                if (user) {
                    const userName = user.asJson.supplierName;
                    setSumitedSupplier([userName]);
                }
            }
        };
        getUser();
        getUSupplier();
    }, [store.inventory.supplier.all, store.user.all, viewedPurchseOrder.submiitedBy, viewedPurchseOrder.supplierId]);



    const handleFilterByStatus = (status: string) => {
        setSelectedStatus(status);
    };


    const pos = store.inventory.purchaseOrder.all;

    const filteredPurchaseO = selectedStatus
        ? purchaseO.filter(
            (purchase) =>
                purchase.status === selectedStatus &&
                purchase.orderNumber &&
                purchase.orderNumber.toString().includes(orderSearch)
        )
        : purchaseO.filter((purchase) =>
            purchase.orderNumber &&
            purchase.orderNumber.toString().includes(orderSearch)
        );


    const suppliersFiltered = useMemo(() => {
        const sups = store.inventory.supplier.all;

        const suppliersWithPurchaseOrders = sups.filter((sup) => {
            return purchaseO.some((po) => po.supplierId === sup.asJson.id);
        });

        return suppliersWithPurchaseOrders;
    }, [purchaseO, store.inventory.supplier.all]);

    // Handler for order number search input
    const handleOrderSearch = (event: any) => {
        setOrderSearch(event.target.value);
    };


    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file && file.type === 'application/pdf') {
            setSelectedFile(file);
        } else {
            setSelectedFile(null);
            console.log('Invalid file type. Please select a PDF file.');
        }
    };



    const [uploadLoader, setUploadLoader] = useState(false);

    const handleUpload = async () => {
        if (selectedFile) {
            try {
                setUploadLoader(true)
                const storageRef = ref(storage, selectedFile.name);
                const uploadTask = uploadBytesResumable(storageRef, selectedFile);
                uploadTask.on('state_changed',
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log(`Upload progress: ${progress}%`);
                        setProgress(progress)
                    },
                    (error) => {
                        console.log('Error uploading file:', error);
                    },
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref)
                            .then((fileUrl) => {
                                const docId = purchaseId; // Replace with the actual document ID
                                const docRef = doc(db, 'InventoryPurchaseOrder', docId);
                                return updateDoc(docRef, { invoice: fileUrl });
                            })
                            .then(() => {
                                console.log('Document updated successfully.');
                                alert('Uploaded');
                                const viewPurchasedOrder = async () => {
                                    if (purchaseId) {
                                        setViewLoading(true)
                                        const colRef = doc(db, "InventoryPurchaseOrder", purchaseId);
                                        const docSnap = await getDoc(colRef);
                                        if (docSnap.exists()) {
                                            setViewedPurchasedOrder(docSnap.data());
                                        }
                                    }
                                    setViewLoading(false)
                                }
                                viewPurchasedOrder();
                            })
                            .catch((error) => {
                                console.log('Error updating document:', error);
                            });
                    }
                );
            } catch (error) {
                console.log('Error uploading file:', error);
            }
        } else {
            console.log('No file selected. Please choose a PDF file.');
        }
        setUploadLoader(false)
    };




    const updateDocument = async (itemIndex: number, newPrice: number) => {
        try {
            setLoadingUpdate(true);
            // Step 1: Retrieve the document
            const docRef = doc(db, "InventoryPurchaseOrder", purchaseId);
            const documentSnapshot = await getDoc(docRef);

            if (documentSnapshot.exists()) {
                const documentData = documentSnapshot.data();

                // Step 2: Update the 'unitItemPrice' within the array
                const updatedOrderItems = [...documentData.orderItems];
                updatedOrderItems[itemIndex].unitItemPrice = newPrice;

                // Step 3: Save the updated document
                await updateDoc(docRef, { orderItems: updatedOrderItems });

                const viewPurchasedOrder = async () => {
                    if (purchaseId) {
                        setViewLoading(true)
                        const colRef = doc(db, "InventoryPurchaseOrder", purchaseId);
                        const docSnap = await getDoc(colRef);
                        if (docSnap.exists()) {
                            setViewedPurchasedOrder(docSnap.data());
                        }
                    }
                    setViewLoading(false)
                }
                viewPurchasedOrder();
                console.log("Document updated successfully!");
            } else {
                console.error("Document not found!");
            }
        } catch (error) {
            console.error("Error updating document:", error);
        }
        setLoadingUpdate(false);
    };

    return (
        <div className="uk-section leave-analytics-page purchase-order">
            <div className="uk-container uk-container-large">
                <div className="section-toolbar uk-margin">
                    <h4 className="section-heading uk-heading">PURCHASE ORDERS</h4>
                    {/* <div className="controls">
                        <div className="uk-inline">
                            <button className="uk-button primary" type="button" data-uk-toggle="target: #offcanvas-flip">
                                Audit Trail
                            </button>
                        </div>
                    </div> */}
                </div>
                <div className="uk-child-width-1-1@m uk-grid-small uk-grid-match" data-uk-grid>
                    <div>
                        <div style={{ backgroundPosition: "right", backgroundSize: "15%", backgroundRepeat: "no-repeat" }} className="uk-card uk-card-default uk-card-body">
                            <div className="uk-grid-small uk-child-width-1-1@m" data-uk-grid>
                                {createPurchase.map((prod) => prod).length === 0 ?
                                    <>
                                        <div className="uk-width-1-2@m">
                                            <label className="uk-form-label" htmlFor="form-stacked-text">
                                                Supplier
                                            </label>
                                            <div className="uk-form-controls">
                                                <select name="" id="" className="uk-input"
                                                    value={purchaseOrder.supplierName}
                                                    onChange={onIdChange}
                                                    style={{ background: "transparent" }}
                                                >
                                                    <option value="">Select Supplier</option>
                                                    {store.inventory.supplier.all.map((suppliers) => (
                                                        <option value={suppliers.asJson.id} key={suppliers.asJson.id}>{suppliers.asJson.supplierName}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="uk-width-1-2@m">
                                            <label className="uk-form-label" htmlFor="form-stacked-text">
                                                Supplier
                                            </label>
                                            <div className="uk-form-controls">
                                                <select disabled name="" id="" className="uk-input"
                                                    value={purchaseOrder.supplierName}
                                                    onChange={onIdChange}
                                                    style={{ background: "transparent" }}
                                                >
                                                    <option value="">Select Supplier</option>
                                                    {store.inventory.supplier.all.map((suppliers) => (
                                                        <option value={suppliers.asJson.id} key={suppliers.asJson.id}>{suppliers.asJson.supplierName}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </>
                                }


                                <div className="uk-width-1-2@m">
                                    <label className="uk-form-label" htmlFor="form-stacked-text">
                                        Order
                                    </label>
                                    <div className="uk-form-controls">
                                        <select name="" id="" className="uk-input"
                                            onChange={(e: any) => { setOrderType(e.target.value) }}
                                            style={{ background: "transparent" }}
                                        >
                                            <option value="">Select Order</option>
                                            <option value="old">Re order</option>
                                            <option value="new">New</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {orderType === "old" && (
                                <table className="uk-table uk-table-small uk-table-divider">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Description</th>
                                            <th>Current quantity</th>
                                            <th>Adjust quantity</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {store.inventory.products.all.filter((prod) => prod.supplier?.asJson.id === supplierId).map((prod, index: any) => (
                                            <tr key={prod.asJson.id}>
                                                <td>{prod.asJson.productName}</td>
                                                <td>{prod.asJson.productDescription}</td>
                                                <td>10</td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        min={0}
                                                        max={1000}
                                                        className="uk-input"
                                                        style={{ height: "2rem", width: "6rem", background: "transparent" }}
                                                        onChange={(e: any) => updateAddedQty(index, e.target.value)}
                                                        value={addedQtyList[index] || ""} // Use the corresponding quantity value from the state
                                                    />
                                                </td>
                                                <td>
                                                    <button
                                                        className="uk-button primary"
                                                        type="button"
                                                        onClick={() => {



                                                            const quantity = parseInt(addedQtyList[index]);

                                                            // Check if quantity is empty, zero, or not entered
                                                            if (!quantity || isNaN(quantity) || quantity === 0) {
                                                                alert('Please enter a valid quantity.');
                                                                return; // Stop further execution
                                                            }



                                                            const newItem: IPurchaseOrderItems = {
                                                                itemId: prod.asJson.id,
                                                                itemName: prod.asJson.productName,
                                                                itemDescription: prod.asJson.productDescription,
                                                                itemQty: parseInt(addedQtyList[index]),
                                                                itemUnitPrice: 0,
                                                                discountPercentage: 0
                                                            };


                                                            const existingProduct = createPurchase.find((item) => item.itemId === newItem.itemId);
                                                            if (existingProduct) {
                                                                alert('Product already exists in the list!');
                                                                updateAddedQty(index, ""); // Clear the quantity value for the current product
                                                                return; // Stop further execution
                                                            }

                                                            setCreatePurchase((prevItems) => [...prevItems, newItem]);
                                                            updateAddedQty(index, ""); // Clear the quantity value for the current product
                                                        }}
                                                    >
                                                        Add Product
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                            {orderType === "new" && (
                                <div>
                                    <form action="">
                                        <div className="uk-grid-small uk-child-width-1-1@m" data-uk-grid>
                                            <br />
                                            <br />
                                            <h5>Add New Product to Purchase Order</h5>
                                            <div className="uk-width-1-2@m">
                                                <label className="uk-form-label" htmlFor="form-stacked-text">
                                                    Product Name
                                                </label>
                                                <div className="uk-form-controls">
                                                    <input
                                                        className="uk-input "
                                                        type="text"
                                                        value={productName}
                                                        onChange={(e) => setProductName(e.target.value)}
                                                        required
                                                        style={{ background: "transparent" }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="uk-width-1-2@m">
                                                <label className="uk-form-label" htmlFor="form-stacked-text">
                                                    Product Quantity
                                                </label>
                                                <div className="uk-form-controls">
                                                    <input
                                                        className="uk-input "
                                                        type="number"
                                                        min={0}
                                                        max={1000}
                                                        value={productQuantity}
                                                        onChange={(e) => setProductQuantity(parseInt(e.target.value))}
                                                        required
                                                        style={{ background: "transparent" }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="uk-width-1-1@m">
                                                <label className="uk-form-label" htmlFor="form-stacked-text">
                                                    Product Description
                                                </label>
                                                <div className="uk-form-controls">
                                                    <textarea
                                                        style={{ height: "6rem", background: "transparent" }}
                                                        className="uk-input uk-form-small"
                                                        value={productDescription}
                                                        onChange={(e) => setProductDescription(e.target.value)}
                                                        required

                                                    />
                                                </div>
                                            </div>
                                            <button className="uk-button primary" type="button" style={{ width: "15rem" }} onClick={handleAddProduct}>
                                                Add to Purchase Order
                                            </button>
                                        </div>
                                    </form>


                                </div>
                            )}
                        </div>
                    </div>
                    <div>
                        {createPurchase.map((pur) => pur).length === 0 ? <></>
                            :
                            <>
                                <div className="uk-card uk-card-default uk-card-body">
                                    <div className="section-toolbar uk-margin">
                                        <h4 className="section-heading uk-heading">Purchase Order</h4>
                                        <div className="controls">
                                            <div className="uk-inline">
                                                {createPurchase.map((purchase) => purchase).length > 0 &&
                                                    <button className="uk-button primary" type="button" onClick={createPurchaseOrder} >
                                                        {loading ? <>loading...</> : <>Create Order</>}
                                                    </button>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                    <table className="uk-table uk-table-small uk-table-divider">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Description</th>
                                                <th>Quantity</th>
                                                <th style={{ textAlign: "right" }}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {createPurchase.map((prod, index) => (
                                                <tr key={index}>
                                                    <td>{prod.itemName}</td>
                                                    <td>{prod.itemDescription}</td>
                                                    <td>{prod.itemQty}</td>
                                                    <td style={{ textAlign: "right" }}>
                                                        <button
                                                            className="uk-button danger"
                                                            type="button"
                                                            onClick={() => handleRemoveProduct(index)}
                                                        >
                                                            Remove
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                            {createPurchase.map((prod) => prod).length === 0 && <p style={{ color: "red" }}>No products</p>}
                                        </tbody>
                                    </table>
                                </div>
                            </>}

                    </div>
                    <div>
                        <div className="uk-card uk-card-default uk-card-body">
                            <div className="section-toolbar uk-margin">
                                <h4 className="section-heading uk-heading">Purchase Order list</h4>
                            </div>
                            <input type="text" className="uk-input muk-margin-left" placeholder="Search supplier" style={{ width: "25%" }} onChange={(e: any) => setSupplierQuery(e.target.value)} />
                            <br />
                            <br />
                            {loading ? <Loading />
                                :
                                <>
                                    {suppliersFiltered.filter((supplier) => supplier.asJson.supplierName.toLowerCase().includes(supplierQuery.toLowerCase())).map((supplier) => (
                                        <ul data-uk-accordion className="purchase-accordion">
                                            <li key={supplier.asJson.id}>
                                                <a className="uk-accordion-title" href="void(0)">
                                                    {supplier.asJson.supplierName}
                                                </a>

                                                <div className="uk-accordion-content">
                                                    <input
                                                        style={{ width: "20%" }}
                                                        type="text"
                                                        placeholder="Search by order number"
                                                        className="uk-input uk-margin"
                                                        value={orderSearch}
                                                        onChange={handleOrderSearch}
                                                    />
                                                    <br />
                                                    <div className="uk-inline uk-margin">
                                                        <button
                                                            className={`uk-button primary ${selectedStatus === '' ? 'selected' : ''}`}
                                                            type="button"
                                                            onClick={() => handleFilterByStatus('')}
                                                        >
                                                            All
                                                        </button>
                                                        <button
                                                            className={`uk-button primary uk-margin-left ${selectedStatus === 'Pending' ? 'selected' : ''}`}
                                                            type="button"
                                                            onClick={() => handleFilterByStatus('Pending')}
                                                        >
                                                            Pending
                                                        </button>
                                                        <button
                                                            className={`uk-button primary uk-margin-left ${selectedStatus === 'Hold' ? 'selected' : ''}`}
                                                            type="button"
                                                            onClick={() => handleFilterByStatus('Hold')}
                                                        >
                                                            Hold
                                                        </button>
                                                        <button
                                                            className={`uk-button primary uk-margin-left ${selectedStatus === 'Received' ? 'selected' : ''}`}
                                                            type="button"
                                                            onClick={() => handleFilterByStatus('Received')}
                                                        >
                                                            Received
                                                        </button>
                                                        <button
                                                            className={`uk-button primary uk-margin-left ${selectedStatus === 'Fulfilled' ? 'selected' : ''}`}
                                                            type="button"
                                                            onClick={() => handleFilterByStatus('Fulfilled')}
                                                        >
                                                            Fulfilled
                                                        </button>
                                                    </div>

                                                    <br />
                                                    <div>
                                                        <h4 style={{ fontWeight: "700", color: "grey" }}>{selectedStatus}</h4>
                                                    </div>
                                                    <br />
                                                    <div
                                                        className="uk-child-width-1-3@m uk-grid-small uk-grid-match"
                                                        data-uk-grid
                                                    >

                                                        {filteredPurchaseO
                                                            .filter((purchase) => purchase.supplierId === supplier.asJson.id) // Filter purchase orders by supplierId
                                                            .map((purchase: any) => (
                                                                <div>
                                                                    <div
                                                                        style={{
                                                                            backgroundImage: `url(${bg1})`,
                                                                            backgroundPosition: "right",
                                                                            backgroundSize: "20%",
                                                                            backgroundRepeat: "no-repeat",
                                                                        }}
                                                                        className="uk-card uk-card-default uk-card-body"
                                                                    >
                                                                        <h3
                                                                            className=""
                                                                            style={{ fontWeight: "800", color: "grey" }}
                                                                        >
                                                                            Order No. {purchase.orderNumber}
                                                                        </h3>
                                                                        <div className="uk-inline">
                                                                            <button
                                                                                className="uk-button primary"
                                                                                type="button"
                                                                                onClick={() => onView(purchase.purchaseOrderId)}
                                                                            >
                                                                                View Order
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}

                                                    </div>
                                                    {filteredPurchaseO
                                                        .filter((purchase) => purchase.supplierId === supplier.asJson.id)
                                                        .map((purchase) => purchase).length === 0 && <p style={{ color: "red" }}>No orders</p>
                                                    }
                                                </div>
                                            </li>
                                        </ul>
                                    ))}
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Modal modalId={DIALOG_NAMES.INVENTORY.PURCHASE_ORDER_VIEW}>
                <div className="uk-modal-dialog uk-modal-body margin-auto-vertical" style={{ width: "100%" }}>
                    <button className="uk-modal-close-default" type="button" data-uk-close />

                    {viewLoading ?
                        <Loading />
                        :
                        <div style={{ backgroundImage: `url(${supplier})`, backgroundPosition: "right", backgroundSize: "20%", backgroundRepeat: "no-repeat" }}>
                            <div className="uk-inline">
                                <button className="uk-button primary" type="button" data-uk-toggle="target: #offcanvas-flip" onClick={getLogsData}>
                                    Audit Trail
                                </button>
                            </div>
                            <br />
                            <br />
                            <br />
                            {viewedPurchseOrder.status !== "Fulfilled" && (
                                <>
                                    <label htmlFor="">Update Status</label><br />
                                    <select
                                        name=""
                                        id=""
                                        className="uk-input uk-form-small"
                                        style={{ width: "10%" }}
                                        value={newStatus}
                                        onChange={(e) => setNewStatus(e.target.value)}
                                    >
                                        <option value="">Select</option>

                                        {viewedPurchseOrder.invoice === "" ? (
                                            <>
                                                <option value="Pending">Pending</option>
                                                <option value="Hold">Hold</option>
                                            </>
                                        ) : (
                                            <>
                                                {viewedPurchseOrder.status === "Received" ? (
                                                    <>
                                                        <option value="Fulfilled">Fulfilled</option>
                                                    </>
                                                ) : (
                                                    <>
                                                        <option value="Received">Received</option>
                                                    </>
                                                )
                                                }
                                            </>
                                        )}
                                    </select>
                                    {newStatus !== "" &&
                                        <button className="uk-button primary uk-margin-left" type="button" onClick={statusUpdate}>Update Status</button>
                                    }
                                </>
                            )}
                            {viewedPurchseOrder.status === "Fulfilled" && <p style={{ color: "green", fontWeight: "600" }}>Purchase Order Completed Successfully</p>}


                            <br />
                            <br />
                            <div className="uk-child-width-1-1@m uk-grid-small uk-grid-match" data-uk-grid>
                                <div>
                                    <div className="uk-card uk-card uk-card-body" >
                                        <p className="" style={{ fontWeight: "600", color: "grey" }}>Order Number: {viewedPurchseOrder.orderNumber}</p>
                                        <p className="" style={{ fontWeight: "600", color: "grey" }}>TotalPrice: N$ {totalNetPrice?.toFixed(2)}</p>
                                        <p className="" style={{ fontWeight: "600", color: "grey" }} >Submitted by: {submitedUser}</p>
                                        <p className="" style={{ fontWeight: "600", color: "grey" }} >Supplier: {submitedSupplier}</p>
                                        {statusLoader ?
                                            <p>updating status....</p> :
                                            <>
                                                <p className="" style={{ fontWeight: "600", color: "grey" }}>Status: {viewedPurchseOrder.status}</p>
                                            </>
                                        }
                                        {viewedPurchseOrder.invoice === "" && (
                                            <>
                                                <div className="uk-margin" data-uk-margin>
                                                    <div uk-form-custom="target: true">
                                                        <input
                                                            type="file"
                                                            accept="application/pdf"
                                                            aria-label="Custom controls"
                                                            onChange={handleFileChange}
                                                        />
                                                        <input
                                                            className="uk-input uk-form-width-medium"
                                                            type="text"
                                                            placeholder="Select Invoice"
                                                            aria-label="Custom controls"
                                                            disabled
                                                        />
                                                    </div>
                                                    <button className="uk-button primary uk-margin-left uk-button-default" onClick={handleUpload}>
                                                        {uploadLoader ? <>Loading...</> : <>Upload</>}
                                                    </button>
                                                </div>
                                                <progress
                                                    style={{ width: "50%" }}
                                                    id="js-progressbar"
                                                    className="uk-progress"
                                                    value={progress}
                                                    max="100"
                                                ></progress>
                                            </>
                                        )}
                                        {viewedPurchseOrder.invoice !== "" && <a target="blank" href={viewedPurchseOrder.invoice}>view Invoice</a>}

                                    </div>
                                </div>
                            </div>
                            <div className="uk-card" style={{ marginTop: "4rem" }}>
                                <table className="uk-table uk-table-small uk-table-divider">
                                    <thead>
                                        <tr>
                                            <th>Product Name</th>
                                            <th>Product Description</th>
                                            <th>Product Quantity</th>
                                            {viewedPurchseOrder.status === "Received" || viewedPurchseOrder.status === "Fulfilled" ?
                                                (
                                                    <>

                                                    </>
                                                )
                                                :
                                                (
                                                    <>
                                                        {viewedPurchseOrder.invoice === "" ?
                                                            (
                                                                <>
                                                                    <th>Add Product Unit Price (Supplier)</th>
                                                                </>
                                                            ) :
                                                            (
                                                                <>   <th>Add Product Unit Price (Supplier)</th></>
                                                            )
                                                        }
                                                    </>
                                                )
                                            }

                                            <th>Product Unit Price (Supplier)</th>
                                            <th>Product Total Price (Supplier)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {viewedPurchseOrder?.orderItems?.map((pur: any, index: number) => (
                                            <tr key={index}>
                                                <td>{pur.itemName}</td>
                                                <td>{pur.itemDescription}</td>
                                                <td>{pur.itemQty}</td>

                                                {viewedPurchseOrder.status === "Received" || viewedPurchseOrder.status === "Fulfilled" ?
                                                    (
                                                        <>

                                                        </>
                                                    )
                                                    :
                                                    (
                                                        <>
                                                            {viewedPurchseOrder.invoice === "" ?
                                                                (
                                                                    <>
                                                                        <td>
                                                                            <div className="uk-inline">
                                                                                <input type="number" className="uk-input uk-form-small" onChange={(e: any) => setSupplierPrice(e.target.value)} placeholder="Add supplier price" style={{ width: "50%" }} />
                                                                                <button style={{ background: "grey" }} disabled className="uk-button primary uk-margin-left" type="button" onClick={() => updateDocument(index, supplierPrice)}>
                                                                                    {loadingUpdate ? <>Loading...</> : <>Update Price</>}
                                                                                </button>
                                                                            </div>


                                                                        </td>
                                                                    </>
                                                                ) :
                                                                (
                                                                    <>
                                                                        <td>
                                                                            <div className="uk-inline">
                                                                                <input type="number" className="uk-input uk-form-small" onChange={(e: any) => setSupplierPrice(e.target.value)} placeholder="Add supplier price" style={{ width: "50%" }} />
                                                                                <button className="uk-button primary uk-margin-left" type="button" onClick={() => updateDocument(index, supplierPrice)}>
                                                                                    {loadingUpdate ? <>Loading...</> : <>Update Price</>}
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                    </>
                                                                )
                                                            }
                                                        </>
                                                    )

                                                }

                                                <td>
                                                    N${(1 * pur.unitItemPrice)?.toFixed(2)}
                                                </td>
                                                <td>
                                                    N${(pur.itemQty * pur.unitItemPrice)?.toFixed(2)}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    }
                </div>
            </Modal>

            <div id="offcanvas-flip" data-uk-offcanvas="flip: true; overlay: true" data-bg-close={false}>
                <div className="uk-offcanvas-bar">
                    <button className="uk-offcanvas-close" type="button" data-uk-close onClick={() => onView(purchaseId)}></button>

                    <h3>LOGS</h3>
                    {loadLogData ? <p>Loading logs.....</p> :
                        <>
                            {log.map((log: any, Index: number) => (
                                <ul className="uk-list uk-list-divider uk-list-striped" key={Index}>
                                    <li > Status changed to <strong>{log.status}</strong>  by  <strong>{log.person}</strong>.
                                        <br />
                                        Date: {log.actionDateTime.date}
                                        <br />
                                        Time: {log.actionDateTime.time}
                                    </li>
                                </ul>
                            ))}
                            {log.map((log: any) => log).length === 0 && <p style={{ color: "red" }}>No logs</p>}
                        </>
                    }
                </div>
            </div>

        </div >
    )
})
