import Store from "../Store";
import AppStore from "../AppStore";
import {runInAction } from "mobx";
import { LeaveRequest } from "../../models/LeaveRequest";
import ILeaveRequest from "../../interfaces/ILeaveRequest";

export default class LeaveRequestStore extends Store<ILeaveRequest,LeaveRequest> {
  items = new Map<string, LeaveRequest>();

  constructor(store: AppStore) {
    super(store);
    this.store = store;
  }

  load(items: ILeaveRequest[] = []) {
    runInAction(() => {
      items.forEach((item) =>
        this.items.set(item.id, new LeaveRequest(this.store, item))
      );
    });
  }

  get allApproved() {
    const list = Array.from(this.items.values());
    return list.filter((item) => item.status === "APPROVED");
  }

  get allPending() {
    const list = Array.from(this.items.values());
    return list.filter((item) => item.status === "PENDING");
  }

  get allRejected() {
    const list = Array.from(this.items.values());
    return list.filter((item) => item.status === "REJECTED");
  }
}