import {
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore";
import IInvoice, { defaultInvoice } from "../interfaces/IInvoice";
import AppStore from "../stores/AppStore";
import AppApi from "./AppApi";

export default class InvoiceApi {
  collectionRef: CollectionReference;
  invoiceSubCollectionId: string;

  constructor(
    private api: AppApi,
    private store: AppStore,
    collectionRef: CollectionReference,
    invoiceSubCollectionId: string
  ) {
    this.collectionRef = collectionRef;
    this.invoiceSubCollectionId = invoiceSubCollectionId;
  }

  async getAll(customerId: string) {
    this.store.invoicing.invoice.removeAll();

    const collectionRef = collection(
      this.collectionRef,
      customerId,
      this.invoiceSubCollectionId
    );

    const q = query(collectionRef, orderBy("paymentDate", "asc"));
    const querySnapshot = await getDocs(q);

    const items = querySnapshot.docs.map((doc) => {
      const item = {
        ...defaultInvoice,
        id: doc.id,
        ...doc.data(),
      } as IInvoice;
      return item;
    });
    this.store.invoicing.invoice.load(items);
  }

  async getById(customerId: string, employeeId: string) {
    const collectionRef = collection(
      this.collectionRef,
      customerId,
      this.invoiceSubCollectionId
    );
    const unsubscribe = onSnapshot(doc(collectionRef, employeeId), (doc) => {
      const item: IInvoice = {
        ...defaultInvoice,
        id: doc.id,
        ...doc.data(),
      } as IInvoice;
      this.store.invoicing.invoice.load([item]);
    });
    return unsubscribe;
  }

  async create(customerId: string, data: IInvoice) {
    const collectionRef = collection(
      this.collectionRef,
      customerId,
      this.invoiceSubCollectionId
    );
    const docRef = doc(collectionRef);
    data.id = docRef.id;
    await setDoc(docRef, data, { merge: true });
    return data;
  }

  async update(customerId: string, data: IInvoice) {
    const collectionRef = collection(
      this.collectionRef,
      customerId,
      this.invoiceSubCollectionId
    );
    const docRef = doc(collectionRef, data.id);
    await setDoc(docRef, data, { merge: true });
    return data;
  }

  async deleteAll(customerId: string) {
    const collectionRef = collection(
      this.collectionRef,
      customerId,
      this.invoiceSubCollectionId
    );
    const q = query(collectionRef);
    const querySnapshot = await getDocs(q);

    await Promise.all(
      querySnapshot.docs.map(async (doc) => {
        await deleteDoc(doc.ref);
      })
    );
  }

  async delete(customerId: string, invoiceId: string) {
    const collectionRef = collection(
      this.collectionRef,
      customerId,
      this.invoiceSubCollectionId
    );

    const docRef = doc(collectionRef, invoiceId);
    await deleteDoc(docRef);
    this.store.invoicing.invoice.remove(invoiceId);
  }

  async getByUserId(customerId: string, userId: string) {
    const collectionRef = collection(
      this.collectionRef,
      customerId,
      this.invoiceSubCollectionId
    );
    const unsubscribe = onSnapshot(doc(collectionRef, userId), (doc) => {
      const item: IInvoice = {
        ...defaultInvoice,
        id: doc.id,
        ...doc.data(),
      } as IInvoice;
      this.store.invoicing.invoice.load([item]);
    });
    return unsubscribe;
  }

  async getByParamsLink(customerId: string, invoiceId: string) {
    const collectionRef = collection(
      this.collectionRef,
      customerId,
      this.invoiceSubCollectionId
    );
    const docSnap = await getDoc(doc(collectionRef, invoiceId));
    if (docSnap.exists()) {
      const $doc = { ...docSnap.data(), id: docSnap.id } as IInvoice;
      await this.store.invoicing.invoice.load([$doc]);
      return $doc;
    } else return undefined;
  }

  // async getByParamsLink(id: string) {
  //   const docSnap = await getDoc(doc(this.collectionRef, id));
  //   if (docSnap.exists()) {
  //     const $doc = { ...docSnap.data(), id: docSnap.id } as IInvoice;
  //     await this.store.invoicing.invoice.load([$doc]);
  //     return $doc;
  //   } else return undefined;
  // }

  // Doc id
  get getDocId() {
    return doc(this.collectionRef).id;
  }
}
