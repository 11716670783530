import { makeAutoObservable } from "mobx";
import AppStore from "../stores/AppStore";
import ITaxRate from "../interfaces/ITaxRate";

export class TaxRate implements ITaxRate {
  id: string;
  taxableIncomeMin: number;
  taxableIncomeMax: number;
  baseRate: number;
  percentage: number;

  constructor(private store: AppStore, taxRate: ITaxRate) {
    makeAutoObservable(this);
    this.id = taxRate.id;
    this.baseRate = taxRate.baseRate;
    this.percentage = taxRate.percentage;
    this.taxableIncomeMin = taxRate.taxableIncomeMin;
    this.taxableIncomeMax = taxRate.taxableIncomeMax;
  }

  get asJson(): ITaxRate {
    return {
      id: this.id,
      taxableIncomeMin: this.taxableIncomeMin,
      taxableIncomeMax: this.taxableIncomeMax,
      baseRate: this.baseRate,
      percentage: this.percentage,
    };
  }
}
