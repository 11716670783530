import Store from "../Store";
import AppStore from "../AppStore";
import { runInAction } from "mobx";
import { Invoice } from "../../models/Invoice";
import IInvoice from "../../interfaces/IInvoice";

export default class InvoiceStore extends Store<IInvoice, Invoice> {
  items = new Map<string, Invoice>();

  constructor(store: AppStore) {
    super(store);
    this.store = store;
  }
  load(items: IInvoice[] = []) {
    runInAction(() => {
      items.forEach((item) =>
        this.items.set(item.id, new Invoice(this.store, item))
      );
    });
  }

  get sortedByPaymentDate() {
    return Array.from(this.items.values()).sort((a, b) =>
      a.paymentDate.localeCompare(b.paymentDate)
    );
  }

  getById(id: string) {
    return this.items.get(id);
  }

  get allUnpaid() {
    const list = Array.from(this.items.values());
    return list.filter((item) => item.invoiceStatus === "Unpaid");
  }

  get allPartially() {
    const list = Array.from(this.items.values());
    return list.filter((item) => item.invoiceStatus === "Partially");
  }

  get allPaid() {
    const list = Array.from(this.items.values());
    return list.filter((item) => item.invoiceStatus === "Paid");
  }
}
