import { IPayrollStatus } from "../../../../shared/interfaces/IPayroll";

interface ITabProps {
  activeStage: "draft" | "approval" | "run";
  status: IPayrollStatus;
  onDraft: () => void;
  onApproval: () => void;
  onRun: () => void;
}
export const PayrollTabs = (props: ITabProps) => {
  const { status, activeStage, onDraft, onApproval, onRun } = props;

  const draftCss = activeStage === "draft" ? "active" : "complete";
  const approvalCss = activeStage === "approval" ? "active" : activeStage === "run" ? "complete" : "";
  const runCss = activeStage === "run" ? "active" : "";

  return (
    <div className="uk-section">
      <div className="uk-container uk-container-large">
        <div className="payroll-steps uk-grid-collapse uk-child-width-expand@s uk-text-center"
          data-uk-grid
        >
          <div className="stage" onClick={onDraft}>
            <div>
              <h6 className="title">STEP 1</h6>
            </div>
            <div className={`step ${draftCss}`}>
              <div>
                {status === "draft" && (
                  <div className="step-button">
                    <span
                      className="uk-margin-small-right icon close"
                      data-uk-icon="close"
                    />
                    In progress
                  </div>
                )}
                {status !== "draft" && (
                  <div className="step-button">
                    <span
                      className="uk-margin-small-right icon check"
                      data-uk-icon="check"
                    />
                    Completed
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="stage" onClick={onApproval}>
            <div>
              <h6 className="title">STEP 2</h6>
            </div>
            <div className={`step ${approvalCss}`}>
              <div>
                {status === "in-progress" && (
                  <div className="step-button">
                    <span
                      className="uk-margin-small-right icon check"
                      data-uk-icon="check"
                    />
                    In progress
                  </div>
                )}
                {status === "completed" && (
                  <div className="step-button">
                    <span
                      className="uk-margin-small-right icon check"
                      data-uk-icon="check"
                    />
                    Completed
                  </div>
                )}
                {status === "draft" && (
                  <div className="step-button">
                    <span
                      className="uk-margin-small-right icon close"
                      data-uk-icon="close"
                    />
                    Pending
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="stage" onClick={onRun}>
            <div>
              <h6 className="title">STEP 3</h6>
            </div>
            <div className={`step ${runCss}`}>
              <div className="step-button">Run payroll</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
