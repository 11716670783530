import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        title: {
            display: true,
            text: 'Monthly Revenue',
        },
    },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const data = {
    labels,
    datasets: [
        {
            fill: true,
            label: 'Revenue',
            data: [30000, 40000, 40000, 50000, 70000, 30000, 60000, 50000, 105000, 400000, 410000, 140000],
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'grey',
        },
    ],
};

export const Revenue = () => {
    return <Bar options={options} data={data} />
}
