import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../shared/functions/Context";
import { PayrollFolder } from "../../../shared/models/PayrollFolder";
import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import useAutoSave from "../../../shared/hooks/useAutoSave";

interface IFolderEditProps {
  folderName: number;
  loading: boolean;
  unsavedChanges: boolean;
  handleDelete: any;
  handleUpdate: (value: number) => void;
}
const PortfolioSetting: FC<IFolderEditProps> = observer(
  ({ folderName, handleDelete, loading, unsavedChanges, handleUpdate }) => {
    return (
      <div className="folder-edit" uk-dropdown="mode: click">
        <div>
          <div className="dropdown-input">
            <input
              type="text"
              name="name"
              id="name"
              placeholder="Folder name"
              defaultValue={folderName}
              onChange={(e) => handleUpdate(Number(e.target.value))}
            />
            {unsavedChanges && (
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  display: "grid",
                  placeItems: "center",
                  backgroundColor: "#00000015",
                }}
              >
                <div data-uk-spinner="ratio: 1.5"></div>
              </div>
            )}
          </div>
        </div>
        <div className="actions uk-margin-small">
          <div className="folder-actions" onClick={handleDelete}>
            Delete
          </div>
          {loading && (
            <div
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                display: "grid",
                placeItems: "center",
                backgroundColor: "#00000015",
              }}
            >
              <div data-uk-spinner="ratio: 1.5"></div>
            </div>
          )}
        </div>
      </div>
    );
  }
);

interface IFolderItemProps {
  folder: PayrollFolder;
}
const PayrollFolderItem = observer((props: IFolderItemProps) => {
  const { folder } = props;
  const { store, api, ui } = useAppContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [unsavedChanges, setunSavedChanges] = useState(false);
  const { autoSave } = useAutoSave({ timeout: 2000 });

  const onView = () => {
    store.payrollfolder.select(folder.asJson);
    navigate(`/c/payroll/fy/${folder.id}`);
  };

  const handleUpdate = async (value: number) => {
    try {
      folder.year = value;
      autoSave(async () => {
        setunSavedChanges(true);
        await api.payrollfolder.update(folder.asJson);
        setunSavedChanges(false);
      });
    } catch {
      ui.snackbar.load({
        id: Date.now(),
        type: "success",
        message: `Failed to update`,
        timeoutInMs: 10000,
      });
    }
  };

  const handleDelete = async (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (!window.confirm("Delete Folder?")) return;
    try {
      setLoading(true);
      await api.payrollfolder.delete(folder.id);
    } catch (error) { }
    setLoading(false);
  };

  return (
    <div className="folder-card-body">
      <div className="folder-file">
        <div className="folder-image" onClick={onView}>
          <img
            src={process.env.PUBLIC_URL + "/icons/folder_icon.svg"}
            width="1700"
            height="1100"
            alt=""
          />
        </div>
        <button className="edit-icon" data-uk-tooltip="Edit">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
          </svg>
        </button>
        <PortfolioSetting
          folderName={folder.year}
          handleDelete={handleDelete}
          loading={loading}
          unsavedChanges={unsavedChanges}
          handleUpdate={handleUpdate}
        />
      </div>
      <div className="folder-title">
        <p>{folder.year}</p>
        <span></span>
      </div>
    </div>
  );
});
export default PayrollFolderItem;
