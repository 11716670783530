import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import Loading from "../../../shared/components/Loading";
import Modal from "../../../shared/components/Modal";
import { useAppContext } from "../../../shared/functions/Context";
import showModalFromId from "../../../shared/functions/ModalShow";
import DIALOG_NAMES from "../../dialogs/Dialogs";
import RegionDialog from "../../dialogs/region-dialog/RegionDialog";
import IRegion from "../../../shared/interfaces/IRegion";
import { Region } from "../../../shared/models/Region";

interface ToolBarProps {
  onAdd: (region?: Region) => void;
}
const ToolBar = (props: ToolBarProps) => {
  const { onAdd } = props;
  return (
    <div className="section-toolbar uk-margin">
      <h4 className="section-heading uk-heading">Regions</h4>

      <div className="controls">
        <button className="uk-button primary" onClick={() => onAdd()}>
          Add Region
        </button>
      </div>
    </div>
  );
};

interface IRegionProps {
  regions: Region[];
  isLoading: boolean;
  onEdit: (region: IRegion) => void;
  onDelete: (id: string) => void;
}
const RegionsTable = (props: IRegionProps) => {
  const { regions, isLoading, onEdit, onDelete } = props;
  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    setIsEmpty(regions.length === 0);
    return () => {};
  }, [regions.length]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="company-users uk-margin">
        {!isEmpty ? (
          <div className="uk-overflow-auto">
            <table className="company-users-table uk-table uk-table-small uk-table-divider uk-table-middle uk-table-responsive">
              <thead className="table-header">
                <tr>
                  <th>#</th>
                  <th>Region</th>
                  <th className="actions">Actions</th>
                </tr>
              </thead>
              <tbody className="table-body">
                {regions.map((region, index) => (
                  <tr className="row" key={region.id}>
                    <td className="id">{index + 1}</td>
                    <td className="departmentName">{region.name} </td>
                    <td className="actions">
                      <button
                        className="uk-button text uk-margin-small-right"
                        onClick={() => onEdit(region.asJson)}
                      >
                        <span data-uk-icon="icon: pencil"></span>
                        Edit
                      </button>
                      <button
                        className="uk-button text-danger"
                        onClick={() => onDelete(region.id)}
                      >
                        <span data-uk-icon="icon: trash"></span>
                        Remove
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="no-orders">
            <p className="uk-text-center">
              Empty (no regions) <span>😔</span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

const Regions = observer(() => {
  const { api, store, ui } = useAppContext();
  const [loading, setLoading] = useState(false);

  const onAdd = (region?: IRegion) => {
    if (region) store.region.select(region);
    else store.region.clearSelected();
    showModalFromId(DIALOG_NAMES.TEAM.REGION_DIALOG);
  };

  const onDelete = async (id: string) => {
    if (!window.confirm("Delete region?")) return;
    try {
      await api.region.delete(id);
      await store.region.remove(id);
      ui.snackbar.load({
        id: Date.now(),
        message: "Region deleted!",
        type: "success",
      });
    } catch (error) {
      ui.snackbar.load({
        id: Date.now(),
        message: "Confirm that no user is assigned to region.",
        type: "warning",
        timeoutInMs: 10000,
      });
      ui.snackbar.load({
        id: Date.now(),
        message: "Error! Deleting region!",
        type: "danger",
      });
    }
  };

  // Load regions.
  const load = useCallback(async () => {
    setLoading(true);
    await api.region.getAll();
    setLoading(false);
  }, [api.region]);

  useEffect(() => {
    load();
    return () => {};
  }, [load]);

  return (
    <>
      <div className="uk-section">
        <div className="uk-container uk-container-large">
          <ToolBar onAdd={onAdd} />
          <div className="uk-card uk-card-default uk-card-small uk-card-body">
            <RegionsTable
              regions={store.region.all}
              isLoading={loading}
              onEdit={onAdd}
              onDelete={onDelete}
            />
          </div>
        </div>
      </div>
      <Modal modalId={DIALOG_NAMES.TEAM.REGION_DIALOG}>
        <RegionDialog />
      </Modal>
    </>
  );
});

export default Regions;
