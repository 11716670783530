import react, { useEffect, useState } from "react"
import { useAppContext } from "../../../shared/functions/Context";
import showModalFromId from "../../../shared/functions/ModalShow";
import { ISupplier } from "../../../shared/models/InventorySupplier";
import DIALOG_NAMES from "../../dialogs/Dialogs";
import { observer } from "mobx-react-lite";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../shared/database/FirebaseConfig";
import { useNavigate } from "react-router-dom";


interface IProps {
    supplier: ISupplier
}

export const SupplierTable = observer((props: IProps) => {
    const { supplier } = props;
    const { store, api } = useAppContext();
    const [codes, setCodes] = useState<[]>([]);
    const navigate = useNavigate();

    const getCode = async () => {
        const colRef = collection(db, "InventoryOveride");
        const docsSnap = await getDocs(colRef);
        const list: any = [];
        docsSnap.forEach((doc) => {
            const code = doc.data().code;
            list.push(code);
        })
        console.log(list);
        setCodes(list);
    }

    useEffect(() => {
        getCode();

    }, [])




    const onDelete = async (id: string) => {
        let code: any = prompt("Enter your code:");
        if (codes.length > 0 && codes.some((c: string) => code.includes(c))) {
            if (window.confirm("Delete")) {
                try {
                    await api.inventory.suppliers.delete(id)
                    await api.inventory.suppliers.getAll();
                } catch (error) {
                    console.log("Error creating", error)
                }
            }
        } else {
            alert("error.");
        }
    }

    const onView = () => {
        let code: any = prompt("Enter your code:");
        if (codes.length > 0 && code && codes.some((c: string) => code.includes(c))) {
            store.inventory.supplier.select(supplier);
            showModalFromId(DIALOG_NAMES.INVENTORY.CREATE_SUPPLIER_DIALOG);
        }
        else {
            alert("error.");
        }
    };

    const view = (id: string) => {
        navigate(`/c/inventory/supplier/${id}`)
    }

    return (
        <>
            <td>{supplier.supplierName}</td>
            <td>{supplier.registrationNo}</td>
            <td>{supplier.vatRegistration}</td>
            <td>{supplier.contactNumber}</td>
            <td>{supplier.contactEmail}</td>
            <td>
                <button onClick={() => view(supplier.id)} className="uk-button secondary uk-margin-right" type="button">
                    View
                </button>
                <button onClick={onView} className="uk-button primary uk-margin-right" type="button">
                    Edit
                </button>
                <button onClick={() => onDelete(supplier.id)} className="uk-button danger" type="button">
                    Delete
                </button>
            </td>
        </>
    )
})