import { observer } from "mobx-react-lite";
import { Dispatch, FormEvent, SetStateAction } from "react";
import { IInventoryProductCategory } from "../../../shared/models/InventoryProductCategory";

interface IProps {
  productCategory: IInventoryProductCategory;
  setInventoryProduct: Dispatch<SetStateAction<IInventoryProductCategory>>;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  loading: boolean;
}
const ProductForm = observer((props: IProps) => {
  const { productCategory, setInventoryProduct, onSubmit, loading } = props;

  return (
    <form
      className="uk-form-stacked uk-grid-small uk-child-width-1-1"
      data-uk-grid
      onSubmit={onSubmit}
    >
      <div className="uk-grid-small uk-child-width-1-1" data-uk-grid>
        <div>
          <div className="uk-margin">
            <label className="uk-form-label" htmlFor="form-stacked-text">
              Category ID
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input uk-form-small"
                type="text"
                placeholder="Catergory ID"
                value={productCategory.categoryId}
                onChange={(e) =>
                  setInventoryProduct({ ...productCategory, categoryId: e.target.value })
                }
                required
              />
            </div>
          </div>
        </div>
        <div>
          <div className="uk-margin">
            <label className="uk-form-label" htmlFor="form-stacked-text">
              Catergory Name
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input uk-form-small"
                type="text"
                placeholder="Catergory Name"
                value={productCategory.categoryName}
                onChange={(e) =>
                  setInventoryProduct({ ...productCategory, categoryName: e.target.value })
                }
                required
              />
            </div>
          </div>
        </div>
        {/* <div>
          <div className="uk-margin">
            <label className="uk-form-label" htmlFor="form-stacked-text">
              Description
            </label>
            <div className="uk-form-controls">
              <textarea
                className="uk-textarea uk-form-small"
                placeholder="Description"
                value={productCategory.productCategoryDescription.productCategoryDescription}
                onChange={(e) =>
                  setInventoryProduct({
                    ...productCategory,
                    productCategoryDescription.: e.target.value,
                  })
                }
                required
              />
            </div>
          </div>
        </div> */}
        <div className="footer">
          <button className="uk-button secondary uk-modal-close" type="button">
            Cancel
          </button>
          <button className="uk-button uk-button-primary" type="submit">
            Save
            {loading && <div data-uk-spinner="ratio: .5"></div>}
          </button>
        </div>
      </div>
    </form>
  );
});

export default ProductForm;
