import Store from "../Store";
import AppStore from "../AppStore";
import { runInAction } from "mobx";
import { Payroll } from "../../models/Payroll";
import IPayroll from "../../interfaces/IPayroll";

export default class PayrollBatchStore extends Store<IPayroll, Payroll> {
  items = new Map<string, Payroll>();

  constructor(store: AppStore) {
    super(store);
    this.store = store;
  }

  load(items: IPayroll[] = []) {
    runInAction(() => {
      items.forEach((item) =>
        this.items.set(item.id, new Payroll(this.store, item))
      );
    });
  }

  get allDraft() {
    const list = Array.from(this.items.values());
    return list.filter((item) => item.status === "draft");
  }

  get allCompleted() {
    const list = Array.from(this.items.values());
    return list.filter((item) => item.status === "completed");
  }

  get inProgress() {
    const list = Array.from(this.items.values());
    return list.filter((item) => item.status === "in-progress");
  }

  get size() {
    return this.items.size;
  }
}
