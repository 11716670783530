import bg from "./assets/recieve.svg"
import bg1 from "./assets/count.svg"
import bg2 from "./assets/po.svg"
import bg3 from "./assets/tranfers.svg"
import bg4 from "./assets/dispose.svg"
import bg5 from "./assets/levels.svg"

export const Manage = () => {
    return (
        <div className="uk-section leave-analytics-page manage">
            <div className="uk-container uk-container-large">
                <div className="section-toolbar uk-margin">
                    <h4 className="section-heading uk-heading">Stock Management</h4>
                    <div className="controls">
                    </div>
                </div>
                <div className="uk-child-width-1-3@m uk-grid-small uk-grid-match" data-uk-grid>
                    <div >
                        <div style={{ backgroundImage: `url(${bg})`, backgroundPosition: "right", backgroundSize: "20%", backgroundRepeat: "no-repeat" }} className="uk-card uk-card uk-card-body">
                            <h3 className="uk-card-title">Receiving Stock</h3>
                            <button className="uk-button primary uk-margin-right" type="button">
                                Receive Stock
                            </button>
                        </div>
                    </div>
                    <div>
                        <div style={{ backgroundImage: `url(${bg1})`, backgroundPosition: "right", backgroundSize: "20%", backgroundRepeat: "no-repeat" }} className="uk-card uk-card uk-card-body">
                            <h3 className="uk-card-title">Stock Counting</h3>
                            <button className="uk-button primary uk-margin-right" type="button">
                                Start Stoke Count
                            </button>
                        </div>
                    </div>
                    <div>
                        <div style={{ backgroundImage: `url(${bg2})`, backgroundPosition: "right", backgroundSize: "20%", backgroundRepeat: "no-repeat" }} className="uk-card uk-card uk-card-body">
                            <h3 className="uk-card-title">Stock Replenishment</h3>
                            <button className="uk-button primary uk-margin-right" type="button">
                                Issue Purchase Order
                            </button>
                        </div>
                    </div>
                    {/* <div>
                        <div className="uk-card uk-card-secondary uk-card-body">
                            <h3 className="uk-card-title">Stock Allocation</h3>
                            <button className="uk-button primary uk-margin-right" type="button">
                                Start process
                            </button>
                        </div>
                    </div> */}
                    <div>
                        <div style={{ backgroundImage: `url(${bg3})`, backgroundPosition: "right", backgroundSize: "20%", backgroundRepeat: "no-repeat" }} className="uk-card uk-card uk-card-body">
                            <h3 className="uk-card-title">Stock Transfers</h3>
                            <button className="uk-button primary uk-margin-right" type="button">
                                Transfer Now
                            </button>
                        </div>
                    </div>
                    <div>
                        <div style={{ backgroundImage: `url(${bg4})`, backgroundPosition: "right", backgroundSize: "20%", backgroundRepeat: "no-repeat" }} className="uk-card uk-card uk-card-body">
                            <h3 className="uk-card-title">Stock Disposal</h3>
                            <button className="uk-button primary uk-margin-right" type="button">
                                Dispose Stock
                            </button>
                        </div>
                    </div>
                    <div>
                        <div style={{ backgroundImage: `url(${bg5})`, backgroundPosition: "right", backgroundSize: "20%", backgroundRepeat: "no-repeat" }} className="uk-card uk-card uk-card-body">
                            <h3 className="uk-card-title">Stock Level Reporting</h3>
                            <button className="uk-button primary uk-margin-right" type="button">
                                Set Stock Levels
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}