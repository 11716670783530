import React from "react";
import { TaxRate } from "../../../shared/models/TaxRate";
import TaxRateItem from "./TaxRateItem";

interface IProps {
  taxRates: TaxRate[];
}
const TaxRates = (props: IProps) => {
  const { taxRates } = props;

  return (
    <div className="tax-rates uk-margin">
      <h4 className="uk-margin">Tax Rates</h4>
      {taxRates.map((taxRate) => (
        <div key={taxRate.id}>
          <TaxRateItem taxRate={taxRate} />
        </div>
      ))}
    </div>
  );
};

export default TaxRates;
