import { observer } from "mobx-react-lite";
import { PayrollFolder } from "../../../shared/models/PayrollFolder";
import PayrollFolderItem from "./PayrollFolderItem";

interface IProps {
  folders: PayrollFolder[];
}
const PayrollFolderList = observer((props: IProps) => {
  const { folders } = props;

  const sorted = folders.sort((a, b) => b.year - a.year);

  return (
      <div className="uk-grid-small" data-uk-grid>
        {sorted.map((folder) => (
          <PayrollFolderItem key={folder.id} folder={folder} />
        ))}
      </div>
  );
});

export default PayrollFolderList;
