import {
  CollectionReference,
  collection,
  deleteDoc,
  doc,
  getDoc,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
} from "firebase/firestore";

import AppStore from "../stores/AppStore";
import AppApi from "./AppApi";
import { IPurchaseOrder } from "../models/InventoryPurchaseOrder";
import { db } from "../database/FirebaseConfig";

export default class InventoryPurchaseOrderApi {
  collectionRef: CollectionReference;
  constructor(
    private api: AppApi,
    private store: AppStore,
    collectionRef: CollectionReference
  ) {
    this.collectionRef = collectionRef;
  }


  private getPath(purchaseOrderId: string) {
    return `${purchaseOrderId}/logs`;
  }


  async createLog(purchaseOrderId: string, item: any) {
    const path = this.getPath(purchaseOrderId);
    if (!path) return;

    const itemRef = doc(collection(db, path));
    item.id = itemRef.id;

    // create in db
    try {
      await setDoc(itemRef, item, {
        merge: true,
      });
    } catch (error) {
      // console.log(error);
    }
  }


  async getAll() {
    const q = query(this.collectionRef);

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const items: IPurchaseOrder[] = [];
      querySnapshot.forEach((doc) => {
        items.push({ id: doc.id, ...doc.data() } as IPurchaseOrder);
      });

      this.store.inventory.purchaseOrder.load(items);
    });

    return unsubscribe;
  }

  async getPurchaseOrder(id: string) {
    const docSnap = await getDoc(doc(this.collectionRef, id));
    if (docSnap.exists()) {
      const order = { ...docSnap.data(), id: docSnap.id } as IPurchaseOrder;
      await this.store.inventory.purchaseOrder.load([order]);
      return order;
    } else return undefined;
  }

  async create(data: IPurchaseOrder) {
    const docRef = doc(this.collectionRef);
    data.id = docRef.id;
    await setDoc(docRef, data, { merge: true });
    return data;
  }

  async update(order: IPurchaseOrder) {
    await setDoc(doc(this.collectionRef, order.id), order, {
      merge: true,
    });
    this.createLog(order.id, "who who did what what")
    return order;
  }

  // async updateStatus(order: IPurchaseOrder) {
  //   await setDoc(doc(this.collectionRef, order.status), order, {
  //     merge: true,
  //   });
  //   // this.createLog(order., "who who did what what")
  //   return order;
  // }

  async updateStatus(order: IPurchaseOrder, newStatus: string) {
    const docRef = doc(this.collectionRef, order.id);
    try {
      await updateDoc(docRef, { status: newStatus });
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const updatedOrder = { ...docSnap.data(), id: docSnap.id } as IPurchaseOrder;
        this.createLog(order.id, "Status updated to " + newStatus);
        return updatedOrder;
      } else {
        throw new Error("Failed to update purchase order status.");
      }
    } catch (error) {
      console.log("Error updating status:", error);
      throw error;
    }
  }

  async delete(id: string) {
    const docRef = doc(this.collectionRef, id);
    await deleteDoc(docRef);
    this.store.inventory.purchaseOrder.remove(id);
  }



}

