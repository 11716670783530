import axios from "axios";
import AppStore from "../stores/AppStore";
import AppApi from "./AppApi";

export default class MailApi {
  API_URI: string;

  constructor(private api: AppApi, private store: AppStore, URI: string) {
    this.API_URI = URI;
  }

  async leaveUpdate(to: string[], subject: string, message: string) {
    const from = "no-reply@lotsinsights.com";
    await this.sendMail(from, to, subject, message);
  }

  async leaveApplicant(subject: string, message: string) {
    const user = this.store.user.meJson;
    if (!user) return;

    const from = "no-reply@lotsinsights.com";
    const to = [user.email];
    await this.sendMail(from, to, subject, message);
  }

  async leaveAdmin(subject: string, message: string) {
    const me = this.store.user.meJson;
    if (!me) return;

    const users = await this.api.auth.loadHRAdmin();
    const supervisor = await this.api.auth.loadSupervisor();

    const to = users.map(user => user.email);
    const $to = supervisor.map(user => user.email);
    const emails = [...to, ...$to];

    const from = "no-reply@lotsinsights.com";
    await this.sendMail(from, emails, subject, message);
  }

  async approvalEmail(to: string[], subject: string, message: string) {
    const from = "no-reply@lotsinsights.com";
    await this.sendMail(from, to, subject, message);
  }

  async sendInvoiceLink(message: string, to: string[]) {
    const from = "no-reply@lotsinsights.com";
    const subject = "Invoice issued, click the link to view";
    await this.sendMail(from, to, subject, message);
  }

  async sendMail(from: string, to: string[], subject: string, message: string) {
    const body = {
      from: from,
      to: to.join(", "),
      subject,
      message,
    };

    const params = new URLSearchParams(body);
    const uri = `${this.API_URI}?${params.toString()}`;

    axios.get(uri).then((response) => {
    }).catch((error) => { });
  }

  async sendToOne(from: string, to: string, subject: string, message: string) {
    const body = {
      from: from,
      to: to,
      subject,
      message,
    };

    const params = new URLSearchParams(body);
    const uri = `${this.API_URI}?${params.toString()}`;

    axios.get(uri).then((response) => {
    }).catch((error) => {});
  }
}