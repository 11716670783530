import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        title: {
            display: true,
            text: 'Top 3 Highest Stocked Categories',
        },
    },
};

const labels = ['Category 1', 'Category 2', 'Category 3'];

export const data = {
    labels,
    datasets: [
        {
            label: 'Total stock',
            data: [220, 344, 766],
            backgroundColor: 'grey',
        },
    ],
};


export const StockLevel = () => {
    return (
        <Bar options={options} data={data} />
    )
}