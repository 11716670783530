import { useState } from "react";
import { useAppContext } from "../../../shared/functions/Context";
import Overide from "../../../shared/models/InventoryOveride"
import Loading from "../../../shared/components/Loading";
import { OverideBtns } from "./OverideBtns";
import { observer } from "mobx-react-lite";


interface IProps {
    overrides: Overide[]
}

export const Overrides = observer((props: IProps) => {
    const { overrides } = props;
    const [loading, setLoading] = useState(true);

    setTimeout(() => {
        setLoading(false);
    }, 2000);


    return (
        <div>
            {loading ? <><Loading /></> :
                <>
                    <div className="uk-child-width-1-2@m uk-grid-small uk-grid-match" data-uk-grid>
                        {overrides.map((overrides) => (
                            <div key={overrides.asJson.id}>
                                <div className="uk-card uk-card-default uk-card-body">
                                    <h4 className="" style={{ textTransform: "uppercase" }}>{"manager: "}{overrides.asJson.manager}{"code: "}{overrides.asJson.code}</h4>
                                    <OverideBtns key={overrides.asJson.id} override={overrides} />
                                </div>
                            </div>
                        ))}

                    </div>
                    {overrides.length === 0 &&
                        <div className="no-orders">
                            <p className="uk-text-center">
                                Empty (no data) <span>😔</span>
                            </p>
                        </div>
                    }
                </>}

        </div>
    )
})