import { doc, setDoc, deleteDoc, updateDoc, getDocs, collection, onSnapshot, query, where } from "firebase/firestore";
import ILeaveRequest from "../interfaces/ILeaveRequest";
import AppStore from "../stores/AppStore";
import AppApi from "./AppApi";
import { db } from "../database/FirebaseConfig";

export default class LeaveRequestApi {
  constructor(private api: AppApi, private store: AppStore) { }

  private requestPath() {
    return "Leave-requests";
  }

  async getAll() {
    const path = this.requestPath();
    const $query = query(collection(db, path))

    const unsubscribe = onSnapshot($query, (querySnapshot) => {
      const items: ILeaveRequest[] = [];
      querySnapshot.forEach((doc) => {
        items.push({ ...doc.data(), id: doc.id } as ILeaveRequest);
      });
      this.store.leave.requests.load(items);
    });

    return unsubscribe;
  }

  async getAllByUID(uid: string) {
    this.store.leave.requests.removeAll();

    const path = this.requestPath();
    const $query = query(collection(db, path), where("userId", "==", uid))

    const unsubscribe = onSnapshot($query, (querySnapshot) => {
      const items: ILeaveRequest[] = [];
      querySnapshot.forEach((doc) => {
        items.push({ ...doc.data(), id: doc.id } as ILeaveRequest);
      });
      this.store.leave.requests.load(items);
    });

    return unsubscribe;
  }

  async getAllFromTo(from: number, to: number) {
    this.store.leave.requests.removeAll();

    const path = this.requestPath();
    const $query = query(collection(db, path), where("from", ">=", from), where("from", "<=", to))
    const docs = await getDocs($query);

    const items: ILeaveRequest[] = [];

    docs.forEach((doc) => {
      items.push({ ...doc.data(), id: doc.id } as ILeaveRequest);
    });

    this.store.leave.requests.load(items);
  }

  async getAllApproved() {
    this.store.leave.requests.removeAll();

    const path = this.requestPath();
    const $query = query(collection(db, path), where("status", "==", "APPROVED"))

    const unsubscribe = onSnapshot($query, (querySnapshot) => {
      const items: ILeaveRequest[] = [];
      querySnapshot.forEach((doc) => {
        items.push({ ...doc.data(), id: doc.id } as ILeaveRequest);
      });
      this.store.leave.requests.load(items);
    });

    return unsubscribe;
  }

  async getAllApprovedByUid(uid: string) {
    this.store.leave.requests.removeAll();

    const path = this.requestPath();
    const $query = query(collection(db, path), where("status", "==", "APPROVED"), where("userId", "==", uid));
    const unsubscribe = onSnapshot($query, (querySnapshot) => {
      const items: ILeaveRequest[] = [];
      querySnapshot.forEach((doc) => {
        items.push({ ...doc.data(), id: doc.id } as ILeaveRequest);
      });
      this.store.leave.requests.load(items);
    });

    return unsubscribe;
  }

  async create(request: ILeaveRequest) {

    const path = this.requestPath();
    if (!path) return;

    const itemRef = doc(collection(db, path))
    request.id = itemRef.id;

    try {
      await setDoc(itemRef, request, { merge: true, })
    } catch (error) { }
  }

  // async create(request: ILeaveRequest) {
  //   await setDoc(doc(this.collectionRef), request, { merge: true });
  //   return request;
  // }

  async update(request: ILeaveRequest) {

    const path = this.requestPath();
    if (!path) return;

    try {
      await updateDoc(doc(db, path, request.id), {
        ...request,
      });
      this.store.leave.requests.load([request]);
    } catch (error) {
      console.log(error);

    }
  }

  // async update(request: ILeaveRequest) {
  //   await setDoc(doc(this.collectionRef, request.id), request, { merge: true });
  //   return request;
  // }

  async delete(request: ILeaveRequest) {
    const path = this.requestPath();
    if (!path) return;

    try {
      await deleteDoc(doc(db, path, request.id));
      this.store.leave.requests.remove(request.id);
    } catch (error) { }
  }

  // async delete(id: string) {
  //   await deleteDoc(doc(this.collectionRef, id));
  //   this.store.leave.requests.remove(id);
  // }
}
