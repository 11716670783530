import React from 'react';
import { StockLevel } from './graphs/StockLevel';
import { SalesTurnOver } from './graphs/SalesTurnOver';
import { SalesTrends } from './graphs/SalesTrends';
import { SalesTrendsProducts } from './graphs/SalesTrendProducts';
import { Revenue } from './graphs/Revenue';


export const StokeManagement = () => {
    return (
        <div className='inventory'>
            <div className="uk-section leave-analytics-page">
                <div className="uk-container uk-container-large">
                    <div className="section-toolbar uk-margin">
                        <h4 className="section-heading uk-heading">Inventory Dashboard</h4>
                        <div className="controls">
                        </div>
                    </div>
                    <div className="uk-child-width-1-2@m uk-grid-small uk-text-center uk-margin-top " data-uk-grid>
                        <div className="">
                            <div className="uk-child-width-1-2@m uk-grid-small uk-text-center" data-uk-grid>
                                <div>
                                    <div className="uk-card uk-card-default uk-card-body uk-card-dash">
                                        <div className="uk-child-width-1-1@m uk-grid-small uk-grid-match" data-uk-grid>
                                            <div>
                                                <div className="uk-card uk-card uk-card-body dash-card">
                                                    <h3 className="uk-card-title">Stock Value</h3>
                                                    <span className="material-symbols-outlined">
                                                        database
                                                    </span>
                                                    <p>N$ 4,5 Million</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="uk-card uk-card uk-card-body dash-card">
                                                    <h3 className="uk-card-title">Total Revenue</h3>
                                                    <span className="material-symbols-outlined">
                                                        attach_money
                                                    </span>
                                                    <p>N$ 2 Million</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="uk-card uk-card uk-card-body dash-card">
                                                    <h3 className="uk-card-title">Total Products</h3>
                                                    <span className="material-symbols-outlined">
                                                        align_items_stretch
                                                    </span>
                                                    <p>36,000</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="uk-card uk-card uk-card-body dash-card">
                                                    <h3 className="uk-card-title">Damaged Stock</h3>
                                                    <span className="material-symbols-outlined">
                                                        warning
                                                    </span>
                                                    <p>66</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="uk-card uk-card-default uk-card-body uk-card-dash">
                                        <div className="uk-child-width-1-1@m uk-grid-small uk-grid-match" data-uk-grid>
                                            <div>
                                                <div className="">
                                                    <h3 className="uk-card-title" style={{ fontWeight: "600", color: "grey" }}>LOW ON STOCK</h3>
                                                    <table className="uk-table uk-table-small uk-table-divider uk-table-striped uk-table-hover">
                                                        <thead>
                                                            <tr>
                                                                <td>Name</td>
                                                                <td>Quantity</td>
                                                                <td>Action</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Soap</td>
                                                                <td>12</td>
                                                                <td>
                                                                    <button className="uk-button primary uk-margin-right" type="button">
                                                                        order
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Beer</td>
                                                                <td>12</td>
                                                                <td>
                                                                    <button className="uk-button primary uk-margin-right" type="button">
                                                                        order
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Whisky</td>
                                                                <td>12</td>
                                                                <td>
                                                                    <button className="uk-button primary uk-margin-right" type="button">
                                                                        order
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <button className="uk-button primary uk-margin-right" type="button">
                                                        view all
                                                    </button>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="">
                                                    <h3 className="uk-card-title" style={{ fontWeight: "600", color: "grey" }}>MOST STOCKED</h3>
                                                    <table className="uk-table uk-table-small uk-table-divider uk-table-striped uk-table-hover">
                                                        <thead>
                                                            <tr>
                                                                <td>Name</td>
                                                                <td>Quantity</td>
                                                                <td>Action</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Soap</td>
                                                                <td>12</td>
                                                                <td>
                                                                    <button className="uk-button primary uk-margin-right" type="button">
                                                                        discount
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Beer</td>
                                                                <td>12</td>
                                                                <td>
                                                                    <button className="uk-button primary uk-margin-right" type="button">
                                                                        discount
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Whisky</td>
                                                                <td>12</td>
                                                                <td>
                                                                    <button className="uk-button primary uk-margin-right" type="button">
                                                                        discount
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <button className="uk-button primary uk-margin-right" type="button">
                                                        view all
                                                    </button>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="">
                                                    <h3 className="uk-card-title" style={{ fontWeight: "600", color: "grey" }}>STAGNANT</h3>
                                                    <table className="uk-table uk-table-small uk-table-divider uk-table-striped uk-table-hover">
                                                        <thead>
                                                            <tr>
                                                                <td>Name</td>
                                                                <td>Quantity</td>
                                                                <td>Action</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Soap</td>
                                                                <td>12</td>
                                                                <td>
                                                                    <button className="uk-button primary uk-margin-right" type="button">
                                                                        manage
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Beer</td>
                                                                <td>12</td>
                                                                <td>
                                                                    <button className="uk-button primary uk-margin-right" type="button">
                                                                        manage
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Whisky</td>
                                                                <td>12</td>
                                                                <td>
                                                                    <button className="uk-button primary uk-margin-right" type="button">
                                                                        manage
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <button className="uk-button primary uk-margin-right" type="button">
                                                        view all
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <div className="uk-card uk-card-default uk-card-body uk-card-dash">
                                <div className="uk-margin-top ">
                                    <h5 style={{ color: "grey", fontWeight: "500" }}>Sales Trends</h5>
                                    <div className="uk-child-width-1-2@m uk-grid-small " data-uk-grid>
                                        <div>
                                            <div className="dash-card uk-card uk-card-secondary uk-card-body">
                                                <h3 className="uk-card-title">Top Categories</h3>
                                                <SalesTrends />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="dash-card uk-card uk-card-secondary uk-card-body">
                                                <h3 className="uk-card-title">Top Products</h3>
                                                < SalesTrendsProducts />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <StockLevel />
                                </div>
                                <div className="uk-margin-top">
                                    <SalesTurnOver />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="uk-card uk-card-default uk-card-body uk-margin-top uk-card-dash">
                            <Revenue />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}























