import { observer } from "mobx-react-lite";
import { Fragment, useEffect } from "react";
import { useAppContext } from "../../../shared/functions/Context";
import { LeaveSetting } from "../../../shared/models/LeaveSetting";
import IDepartment from "../../../shared/interfaces/IDepartment";
import LeaveList from "./LeaveList";

interface DepartmentProps {
  department: IDepartment;
  leaveItems: LeaveSetting[];
}
const Department = (props: DepartmentProps) => {
  const { department, leaveItems } = props;

  return (
    <div className="uk-margin">
      <div className="uk-card uk-card-default uk-card-small uk-card-body">
        <h6 className="department-name">{department.name}</h6>
        <LeaveList department={department} leaveItems={leaveItems} />
      </div>
    </div>
  );
};

const DepartmentLeaves = observer(() => {
  const { api, store } = useAppContext();

  const getDepartmentLeaveItems = (departmentId: string): LeaveSetting[] => {
    return store.leave.settings.allDeparmentLeaves(departmentId);
  };

  useEffect(() => {
    const loadDepartments = async () => {
      await api.department.getAll();
    };
    loadDepartments();
  }, [api.department]);

  return (
    <div>
      {store.department.all &&
        store.department.all.map((department) => (
          <Fragment key={department.id}>
            <Department
              department={department.asJson}
              leaveItems={getDepartmentLeaveItems(department.id)}
            />
          </Fragment>
        ))}
    </div>
  );
});

export default DepartmentLeaves;
