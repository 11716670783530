import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import IPayrollEmployee, { IFixedPaymentInput, IFlexiPaymentInput, IPaymentSummary } from "../../../shared/interfaces/IPayrollEmployee";
// import { CustomNumberFormat, CustomNumberFormat } from "../../../shared/functions/CustomNumberFormat";
import { toJS } from "mobx";
import { useAppContext } from "../../../shared/functions/Context";
import { TaxRate } from "../../../shared/models/TaxRate";

interface IProps {
  employee: IPayrollEmployee;
  setEmployee: React.Dispatch<React.SetStateAction<IPayrollEmployee | undefined>>;
}

const PaymentSummary = observer((props: IProps) => {

  const { store } = useAppContext();

  const { employee, setEmployee } = props;

  const loadFormulaRange = (taxRage: TaxRate[]) => {
    const taxFormulas: TaxRate[] = [];
    for (let i = 0; i < taxRage.length; i++) {
      const range = toJS(taxRage[i]);
      taxFormulas.push(range);
    }
    return taxFormulas;
  };

  const findTaxRateRange = (annualAmount: number) => {
    const taxFormulas = loadFormulaRange(store.payroll.taxRate.all);
    const formula = taxFormulas.find((fm) => annualAmount >= fm.taxableIncomeMin && annualAmount < fm.taxableIncomeMax);
    return formula ? formula : null;
  };

  const incomeTaxFormula = (monthlyAmount: number) => {
    const annualAmount = monthlyAmount * 12;
    const formula = findTaxRateRange(annualAmount);
    const ratedAmount = (annualAmount - (formula ? formula.taxableIncomeMin - 1 : 0)) * (formula ? formula.percentage / 100 : 0);
    const annualTax = formula ? (formula.baseRate + ratedAmount) : 0;
    return annualTax / 12;
  };

  const sum = (prev: number, curr: number) => prev + curr;
  const zeroArray = (values: number[]) => (values.length ? values : [0]);

  const amount = (item: IFixedPaymentInput | IFlexiPaymentInput) => item.amount;
  const percentageAmount = (item: IFixedPaymentInput | IFlexiPaymentInput) => item.percentageAmount;
  const finalAmount = (item: IFixedPaymentInput | IFlexiPaymentInput) => item.finalAmount;


  const totalFixedBonus = employee.fixedBonus.map(amount);
  const totalFlexiBonus = employee.flexiBonus.map(amount);
  const totalBonus = zeroArray(totalFixedBonus).reduce(sum) + zeroArray(totalFlexiBonus).reduce(sum);

  const totalFixedExpenses = employee.fixedExpenses.map(amount);
  const totalFlexiExpenses = employee.flexiExpenses.map(amount);
  const totalExpenses = zeroArray(totalFixedExpenses).reduce(sum) + zeroArray(totalFlexiExpenses).reduce(sum);

  const totalAmounts = employee.fixedSalary.map(amount);
  const amountsGoingForTax = employee.fixedSalary.map(finalAmount);
  const amountNotGoingForTax = employee.fixedSalary.map(percentageAmount);

  const grossIncome = zeroArray(totalAmounts).reduce(sum);
  const taxGrossIncome = zeroArray(amountsGoingForTax).reduce(sum);
  const amountsToBeAddedAfterTax = zeroArray(amountNotGoingForTax).reduce(sum);

  const professionalTax = incomeTaxFormula(taxGrossIncome);
  const totalFixedDeductions = employee.fixedDeductions.map(amount);
  const totalDeductions = (zeroArray(totalFixedDeductions).reduce(sum) + professionalTax);

  const netIncome = (taxGrossIncome + amountsToBeAddedAfterTax) - (totalDeductions);

  useEffect(() => {
    const paySummary: IPaymentSummary = {
      ...employee.paySummary,
      grossIncome,
      taxGrossIncome,
      totalDeductions,
      totalExpenses,
      totalBonus,
      netIncome,
      professionalTax
    };
    const $employee: IPayrollEmployee = {
      ...employee,
      paySummary,
    };

    setEmployee({ ...$employee });
  }, [grossIncome, totalDeductions, totalBonus, totalExpenses, netIncome, taxGrossIncome, professionalTax]);

  return (
    <div className="payment-summary uk-card uk-card-body uk-card-small">
      <table className="uk-table uk-table-small uk-table-divider">
        <tbody>
          {/* <tr>
            <td className="item">Gross Income</td>
            <td className="uk-text-right">N$ {CustomNumberFormat(grossIncome)}</td>
          </tr>
          <tr>
            <td className="item">Tax Gross Income</td>
            <td className="uk-text-right">N$ {CustomNumberFormat(taxGrossIncome)}</td>
          </tr>
          <tr className="uk-padding-top">
            <td className="item uk-text-bold">Deductions</td>
          </tr>
          {employee.fixedDeductions.map((d) =>
            <tr>
              <td className="uk-text-small uk-text-light">{d.name}</td>
              <td className="uk-text-right">N$ {CustomNumberFormat(d.amount)}</td>
            </tr>
          )}
          <tr>
            <td className="uk-text-small uk-text-light">Income Tax</td>
            <td className="uk-text-right">N$ {CustomNumberFormat(professionalTax)}</td>
          </tr>
          <tr>
            <td className="item">Total Deductions</td>
            <td className="uk-text-right">
              N$ {CustomNumberFormat(totalDeductions)}
            </td>
          </tr>
          <tr className="uk-margin">
            <td className="item">Net Income</td>
            <td className="uk-text-right">N$ {CustomNumberFormat(netIncome)}</td>
          </tr> */}

          {/* <tr>
            <td className="item">Gross Pay</td>
            <td className="uk-text-right">N$ {CustomNumberFormat(grossIncome)}</td>
          </tr>
          <tr>
            <td className="item">Bonus</td>
            <td className="uk-text-right">N${CustomNumberFormat(totalBonus)}</td>
          </tr>
          <tr>
            <td className="item">Tax Gross Pay</td>
            <td className="uk-text-right">N$ {CustomNumberFormat(taxGrossIncome)}</td>
          </tr> */}

          {/* <tr>
            <td className="item">Net Pay</td>
            <td className="uk-text-right">N${netPay.toFixed(2)}</td>
          </tr>
          <tr>
            <td className="item">Earnings</td>
            <td className="uk-text-right">N${totalSalary.toFixed(2)}</td>
          </tr> */}

          <tr className="uk-padding-top">
            <td className="item uk-text-bold">Deductions</td>
          </tr>

          {/* <tr>
            <td className="uk-text-small uk-text-light">Income Tax</td>
            <td className="uk-text-right">N$ {CustomNumberFormat(professionalTax)}</td>
          </tr>
          {employee.fixedDeductions.map((d, index) =>
            <tr key={index}>
              <td className="uk-text-small uk-text-light">{d.name}</td>
              <td className="uk-text-right">N$ {CustomNumberFormat(d.amount)}</td>
            </tr>
          )}
          <tr>
            <td className="item">Total Deductions</td>
            <td className="uk-text-right"> N$ {CustomNumberFormat(totalDeductions)}</td>
          </tr>
          <tr>
            <td className="item">Expenses</td>
            <td className="uk-text-right">N${totalExpenses.toFixed(2)}</td>
          </tr>

          <tr className="uk-margin">
            <td className="item">Net Income</td>
            <td className="uk-text-right">N$ {CustomNumberFormat(netIncome)}</td>
          </tr> */}
        </tbody>
      </table>
    </div>
  );
});

export default PaymentSummary;

