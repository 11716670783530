import { observer } from "mobx-react-lite";
import Loading from "../../../shared/components/Loading";
import { dateFormat, dateFormat_YY_MM_DY, timestampToDate } from "../../../shared/functions/TimestampToDate";
import { LeaveRequest } from "../../../shared/models/LeaveRequest";
import ILeaveRequest from "../../../shared/interfaces/ILeaveRequest";

interface RequestCardProps {
  request: LeaveRequest;
  onApprove: (request: ILeaveRequest) => void;
  onReject: (request: ILeaveRequest) => void;
}
const RequestCard = (props: RequestCardProps) => {
  const { request, onApprove, onReject } = props;
  const { userName, userRole, type, from, to, days, reasonForLeave: reason, status } = request;


  return (
    <div className="request-card uk-card uk-card-default uk-card-small">
      <div className="user">
        <h6 className="name">{userName}</h6>
        <p className="role">{userRole}</p>
      </div>
      <div className="uk-card-body">
        <div className={`leave-type ${status}`}>
          <div className="dot"></div>
          <p>{type}</p>
        </div>
        <div className="leave-date">
          <div className="from">
            <p>
              <span>From</span> {dateFormat(from)}
            </p>
          </div>
          <div className="to">
            <p>
              <span>To</span> {dateFormat(to)}
            </p>
          </div>
          <div className="no-of-days">
            <p>
              <span>Days</span> {days}
            </p>
          </div>
        </div>
        <div className="reason">
          <p>
            <span>Reason</span>
          </p>
          <p>{reason}</p>
        </div>
        <div className="footer">
          <button className="uk-button primary uk-margin-small-right" onClick={() => onApprove(request.asJson)}>
            Approve
          </button>
          <button className="uk-button danger uk-margin-small-right" onClick={() => onReject(request.asJson)}>
            Reject
          </button>
          {request.filePath && (
            <a href={request.filePath} target="_balnk">View File</a>
          )}
        </div>
      </div>
    </div>
  );
};

interface Props {
  requests: LeaveRequest[];
  loading: boolean;
  onApprove: (request: ILeaveRequest) => void;
  onReject: (request: ILeaveRequest) => void;
}
const LeaveGrid = observer((props: Props) => {
  const { requests, loading, onApprove, onReject } = props;

  if (loading) return <Loading />;

  return (
    <div>
      <div className="uk-margin">
        <div className="uk-grid-small uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-grid-match"
          data-uk-grid >
          {requests.map((request, index) => (
            <div key={index}>
              <RequestCard
                key={index}
                request={request}
                onApprove={onApprove}
                onReject={onReject}
              />
            </div>
          ))}
        </div>
      </div>
      {requests.length === 0 && (
        <div className="uk-margin">
          <div className="no-orders">
            <p className="uk-text-center">
              Empty (no requests) <span>😔</span>
            </p>
          </div>
        </div>
      )}
    </div>
  );
});

export default LeaveGrid;
