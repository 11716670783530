import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
    labels: ['Product 1', 'Product 2', 'Product 3'],
    datasets: [
        {
            label: 'Total revenue N$',
            data: [60000.00, 50000.00, 100000.00],
            backgroundColor: [
                'white',
                'grey',
                'lightgrey',
            ],
            borderColor: [
                'black',
                'black',
                'black',

            ],
            borderWidth: 1,
        },
    ],
};


export const SalesTrendsProducts = () => {
    return (
        <Pie data={data} />
    )
}