import { makeAutoObservable } from "mobx";
import AppStore from "../stores/AppStore";
import IPayroll, { IApprovalInput, IPayrollStatus } from "../interfaces/IPayroll";

export class Payroll implements IPayroll {
  id: string;
  folderId: string;
  title: string;
  description: string;
  deadline: string;
  deadlineMs: number;
  amount: number;
  status: IPayrollStatus;
  createdAt: number;
  updatedAt: number;
  approval: IApprovalInput[];

  constructor(private store: AppStore, payroll: IPayroll) {
    makeAutoObservable(this);
    this.id = payroll.id;
    this.folderId = payroll.folderId;
    this.amount = payroll.amount;
    this.title = payroll.title;
    this.deadline = payroll.deadline;
    this.description = payroll.description;
    this.deadlineMs = payroll.deadlineMs;
    this.status = payroll.status;
    this.createdAt = payroll.createdAt;
    this.updatedAt = payroll.updatedAt;
    this.approval = payroll.approval;
  }

  get asJson(): IPayroll {
    return {
      id: this.id,
      folderId: this.folderId,
      amount: this.amount,
      title: this.title,
      deadline: this.deadline,
      description: this.description,
      deadlineMs: this.deadlineMs,
      status: this.status,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      approval: this.approval,
    };
  }
}
