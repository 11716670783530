import { makeAutoObservable } from "mobx";
import AppStore from "../stores/AppStore";
import ILeaveProfile, { ILeaveTaken } from "../interfaces/ILeaveProfile";

export class LeaveProfile implements ILeaveProfile {
  id: string;
  userName: string;
  userId: string;
  userRole: string;
  departmentId: string;
  leavesTaken: ILeaveTaken[];

  constructor(private store: AppStore, leave: ILeaveProfile) {
    makeAutoObservable(this);
    this.id = leave.id;
    this.userName = leave.userName;
    this.userId = leave.userId;
    this.userRole = leave.userRole;
    this.departmentId = leave.departmentId;
    this.leavesTaken = leave.leavesTaken;
  }

  get asJson(): ILeaveProfile {
    return {
      id: this.id,
      userName: this.userName,
      userId: this.userId,
      userRole: this.userRole,
      departmentId: this.departmentId,
      leavesTaken: this.leavesTaken,
    };
  }
}
