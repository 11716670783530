import { makeAutoObservable, toJS } from "mobx";
import AppStore from "../stores/AppStore";

export const defaultOveride: IOveride = {
    id: "",
    code: "",
    manager: ""
};

export interface IOveride {
    id: string;
    code: string;
    manager: string;
}

export default class Overide {
    private overide: IOveride;

    constructor(private store: AppStore, overide: IOveride) {
        makeAutoObservable(this);
        this.overide = overide;
    }

    get asJson(): IOveride {
        return toJS(this.overide);
    }
}