import {
    CollectionReference,
    deleteDoc,
    doc,
    getDoc,
    onSnapshot,
    query,
    setDoc,
} from "firebase/firestore";

import AppStore from "../stores/AppStore";
import AppApi from "./AppApi";
import { IOveride } from "../models/InventoryOveride";

export default class InventoryOverideApi {
    collectionRef: CollectionReference;
    constructor(
        private api: AppApi,
        private store: AppStore,
        collectionRef: CollectionReference
    ) {
        this.collectionRef = collectionRef;
    }

    async getAll() {
        const q = query(this.collectionRef);

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const items: IOveride[] = [];
            querySnapshot.forEach((doc) => {
                items.push({ id: doc.id, ...doc.data() } as IOveride);
            });

            this.store.inventory.overide.load(items);
        });

        return unsubscribe;
    }

    async getSupplier(id: string) {
        const docSnap = await getDoc(doc(this.collectionRef, id));
        if (docSnap.exists()) {
            const product = { ...docSnap.data(), id: docSnap.id } as IOveride;
            await this.store.inventory.overide.load([product]);
            return product;
        } else return undefined;
    }

    async create(data: IOveride) {
        const docRef = doc(this.collectionRef);
        data.id = docRef.id;
        await setDoc(docRef, data, { merge: true });
        return data;
    }

    async update(product: IOveride) {
        await setDoc(doc(this.collectionRef, product.id), product, {
            merge: true,
        });
        return product;
    }

    async delete(id: string) {
        const docRef = doc(this.collectionRef, id);
        await deleteDoc(docRef);
        this.store.inventory.overide.remove(id);
        window.location.reload();
    }
}
