import { makeAutoObservable } from "mobx";
import AppStore from "../stores/AppStore";
import IPayrollEmployee, {
  IBankAccount,
  IFixedPaymentInput,
  IFlexiPaymentInput,
  IPaymentSummary,
} from "../interfaces/IPayrollEmployee";

// export class PayrollEmployee {
//   private employee: IPayrollEmployee;

//   constructor(private store: AppStore, employee: IPayrollEmployee) {
//     makeAutoObservable(this);
//     this.employee = employee;
//   }

//   get asJson(): IPayrollEmployee {
//     return toJS(this.employee);
//   }
// }

export class PayrollEmployee implements IPayrollEmployee {
  uid: string;
  firstName: string;
  lastName: string;
  salaryGrade: string;
  position: string;
  departmentId: string;
  departmentName: string;
  employmentDate: number | null;
  identityNo: string;
  payrollMonth: number | null;
  payrollId: string;
  isExcluded: boolean;
  bankAccount: IBankAccount;
  paySummary: IPaymentSummary;
  fixedSalary: IFixedPaymentInput[];
  flexiSalary: IFlexiPaymentInput[];
  fixedBonus: IFixedPaymentInput[];
  flexiBonus: IFlexiPaymentInput[];
  fixedDeductions: IFixedPaymentInput[];
  flexiDeductions: IFlexiPaymentInput[];
  fixedExpenses: IFixedPaymentInput[];
  flexiExpenses: IFlexiPaymentInput[];
  regionId: string;
  regionName: string;

  constructor(private store: AppStore, payroll: IPayrollEmployee) {
    makeAutoObservable(this);
    this.uid = payroll.uid;
    this.firstName = payroll.firstName;
    this.lastName = payroll.lastName;
    this.salaryGrade = payroll.salaryGrade;
    this.position = payroll.position;
    this.departmentId = payroll.departmentId;
    this.departmentName = payroll.departmentName;
    this.employmentDate = payroll.employmentDate;
    this.payrollMonth = payroll.payrollMonth;
    this.identityNo = payroll.identityNo;
    this.bankAccount = payroll.bankAccount;
    this.paySummary = payroll.paySummary;
    this.fixedSalary = payroll.fixedSalary;
    this.fixedBonus = payroll.fixedBonus;
    this.fixedDeductions = payroll.fixedDeductions;
    this.fixedExpenses = payroll.fixedExpenses;
    this.flexiSalary = payroll.flexiSalary;
    this.flexiBonus = payroll.flexiBonus;
    this.flexiDeductions = payroll.flexiDeductions;
    this.flexiExpenses = payroll.flexiExpenses;
    this.payrollId = payroll.payrollId;
    this.isExcluded = payroll.isExcluded;
    this.regionId = payroll.regionId;
    this.regionName = payroll.regionName;
  }

  get asJson(): IPayrollEmployee {
    return {
      uid: this.uid,
      firstName: this.firstName,
      lastName: this.lastName,
      salaryGrade: this.salaryGrade,
      position: this.position,
      isExcluded: this.isExcluded,
      departmentId: this.departmentId,
      departmentName: this.departmentName,
      employmentDate: this.employmentDate,
      payrollMonth: this.payrollMonth,
      identityNo: this.identityNo,
      bankAccount: this.bankAccount,
      paySummary: this.paySummary,
      fixedSalary: this.fixedSalary,
      fixedBonus: this.fixedBonus,
      fixedDeductions: this.fixedDeductions,
      fixedExpenses: this.fixedExpenses,
      flexiSalary: this.flexiSalary,
      flexiBonus: this.flexiBonus,
      flexiDeductions: this.flexiDeductions,
      flexiExpenses: this.flexiExpenses,
      payrollId: this.payrollId,
      regionId: this.regionId,
      regionName: this.regionName,
    };
  }
}
