import { observer } from "mobx-react-lite";
import { useAppContext } from "../../../shared/functions/Context";
import { OnLeaveTable, GoingOnLeaveTable } from "./LeaveTable";

interface IToolBar {
  title: string;
}

const ToolBar = (props: IToolBar) => {

  const { title } = props
  return (
    <div className="section-toolbar uk-margin">
      <h4 className="section-heading uk-heading">{title}</h4>
    </div>
  );
};

const WhoIsOnLeave = observer(() => {
  const { store } = useAppContext();

  return (
    <>
      <div className="who-is-on-leave-section">
        <ToolBar title={"Who's on leave "} />
        <OnLeaveTable requests={store.leave.requests.all} />
      </div>
      <div className="who-is-on-leave-section">
        <ToolBar title={"Who's going on leave "} />
        <GoingOnLeaveTable requests={store.leave.requests.all} />
      </div>
    </>

  );
});

export default WhoIsOnLeave;
