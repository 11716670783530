import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Portal from "../../../shared/components/Portal";
import { useAppContext } from "../../../shared/functions/Context";
import IPayrollEmployee, { IFixedPaymentInput, IFlexiPaymentInput } from "../../../shared/interfaces/IPayrollEmployee";
import { FailedAction, SuccessfulAction } from "../../../shared/models/Snackbar";
import PaymentDetailSection from "./PaymentDetailSection";

interface IProps {
  employee: IPayrollEmployee;
  setEmployee: React.Dispatch<React.SetStateAction<IPayrollEmployee | undefined>>;
}
const PaymentDetails = observer((props: IProps) => {
  const { employee, setEmployee } = props;
  const { api, ui } = useAppContext();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);

  const [SaveButtonElement, setSaveButtonElement] = useState<Element | null>(null);

  const [flexiSalary, setFlexiSalary] = useState<IFlexiPaymentInput[]>([]);
  const [fixedSalary, setFixedSalary] = useState<IFixedPaymentInput[]>([]);

  const [flexiBonus, setFlexiBonus] = useState<IFlexiPaymentInput[]>([]);
  const [fixedBonus, setFixedBonus] = useState<IFixedPaymentInput[]>([]);

  const [flexiDeductions, setFlexiDeductions] = useState<IFlexiPaymentInput[]>([]);
  const [fixedDeductions, setFixedDeductions] = useState<IFixedPaymentInput[]>([]);

  const [flexiExpenses, setFlexiExpenses] = useState<IFlexiPaymentInput[]>([]);
  const [fixedExpenses, setFixedExpenses] = useState<IFixedPaymentInput[]>([]);

  const onSave = async () => {
    setLoading(true);
    const $employee: IPayrollEmployee = {
      ...employee,
      fixedSalary,
      flexiSalary,
      fixedDeductions,
      flexiDeductions,
    };
    try {
      await api.payroll.employee.update($employee.payrollId, $employee);
      SuccessfulAction(ui);

    } catch (error) {
      FailedAction(ui);
    }
    setLoading(false);
  };

  const onDelete = async () => {
    if (!window.confirm("Delete employee from payroll ?")) return;
    try {
      setDeleteLoader(true);
      await api.payroll.employee.delete(employee.payrollId, employee.uid);
      SuccessfulAction(ui);
    } catch (error) {
      FailedAction(ui);
    }
    setDeleteLoader(false);
    navigate("/c/payroll/year/");
  };

  useEffect(() => {
    const $employee: IPayrollEmployee = {
      ...employee,
      fixedSalary,
      flexiSalary,
      fixedBonus,
      flexiBonus,
      fixedDeductions,
      flexiDeductions,
      flexiExpenses,
      fixedExpenses,
    };
    setEmployee({ ...$employee });
  }, [fixedSalary, flexiSalary, fixedBonus, flexiBonus, fixedDeductions, flexiDeductions, flexiExpenses, fixedExpenses]);


  useEffect(() => {
    setFlexiSalary(employee.flexiSalary);
    setFixedSalary(employee.fixedSalary);

    setFlexiBonus(employee.flexiBonus);
    setFixedBonus(employee.fixedBonus);

    setFixedDeductions(employee.fixedDeductions);
    setFlexiDeductions(employee.flexiDeductions);

    setFlexiExpenses(employee.flexiExpenses);
    setFixedExpenses(employee.fixedExpenses);

  }, []);

  // useEffect(() => {
  //   setFlexiSalary(employee.flexiSalary);
  //   setFixedSalary(employee.fixedSalary);
  //   setFlexiDeductions(employee.flexiDeductions);
  //   setFixedDeductions(employee.fixedDeductions);

  // }, [employee.fixedDeductions, employee.fixedSalary, employee.flexiDeductions, employee.flexiSalary]);

  useEffect(() => {
    setSaveButtonElement(document.getElementById("save-button-portal"));
  }, []);

  return (
    <div className="payment-details">
      <div className="salary-details uk-card-small uk-card-body">
        <PaymentDetailSection
          title="Earnings"
          flexiItems={flexiSalary}
          setFlexiItems={setFlexiSalary}
          fixedItems={fixedSalary}
          setFixedItems={setFixedSalary}
        />
      </div>
      <div className="bonus-details uk-card uk-card-default uk-card-small uk-card-body">
        <PaymentDetailSection
          title="Bonus"
          flexiItems={flexiBonus}
          setFlexiItems={setFlexiBonus}
          fixedItems={fixedBonus}
          setFixedItems={setFixedBonus}
        />
      </div>
      <div className="deductions-details uk-card-small uk-card-body">
        <PaymentDetailSection
          title="Deductions"
          flexiItems={flexiDeductions}
          setFlexiItems={setFlexiDeductions}
          fixedItems={fixedDeductions}
          setFixedItems={setFixedDeductions}
        />
      </div>
      {/* Expenses */}
      <div className="expenses-details uk-card uk-card-default uk-card-small uk-card-body">
        <PaymentDetailSection
          title="Expenses"
          flexiItems={flexiExpenses}
          setFlexiItems={setFlexiExpenses}
          fixedItems={fixedExpenses}
          setFixedItems={setFixedExpenses}
        />
      </div>
      {SaveButtonElement && (
        <Portal attachTo={SaveButtonElement}>
          <button
            className="uk-button danger uk-margin-small-right"
            onClick={onDelete}
          >
            Delete{deleteLoader && <div data-uk-spinner="ratio: .5"></div>}
          </button>
          <button
            className="uk-button primary uk-margin-small-right"
            onClick={onSave}
          >
            Save{loading && <div data-uk-spinner="ratio: .5"></div>}
          </button>
        </Portal>
      )}
    </div>
  );
});

export default PaymentDetails;



  // const onSave = async () => {
  //   setLoading(true);
  //   const $employee: IPayrollEmployee = {
  //     ...employee,
  //     fixedSalary,
  //     flexiSalary,
  //     fixedDeductions,
  //     flexiDeductions,
  //   };
  //   try {
  //     await api.payroll.employee.update($employee.payrollId, $employee);
  //     SuccessfulAction(ui);

  //   } catch (error) {
  //     FailedAction(ui);
  //   }
  //   setLoading(false);
  // };

  // const onDelete = async () => {
  //   if (!window.confirm("Delete employee from payroll ?")) return;
  //   try {
  //     setDeleteLoader(true);
  //     await api.payroll.employee.delete(employee.payrollId, employee.uid);
  //     SuccessfulAction(ui);
  //   } catch (error) {
  //     FailedAction(ui);
  //   }
  //   setDeleteLoader(false);
  //   navigate("/c/payroll/year/");
  // };

  // useEffect(() => {
  //   const $employee: IPayrollEmployee = {
  //     ...employee,
  //     fixedSalary,
  //     flexiSalary,
  //     fixedDeductions,
  //     flexiDeductions,
  //   };
  //   setEmployee({ ...$employee });
  //   return () => { };
  // }, [fixedDeductions, fixedSalary, flexiDeductions, flexiSalary, setEmployee, employee]);

  // useEffect(() => {
  //   setFlexiSalary(employee.flexiSalary);
  //   setFixedSalary(employee.fixedSalary);

  //   setFixedDeductions(employee.fixedDeductions);
  //   setFlexiDeductions(employee.flexiDeductions);

  //   return () => { };
  // }, [employee.fixedDeductions, employee.fixedSalary, employee.flexiDeductions, employee.flexiSalary]);

  // useEffect(() => {
  //   setSaveButtonElement(document.getElementById("save-button-portal"));
  //   return () => { };
  // }, []);