import Store from "../Store";
import AppStore from "../AppStore";
import {  runInAction } from "mobx";
import ICustomer from "../../interfaces/ICustomer";
import { Customer } from "../../models/Customer";

export default class CustomerStore extends Store<ICustomer, Customer> {
  items = new Map<string, Customer>();

  constructor(store: AppStore) {
    super(store);
    this.store = store;
  }

  load(items: ICustomer[] = []) {
    runInAction(() => {
      items.forEach((item) =>
        this.items.set(item.id, new Customer(this.store, item))
      );
    });
  }

  get allSortedByFirstname() {
    return Array.from(this.items.values()).sort((a, b) =>
      a.firstName.localeCompare(b.firstName)
    );
  }

  getById(id: string) {
    return this.items.get(id);
  }
}
