import React from "react";

interface IProps {
  isActive: boolean;
  title: string;
  description: string;
  onClick: () => void;
}
const TaxTab = (props: IProps) => {
  const { isActive, title, description, onClick } = props;
  const activeClass = isActive ? "active" : "";

  return (
    <div
      className={`tax-tab uk-card-small uk-card-body ${activeClass}`}
      onClick={onClick}
    >
      <div className="icon">
        <span data-uk-icon="icon: check"></span>
      </div>

      <h5 className="title">{title}</h5>
      <p className="description">{description}</p>
    </div>
  );
};

export default TaxTab;
