import React from "react";
import { observer } from "mobx-react-lite";
import IPayrollEmployee from "../../../shared/interfaces/IPayrollEmployee";

interface IProps {
  employee: IPayrollEmployee;
  setEmployee: React.Dispatch<
    React.SetStateAction<IPayrollEmployee | undefined>
  >;
}

const BankAccountDetails = observer((props: IProps) => {
  const { employee, setEmployee } = props;

  const onChange = (
    name:
      | "bankName"
      | "accountNumber"
      | "branchName"
      | "branchCode"
      | "accountType",
    value: string
  ) => {
    const bank = { ...employee.bankAccount };
    if (name === "bankName") {
      bank.bankName = value;
    }
    if (name === "accountNumber") {
      bank.accountNumber = value;
    }
    if (name === "branchName") {
      bank.branchName = value;
    }
    if (name === "branchCode") {
      bank.branchCode = value;
    }
    if (name === "accountType") {
      bank.accountType = value;
    }
    setEmployee({ ...employee, bankAccount: bank });
  };

  return (
    <div className="bank-account-details uk-card uk-card-body uk-card-small">
      <table className="uk-table uk-table-small uk-table-divider">
        <tbody>
          <tr>
            <td className="item">Bank Name</td>
            <td className="uk-text-right">
              <input
                className="uk-input uk-form-small"
                type="text"
                value={employee.bankAccount.bankName}
                name={"bankName"}
                onChange={(e) => onChange("bankName", e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td className="item">Account Number</td>
            <td className="uk-text-right">
              <input
                className="uk-input uk-form-small"
                type="text"
                value={employee.bankAccount.accountNumber}
                name={"accountNumber"}
                onChange={(e) => onChange("accountNumber", e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td className="item">Branch Name</td>
            <td className="uk-text-right">
              <input
                className="uk-input uk-form-small"
                type="text"
                value={employee.bankAccount.branchName}
                name={"branchName"}
                onChange={(e) => onChange("branchName", e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td className="item">Branch Code</td>
            <td className="uk-text-right">
              <input
                className="uk-input uk-form-small"
                type="text"
                value={employee.bankAccount.branchCode}
                name={"branchCode"}
                onChange={(e) => onChange("branchCode", e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td className="item">Account Type</td>
            <td className="uk-text-right">
              <input
                className="uk-input uk-form-small"
                type="text"
                value={employee.bankAccount.accountType}
                name={"accountType"}
                onChange={(e) => onChange("accountType", e.target.value)}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
});

export default BankAccountDetails;
