import WhoIsOnLeave from "./who-is-on-leave/WhoIsOnLeave";
import { observer } from "mobx-react-lite";
import useProfile from "../shared/hooks/useProfile";
import Loading from "../../shared/components/Loading";
import LeaveApplication from "./application/LeaveApplication";
import { useCallback, useEffect } from "react";
import { useAppContext } from "../../shared/functions/Context";
import ILeaveProfile from "../../shared/interfaces/ILeaveProfile";

interface ProfileCardProps {
  type: string;
  taken: number;
  left: number;
}
const ProfileCard = (props: ProfileCardProps) => {
  const { type, taken, left } = props;
  return (
    <div className="profile-card uk-card uk-card-small uk-card-body">
      <div className="type">{type}</div>
      <div className="days">
        <div className="days-taken">
          <div className="value">
            <p className="title">Taken</p>
            {taken} days
          </div>
        </div>
        <div className="days-left">
          <div className="value">
            <p className="title">Left</p>
            {left} days
          </div>
        </div>
      </div>
    </div>
  );
};

interface UserLeaveProfileProps {
  profile: ILeaveProfile;
}
const UserLeaveProfile = observer((props: UserLeaveProfileProps) => {
  const { store } = useAppContext();
  const { leavesTaken, departmentId } = props.profile;

  const commonLeaves = store.leave.settings.allCommonLeaves;
  const departmentLeaves = store.leave.settings.allDeparmentLeaves(departmentId);
  const leaveTypes = [...commonLeaves, ...departmentLeaves];

  const leaveAnalytics = leaveTypes.map((leaveType) => {
    return {
      id: leaveType.id,
      type: leaveType.type,
      days: leaveType.days,
      taken: leavesTaken.find((took) => took.type === leaveType.type)?.days || 0,
    };
  });
  return (
    <div
      className="uk-grid-small uk-child-width-1-3@m uk-child-width-1-4@l uk-margin"
      data-uk-grid
    >
      {leaveAnalytics.map((leave) => (
        <div key={leave.id}>
          <ProfileCard
            type={leave.type}
            taken={leave.taken}
            left={leave.days - leave.taken}
          />
        </div>
      ))}
    </div>
  );
});

const Apply = observer(() => {
  const { api } = useAppContext();
  const [profile, loadingProfile] = useProfile();

  // Get common leaves
  const load = useCallback(async () => {
    await api.leave.settings.getAll();
  }, [api.leave.settings]);

  useEffect(() => {
    load();
  }, [load]);

  if (loadingProfile) {
    return <Loading fullWidth={true} />;
  }

  return (
    <div className="uk-section">
      <div className="uk-container uk-container-large">
        <div className="my-profile-section">
          {profile && <UserLeaveProfile profile={profile} />}
        </div>
        <div className="leave-requests-page">
          {profile && <LeaveApplication profile={profile} />}
          <WhoIsOnLeave />
        </div>
      </div>
    </div>
  );
});

export default Apply;
