import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAppContext } from "../../../shared/functions/Context";
import showModalFromId from "../../../shared/functions/ModalShow";
import ILeaveRequest, { ILeaveStatus } from "../../../shared/interfaces/ILeaveRequest";
import DIALOG_NAMES from "../../dialogs/Dialogs";
import LeaveGrid from "./LeaveGrid";
import { defaultLeaveProfile } from "../../../shared/interfaces/ILeaveProfile";
import { FailedAction, SuccessfulAction } from "../../../shared/models/Snackbar";
import ApprovedRequestsItem from "./ApprovedRequestsItem";
import RejectedRequestsItem from "./RejectedRequestsItem";
import WhoIsOnLeave from "../who-is-on-leave/WhoIsOnLeave";

interface ToolBarProps {
  showDialog: () => void;
}
const ToolBar = (props: ToolBarProps) => {
  const { showDialog } = props;

  return (
    <div className="section-toolbar uk-margin">
      <h4 className="section-heading uk-heading">Leave Requests</h4>

      <div className="controls">
        <button className="uk-button primary" onClick={() => showDialog()}>
          Record Leave
        </button>
      </div>
    </div>
  );
};

interface TabsProps {
  setTab: React.Dispatch<React.SetStateAction<ILeaveStatus>>;
}
const Tabs = (props: TabsProps) => {
  const { setTab } = props;

  return (
    <ul className="tabs" data-uk-tab>
      <li className="tab-item uk-active">
        <a href="/#" onClick={() => setTab("PENDING")}>
          Pending
        </a>
      </li>
      <li className="tab-item">
        <a href="/#" onClick={() => setTab("APPROVED")}>
          Approved
        </a>
      </li>
      <li className="tab-item">
        <a href="/#" onClick={() => setTab("REJECTED")}>
          Rejected
        </a>
      </li>
    </ul>
  );
};

const LeaveRequests = observer(() => {
  const { api, ui, store } = useAppContext();
  const me = store.user.meJson
  const [tab, setTab] = useState<ILeaveStatus>("PENDING");
  const [loading, setLoading] = useState(true);

  const onApproveUpdateRequest = (request: ILeaveRequest) => {
    request.status = "APPROVED";
    return request;
  };

  const onApproveUpdateProfile = async (request: ILeaveRequest) => {

    const { userId, userRole, userName, departmentId, typeId, days, type, from, } = request;

    const id = `${userId}_${new Date(from).getFullYear()}`;
    const profile = await api.leave.profiles.getProfile(id);

    if (profile) {
      const leavesTaken = profile.leavesTaken;
      const leaveIndex = leavesTaken.findIndex((r) => r.type === type);

      if (leaveIndex !== -1)
        leavesTaken[leaveIndex].days = leavesTaken[leaveIndex].days + days;
      else
        leavesTaken.push({
          id: typeId,
          days: days,
          type: type,
        });

      profile.leavesTaken = leavesTaken;
      return profile;
    } else {
      const leavesTaken = [];
      leavesTaken.push({
        id: typeId,
        days: days,
        type: type,
      });

      const profile = {
        ...defaultLeaveProfile,
        id,
        userId,
        userName,
        userRole,
        departmentId,
      };

      profile.leavesTaken = leavesTaken;
      return profile;
    }
  };

  const onApprove = async (request: ILeaveRequest) => {
    if (!window.confirm("Approve leave?")) return;
    try {
      const $profile = await onApproveUpdateProfile(request);
      await api.leave.profiles.update($profile);
      SuccessfulAction(ui);
    } catch (error) {
      FailedAction(ui);
    }

    try {
      const $request = onApproveUpdateRequest(request);
      await api.mail.leaveUpdate(
        [request.email],
        `Your leave application for ${request.reasonForLeave} has been approved by ${me?.firstName, " ", me?.lastName}`,
        ""
      );
      await api.leave.requests.update($request);
    } catch (error) {
      FailedAction(ui);
    }
  };

  const onReject = (request: ILeaveRequest) => {
    showModalFromId(DIALOG_NAMES.LEAVE.REJECT_LEAVE);
    request.status = "REJECTED";
    store.leave.requests.select(request);
  };

  const onRecordLeave = () => {
    showModalFromId(DIALOG_NAMES.LEAVE.RECORD_LEAVE_DIALOG);
  };

  const pendingRequests = useMemo(() => store.leave.requests.allPending,
    [store.leave.requests.allPending]);

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      await api.leave.requests.getAll();
      await api.leave.settings.getAll();
      await api.leave.profiles.getAll();
      await api.auth.loadAll();
      setLoading(false);
    };
    loadData();
  }, [api.leave.requests, api.auth, api.leave.profiles, api.leave.settings]);


  return (
    <div className="uk-section leave-requests-page">
      <div className="uk-container uk-container-large">
        <ToolBar showDialog={onRecordLeave} />
        <Tabs setTab={setTab} />
        {tab === "PENDING" && (
          <>
            <LeaveGrid
              requests={pendingRequests}
              loading={loading}
              onApprove={onApprove}
              onReject={onReject}
            />
            <WhoIsOnLeave />
          </>
        )}
        {tab === "APPROVED" && (
          <ApprovedRequestsItem />
        )}
        {tab === "REJECTED" && (
          <RejectedRequestsItem />
        )}
      </div>
    </div>
  );
});

export default LeaveRequests;