import {
  CollectionReference,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  Unsubscribe,
  where,
} from "firebase/firestore";
import IPayroll from "../interfaces/IPayroll";
import AppStore from "../stores/AppStore";
import AppApi from "./AppApi";

export default class PayrollBatchApi {
  collectionRef: CollectionReference;

  constructor(
    private api: AppApi,
    private store: AppStore,
    collectionRef: CollectionReference
  ) {
    this.collectionRef = collectionRef;
  }

  async getByFolderId(folderId: string) {
    const $query = query(this.collectionRef, where("folderId", "==", folderId));
    return await new Promise<Unsubscribe>((resolve, reject) => {
      const unsubscribe = onSnapshot($query, (querySnapshot) => {
        const items: IPayroll[] = [];
        querySnapshot.forEach((doc) => {
          items.push({ id: doc.id, ...doc.data() } as IPayroll);
        });
        this.store.payroll.batch.load(items);
        resolve(unsubscribe);
      },
        (error) => {
          reject(error);
        }
      );
    });
  }

  async getAll() {
    const q = query(this.collectionRef, orderBy("deadlineMs", "desc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const items: IPayroll[] = [];
      querySnapshot.forEach((doc) => {
        items.push({ id: doc.id, ...doc.data() } as IPayroll);
      });
      this.store.payroll.batch.load(items);
    });
    return unsubscribe;
  }

  async getById(id: string) {
    const unsubscribe = onSnapshot(doc(this.collectionRef, id), (doc) => {
      const item: IPayroll = { id: doc.id, ...doc.data() } as IPayroll;
      this.store.payroll.batch.load([item]);
    });
    return unsubscribe;
  }

  async create(data: IPayroll) {
    const docRef = doc(this.collectionRef);
    data.id = docRef.id;
    await setDoc(docRef, data, { merge: true });
    return data;
  }

  async duplicate(data: IPayroll) {
    const copyFromBatchId = data.id;
    // Duplicate the batch
    const $data = await this.create({
      ...data,
      id: "",
      title: `${data.title} (copy)`,
      status: "draft",
      approval: [],
      amount: 0,
    });
    // Duplicate the employees
    const copyToBatchId = $data.id;
    await this.api.payroll.employee.duplicateAll(copyFromBatchId, copyToBatchId);
    return data;
  }

  async update(data: IPayroll) {
    const docRef = doc(this.collectionRef, data.id);
    await setDoc(docRef, data, { merge: true });
    this.store.payroll.batch.load([data]);
    return data;
  }

  async delete(id: string) {
    const docRef = doc(this.collectionRef, id);
    // Delete the batch
    await deleteDoc(docRef);

    // Remove payroll from store
    this.store.payroll.batch.remove(id);

    // Delete the employees
    await this.api.payroll.employee.deleteAll(id);
  }
}
