import Store from "../Store";
import AppStore from "../AppStore";
import { runInAction } from "mobx";
import InventoryProductCategory, { IInventoryProductCategory } from '../../models/InventoryProductCategory';

export default class InventoryProductCategoryStore extends Store<
  IInventoryProductCategory,
  InventoryProductCategory
> {
  items = new Map<string, InventoryProductCategory>();

  constructor(store: AppStore) {
    super(store);
    this.store = store;
  }

  load(items: IInventoryProductCategory[] = []) {
    runInAction(() => {
      items.forEach((item) =>
        this.items.set(item.id, new InventoryProductCategory(this.store, item))
      );
    });
  }
}