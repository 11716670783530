import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../shared/functions/Context";
import showModalFromId from "../../../shared/functions/ModalShow";
import IPayroll, { defaultPayroll } from "../../../shared/interfaces/IPayroll";
import DIALOG_NAMES from "../../dialogs/Dialogs";
import EmployeesList from "./EmployeesList";
import { PayrollTabs } from "./payroll-tab/PayrollTabs";
import { ApprovalToolBar } from "./payroll-toolbars/ApprovalToolBar";
import { DraftToolBar } from "./payroll-toolbars/DraftToolBar";
import { RunToolBar } from "./payroll-toolbars/RunToolBar";
import { FailedAction, SuccessfulAction } from "../../../shared/models/Snackbar";
import ErrorBoundary from "../../shared/error-boundary/ErrorBoundary";

const ViewPayroll = observer(() => {

  const { api, store, ui } = useAppContext();
  const navigate = useNavigate();

  const [activeStage, setactiveStage] = useState<"draft" | "approval" | "run">("draft");

  const [payroll, setPayroll] = useState<IPayroll>({ ...defaultPayroll });
  const [loading, setLoading] = useState(false);
  const [including, setIncluding] = useState(false);

  const employees = store.payroll.employee.allSortedByDisplayName;
  const exclusives = employees.filter((emp) => emp.isExcluded === true);
  const inclusivesLength = (employees.length - exclusives.length);
  const payrollId = payroll.id;

  const onNewEmployee = async () => {
    try {
      await api.department.getAll();
      await api.region.getAll();
    } catch (error) { }
    finally {
      showModalFromId(DIALOG_NAMES.PAYROLL.NEW_PAYROLL_EMPLOYEE_DIALOG);
    }
  };

  const openApprovalDialog = () => {
    showModalFromId(DIALOG_NAMES.PAYROLL.PAYROLL_APPROVAL_DIALOG);
  };

  const openRunDialog = () => {
    showModalFromId(DIALOG_NAMES.PAYROLL.RUN_PAYROLL_DIALOG);
  };

  const navigateBack = () => {
    navigate(`/c/payroll/fy/${payroll.folderId}`);
  };
  const onDraft = () => {
    setactiveStage("draft");
  };

  const onApproval = () => {
    setactiveStage("approval");
  };

  const onRun = () => {
    setactiveStage("run");
  };

  const includeAll = async () => {
    setIncluding(true)

    try {
      for (const employee of exclusives) {
        await api.payroll.employee.update(payroll.id, { ...employee.asJson, isExcluded: false, payrollId });
      }
      SuccessfulAction(ui);
    } catch (error) {
      FailedAction(ui);
    }
    setIncluding(false)
  };


  useEffect(() => {

    const loadFromDB = async () => {
      setLoading(true);
      try {
        await api.payroll.employee.getAll(payroll.id);
      } catch (error) {
        setLoading(false);
      }
    };
    loadFromDB();

    setLoading(false);
  }, [api.payroll.employee, payroll.id]);

  useEffect(() => {
    if (store.payroll.batch.selected)
      setPayroll(store.payroll.batch.selected);
    else navigate(`/c/payroll/fy/${payroll.folderId}`);
  }, [store.payroll.batch.selected, navigate, payroll.folderId]);

  return (
    <ErrorBoundary>
      <div className="uk-section view-payroll-page">
        <div className="uk-container uk-container-large">
          <PayrollTabs
            activeStage={activeStage}
            status={payroll.status}
            onDraft={onDraft}
            onApproval={onApproval}
            onRun={onRun}
          />
          <div className="uk-margin">
            {activeStage === "draft" && (
              <DraftToolBar
                including={including}
                title={payroll.title}
                status={payroll.status}
                navigateBack={navigateBack}
                onNewEmployee={onNewEmployee}
                includeAll={includeAll}
              />
            )}
            {activeStage === "approval" && (
              <ApprovalToolBar
                title={payroll.title}
                status={payroll.status}
                navigateBack={navigateBack}
                openApprovalDialog={openApprovalDialog}
                inclusivesLength={inclusivesLength}
              />
            )}
            {activeStage === "run" && (
              <RunToolBar
                title={payroll.title}
                status={payroll.status}
                navigateBack={navigateBack}
                openRunDialog={openRunDialog}
              />
            )}
          </div>
          <EmployeesList
            payrollId={payroll.id}
            employees={employees}
            status={payroll.status}
            loading={loading}
          />
        </div>
      </div>
    </ErrorBoundary>
  );
});

export default ViewPayroll;

