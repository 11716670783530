import { observer } from "mobx-react-lite";
import { Fragment } from "react";
import Loading from "../../../shared/components/Loading";
import { dateFormat } from "../../../shared/functions/TimestampToDate";
import { LeaveRequest } from "../../../shared/models/LeaveRequest";

interface RequestProps {
  index: number;
  request: LeaveRequest;
}
const RequestRow = (props: RequestProps) => {
  const { index, request } = props;
  const { id, userName, userRole, from, to, days } = request;
  // const days = Math.floor(((to - from) / (1000 * 60 * 60 * 24)) % 7);

  return (
    <>
      <tr className="row" key={id}>
        <td className="no">{index + 1}</td>
        <td className="user">
          <p>{userName}</p>
          <p>{userRole}</p>
        </td>
        <td className="from">{dateFormat(from)}</td>
        <td className="to">{dateFormat(to)}</td>
        <td className="days">{days}</td>
      </tr>
    </>
  );
};

interface IProps {
  requests: LeaveRequest[];
  loading: boolean;
}

export const OnLeaveTable = observer((props: IProps) => {
  const { requests, loading } = props;
  let current = new Date().getTime();

  const onLeave = requests.filter((req) => req.from <= current && req.to >= current && req.status === "APPROVED");

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      {onLeave.length !== 0 && (
        <div className="uk-card uk-card-default uk-card-body uk-card-small uk-margin">
          <div className="uk-overflow-auto">
            <table className="leave-table uk-table uk-table-small uk-table-divider uk-table-middle uk-table-responsive">
              <thead className="table-header">
                <tr>
                  <th>#</th>
                  <th>User</th>
                  <th className="uk-table-expand">From</th>
                  <th className="uk-table-expand">To</th>
                  <th className="uk-table-expand">Days</th>
                </tr>
              </thead>
              <tbody className="table-body">
                {onLeave.map((request, index) => (
                  <Fragment key={request.id}>
                    <RequestRow index={index} request={request} />
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {onLeave.length === 0 && (
        <div className="no-orders">
          <p className="uk-text-center">
            Empty (nobody is on leave) <span>😔</span>
          </p>
        </div>
      )}
    </div>
  );
});

export const GoingOnLeaveTable = observer((props: IProps) => {
  const { requests, loading } = props;
  let current = new Date().getTime();

  const goingOnLeave = requests.filter((req) => req.from > current && req.status === "APPROVED");

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      {goingOnLeave.length !== 0 && (
        <div className="uk-card uk-card-default uk-card-body uk-card-small uk-margin">
          <div className="uk-overflow-auto">
            <table className="leave-table uk-table uk-table-small uk-table-divider uk-table-middle uk-table-responsive">
              <thead className="table-header">
                <tr>
                  <th>#</th>
                  <th>User</th>
                  <th className="uk-table-expand">From</th>
                  <th className="uk-table-expand">To</th>
                  <th className="uk-table-expand">Days</th>
                </tr>
              </thead>
              <tbody className="table-body">
                {goingOnLeave.map((request, index) => (
                  <Fragment key={request.id}>
                    <RequestRow index={index} request={request} />
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {goingOnLeave.length === 0 && (
        <div className="no-orders">
          <p className="uk-text-center">
            Empty (nobody is going on leave) <span>😔</span>
          </p>
        </div>
      )}
    </div>
  );
});
