import { collection } from "firebase/firestore";
import { db } from "../database/FirebaseConfig";
import AppStore from "../stores/AppStore";
import AppApi from "./AppApi";
import BenefitsApi from "./BenefitsApi";
import PayrollBatchApi from "./PayrollBatchApi";
import PayrollEmployeeApi from "./PayrollEmployeeApi";
import TaxRateApi from "./TaxRateApi";

export default class PayrollApi {
  private payrollDB = collection(db, "Payroll");
  private employeeSubCollectionId = "Employees";
  private taxRatesDB = collection(db, "Tax-rates");
  private benefitsDB = collection(db, "Benefits");

  batch: PayrollBatchApi;
  employee: PayrollEmployeeApi;
  taxRate: TaxRateApi;
  benefits: BenefitsApi;

  constructor(api: AppApi, store: AppStore) {
    this.batch = new PayrollBatchApi(api, store, this.payrollDB);
    this.employee = new PayrollEmployeeApi(api, store, this.payrollDB, this.employeeSubCollectionId);
    this.taxRate = new TaxRateApi(api, store, this.taxRatesDB);
    this.benefits = new BenefitsApi(api, store, this.benefitsDB);
  }
}
