import { observer } from "mobx-react-lite";
import { FormEvent, useEffect, useState } from "react";
import { useAppContext } from "../../../shared/functions/Context";
import { hideModalFromId } from "../../../shared/functions/ModalShow";
import { defaultSupplier, ISupplier } from "../../../shared/models/InventorySupplier";
import DIALOG_NAMES from "../Dialogs";

const SupplierDialog = observer(() => {
    const { api, store, ui } = useAppContext();
    const [loading, setLoading] = useState(false);


    const [supplier, setSupplier] = useState<ISupplier>({
        ...defaultSupplier,
    });

    const defaultSupp: ISupplier = {
        supplierName: "",
        registrationNo: "",
        vatRegistration: "",
        contactNumber: "",
        contactEmail: "",
        id: "",
        businessAddress: "",
        postalAddress: "",
        bankName: "",
        bankBranch: "",
        bankBranchNo: "",
        accountName: "",
        industry: ""
    };

    const resetSupplier = () => {
        setSupplier({ ...defaultSupp });
    };



    const onSave = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);

        // Update API
        try {
            if (store.inventory.supplier.selected) {
                const supp = await api.inventory.suppliers.update(supplier);
                if (supp) await store.inventory.supplier.load([supp]);
                ui.snackbar.load({
                    id: Date.now(),
                    message: "supplier updated!",
                    type: "success",
                });
            } else {
                await api.inventory.suppliers.create(supplier);
                // if (supp) await store.inventory.supplier.load([supp]);
                ui.snackbar.load({
                    id: Date.now(),
                    message: "supplier created!",
                    type: "success",
                });
            }
            // resetSupplier();
        } catch (error) {
            ui.snackbar.load({
                id: Date.now(),
                message: "Error! Failed to update supplier.",
                type: "danger",
            });
        }

        store.inventory.supplier.clearSelected();
        setLoading(false);
        hideModalFromId(DIALOG_NAMES.INVENTORY.CREATE_SUPPLIER_DIALOG);
    };

    const clear = () => {
        store.inventory.supplier.clearSelected();
    }



    useEffect(() => {
        if (store.inventory.supplier.selected) setSupplier(store.inventory.supplier.selected);
        else setSupplier({ ...defaultSupplier });
        return () => { };
    }, [store.inventory.supplier.selected]);

    return (
        <div className="uk-modal-dialog uk-modal-body margin-auto-vertical">
            <button
                className="uk-modal-close-default"
                type="button"
                data-uk-close
                onClick={clear}
            >
                
            </button>

            <h3 className="uk-modal-title">Supplier</h3>

            <div className="dialog-content uk-position-relative">
                <div className="reponse-form">
                    <form className="uk-form-stacked" onSubmit={onSave}>
                        <div className="uk-grid-small uk-child-width-1-1@m" data-uk-grid>
                            <h5 style={{ textTransform: "uppercase" }}>Supplier Details</h5>
                            <div className="uk-width-1-2@m">
                                <label className="uk-form-label" htmlFor="form-stacked-text">
                                    Supplier Name
                                </label>
                                <div className="uk-form-controls">
                                    <input
                                        className="uk-input uk-form-small"
                                        type="text"
                                        // placeholder="supplier name"
                                        value={supplier.supplierName}
                                        onChange={(e) =>
                                            setSupplier({ ...supplier, supplierName: e.target.value })
                                        }
                                        required
                                    />
                                </div>
                            </div>
                            <div className="uk-width-1-2@m">
                                <label className="uk-form-label" htmlFor="form-stacked-text">
                                    Industry
                                </label>
                                <div className="uk-form-controls">
                                    <input
                                        className="uk-input uk-form-small"
                                        type="text"
                                        // placeholder="supplier name"
                                        value={supplier.industry}
                                        onChange={(e) =>
                                            setSupplier({ ...supplier, industry: e.target.value })
                                        }
                                        required
                                    />
                                </div>
                            </div>
                            <div className="uk-width-1-2@m">
                                <label className="uk-form-label" htmlFor="form-stacked-text">
                                    Registration Number
                                </label>
                                <div className="uk-form-controls">
                                    <input
                                        className="uk-input uk-form-small"
                                        type="text"
                                        // placeholder="supplier name"
                                        value={supplier.registrationNo}
                                        onChange={(e) =>
                                            setSupplier({ ...supplier, registrationNo: e.target.value })
                                        }
                                        required
                                    />
                                </div>
                            </div>
                            <div className="uk-width-1-2@m">
                                <label className="uk-form-label" htmlFor="form-stacked-text">
                                    Vat Registration
                                </label>
                                <div className="uk-form-controls">
                                    <input
                                        className="uk-input uk-form-small"
                                        type="text"
                                        // placeholder="supplier name"
                                        value={supplier.vatRegistration}
                                        onChange={(e) =>
                                            setSupplier({ ...supplier, vatRegistration: e.target.value })
                                        }
                                        required
                                    />
                                </div>
                            </div>
                            <div className="uk-width-1-2@m">
                                <label className="uk-form-label" htmlFor="form-stacked-text">
                                    Contact Number
                                </label>
                                <div className="uk-form-controls">
                                    <input
                                        className="uk-input uk-form-small"
                                        type="text"
                                        // placeholder="supplier name"
                                        value={supplier.contactNumber}
                                        onChange={(e) =>
                                            setSupplier({ ...supplier, contactNumber: e.target.value })
                                        }
                                        required
                                    />
                                </div>
                            </div>
                            <div className="uk-width-1-2@m">
                                <label className="uk-form-label" htmlFor="form-stacked-text">
                                    Contact Email
                                </label>
                                <div className="uk-form-controls">
                                    <input
                                        className="uk-input uk-form-small"
                                        type="text"
                                        // placeholder="supplier name"
                                        value={supplier.contactEmail}
                                        onChange={(e) =>
                                            setSupplier({ ...supplier, contactEmail: e.target.value })
                                        }
                                        required
                                    />
                                </div>
                            </div>
                            <div className="uk-width-1-2@m">
                                <label className="uk-form-label" htmlFor="form-stacked-text">
                                    Business Address
                                </label>
                                <div className="uk-form-controls">
                                    <textarea
                                        className="uk-input uk-form-small"
                                        // placeholder="supplier name"
                                        value={supplier.businessAddress}
                                        onChange={(e) =>
                                            setSupplier({ ...supplier, businessAddress: e.target.value })
                                        }
                                        required
                                    />
                                </div>
                            </div>

                            <div className="uk-width-1-2@m">
                                <label className="uk-form-label" htmlFor="form-stacked-text">
                                    Postal Address
                                </label>
                                <div className="uk-form-controls">
                                    <textarea
                                        className="uk-input uk-form-small"
                                        // placeholder="supplier name"
                                        value={supplier.postalAddress}
                                        onChange={(e) =>
                                            setSupplier({ ...supplier, postalAddress: e.target.value })
                                        }
                                        required
                                    />
                                </div>
                            </div>
                            <h5 style={{ textTransform: "uppercase" }}>Banking Details</h5>
                            <div className="uk-width-1-2@m">
                                <label className="uk-form-label" htmlFor="form-stacked-text">
                                    Bank Name
                                </label>
                                <div className="uk-form-controls">
                                    <input
                                        className="uk-input uk-form-small"
                                        type="text"
                                        // placeholder="supplier name"
                                        value={supplier.bankName}
                                        onChange={(e) =>
                                            setSupplier({ ...supplier, bankName: e.target.value })
                                        }
                                        required
                                    />
                                </div>
                            </div>
                            <div className="uk-width-1-2@m">
                                <label className="uk-form-label" htmlFor="form-stacked-text">
                                    Bank Branch
                                </label>
                                <div className="uk-form-controls">
                                    <input
                                        className="uk-input uk-form-small"
                                        type="text"
                                        // placeholder="supplier name"
                                        value={supplier.bankBranch}
                                        onChange={(e) =>
                                            setSupplier({ ...supplier, bankBranch: e.target.value })
                                        }
                                        required
                                    />
                                </div>
                            </div>
                            <div className="uk-width-1-2@m">
                                <label className="uk-form-label" htmlFor="form-stacked-text">
                                    Bank Branch Number
                                </label>
                                <div className="uk-form-controls">
                                    <input
                                        className="uk-input uk-form-small"
                                        type="text"
                                        // placeholder="supplier name"
                                        value={supplier.bankBranchNo}
                                        onChange={(e) =>
                                            setSupplier({ ...supplier, bankBranchNo: e.target.value })
                                        }
                                        required
                                    />
                                </div>
                            </div>
                            <div className="uk-width-1-2@m">
                                <label className="uk-form-label" htmlFor="form-stacked-text">
                                    Account Number
                                </label>
                                <div className="uk-form-controls">
                                    <input
                                        className="uk-input uk-form-small"
                                        type="text"
                                        // placeholder="supplier name"
                                        value={supplier.accountName}
                                        onChange={(e) =>
                                            setSupplier({ ...supplier, accountName: e.target.value })
                                        }
                                        required
                                    />
                                </div>
                            </div>

                            <div className="footer uk-margin">
                                <button className="uk-button secondary uk-modal-close">
                                    Cancel
                                </button>
                                <button className="uk-button primary" type="submit">
                                    Save
                                    {loading && <div data-uk-spinner="ratio: .5"></div>}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
});

export default SupplierDialog;
