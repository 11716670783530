import { makeAutoObservable, toJS } from "mobx";
import AppStore from "../stores/AppStore";

export const defaultInventoryProduct: IInventoryProduct = {
  id: "",
  productCategory: "",
  sku: "", // barcode number
  productName: "",
  productColor: "",
  productSize: "",
  unitOfMeasurement: "",
  quantityOnHand: 0,
  productDescription: "",
  description: [],
  price: 0,
  sellingPrice: 0,
  supplier: "",
  isNonTaxable: false
};

export interface IInventoryProduct {
  id: string;
  productCategory: string,
  sku: string, // barcode number
  productName: string,
  productColor: string,
  productSize: string,
  unitOfMeasurement: string,
  quantityOnHand: number,
  productDescription: string,
  description: IProductDescriptionFormItems[],
  price: number,
  sellingPrice: number,
  supplier: string,
  isNonTaxable: boolean
}

/* sub interfaces used to create multiple duty stations start here */
export const defaultProductDescriptionForm: IProductDescriptionForm = {
  id: "",
  description: []
};

export interface IProductDescriptionForm {
  id: string;
  description: IProductDescriptionFormItems[];
}

export const defaultProductDescriptionFormItems: IProductDescriptionFormItems = {
  description: "",
  value: "",
};

export interface IProductDescriptionFormItems {
  description: string;
  value: string;
}

export default class InventoryProduct {
  private _inventoryProduct: IInventoryProduct;

  constructor(private store: AppStore, InventoryProduct: IInventoryProduct) {
    makeAutoObservable(this);
    this._inventoryProduct = InventoryProduct;
  }

  get asJson(): IInventoryProduct {
    return toJS(this._inventoryProduct);
  }

  get category() {
    return this.store.inventory.categories.getById(this._inventoryProduct.productCategory);
  }
  get supplier() {
    return this.store.inventory.supplier.getById(this._inventoryProduct.supplier);
  }
}