import { observer } from "mobx-react-lite";
import { ChangeEvent } from "react";

interface IProps {
    index: number;
    description: string;
    value: string;
    onChange: (index: number) => (e: ChangeEvent<HTMLInputElement>) => void;
    onRemove: (index: number) => void;
}

const ProductDescriptionItem = observer((props: IProps) => {
    const { index, description, value, onChange, onRemove } = props;

    return (
        <div className="product-description-item uk-grid uk-grid-small" data-uk-grid>
            <div className="uk-card uk-card-default uk-width-1-1">
                <div className="uk-grid uk-grid-small uk-padding-small" data-uk-grid>
                    <div className="uk-form-controls uk-width-2-5">
                        <label className="uk-form-label required" htmlFor="">Description</label>
                        <input className="uk-input uk-form-small" type="text" value={description} name="description" onChange={onChange(index)} required />
                    </div>
                    <div className="uk-form-controls uk-width-2-5">
                        <label className="uk-form-label" htmlFor="">Value</label>
                        <input className="uk-input uk-form-small" value={value} name="value" onChange={onChange(index)} />
                    </div>
                    <div className="uk-width-expand checkdel">
                        <div className="">
                            <div className="uk-margin-top icon">
                                <span
                                    data-uk-tooltip="uk-background-danger Delete item"
                                    onClick={() => onRemove(index)}
                                    data-uk-icon="icon: trash;"
                                ></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default ProductDescriptionItem;
