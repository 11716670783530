import { collection } from "firebase/firestore";
import { db } from "../database/FirebaseConfig";
import AppStore from "../stores/AppStore";
import AppApi from "./AppApi";
import CustomerApi from "./CustomerApi";
import InvoiceAPi from "./InvoiceApi";

export default class InvoicingApi {
  private customerDB = collection(db, "Customers");
  private invoiceSubCollectionId = "Invoices";

  customer: CustomerApi;
  invoice: InvoiceAPi;

  constructor(api: AppApi, store: AppStore) {
    this.customer = new CustomerApi(api, store, this.customerDB);
    this.invoice = new InvoiceAPi(api, store, this.customerDB, this.invoiceSubCollectionId);
  }
}
