import {
  CollectionReference,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore";
import ITaxRate from "../interfaces/ITaxRate";
import AppStore from "../stores/AppStore";
import AppApi from "./AppApi";

export default class TaxRateApi {
  collectionRef: CollectionReference;

  constructor(
    private api: AppApi,
    private store: AppStore,
    collectionRef: CollectionReference
  ) {
    this.collectionRef = collectionRef;
  }

  async getAll() {
    const q = query(this.collectionRef, orderBy("taxableIncomeMin", "asc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const items: ITaxRate[] = [];
      querySnapshot.forEach((doc) => {
        items.push({ id: doc.id, ...doc.data() } as ITaxRate);
      });
      this.store.payroll.taxRate.load(items);
    });
    return unsubscribe;
  }

  async getById(id: string) {
    const unsubscribe = await onSnapshot(doc(this.collectionRef, id), (doc) => {
      const item: ITaxRate = { id: doc.id, ...doc.data() } as ITaxRate;
      this.store.payroll.taxRate.load([item]);
    });
    return unsubscribe;
  }

  async create(data: ITaxRate) {
    const docRef = doc(this.collectionRef);
    data.id = docRef.id;
    await setDoc(docRef, data, { merge: true });
    return data;
  }

  async update(data: ITaxRate) {
    const docRef = doc(this.collectionRef, data.id);
    await setDoc(docRef, data, { merge: true });
    return data;
  }

  async delete(id: string) {
    const docRef = doc(this.collectionRef, id);
    await deleteDoc(docRef);
    this.store.payroll.taxRate.remove(id);
  }
}
