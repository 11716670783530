import { makeAutoObservable } from "mobx";
import AppStore from "../stores/AppStore";
import IInvoice, {
  ICustomerDetail,
  IFlexiInvoiceHeadInput,
  IFlexiInvoiceItemInput,
  IInvoiceStatus,
} from "../interfaces/IInvoice";

export class Invoice implements IInvoice {
  id: string;
  customerId: string;
  invoiceName: string;
  description: string;
  companyImgUrl: string;
  proofOfPayment: string;
  invoiceDate: string;
  paymentDate: string;
  customer: ICustomerDetail;
  subTotal: number;
  total: number;
  paymentDateMs: number;
  amountDue: number;
  amountPayed: number;
  invoiceItem: IFlexiInvoiceItemInput[];
  invoiceHead: IFlexiInvoiceHeadInput;
  invoiceStatus: IInvoiceStatus;
  invoiceDiscount: number;

  constructor(private store: AppStore, invoice: IInvoice) {
    makeAutoObservable(this);
    this.id = invoice.id;
    this.customerId = invoice.customerId;
    this.invoiceName = invoice.invoiceName;
    this.description = invoice.description;
    this.companyImgUrl = invoice.companyImgUrl;
    this.proofOfPayment = invoice.proofOfPayment;
    this.customer = invoice.customer;
    this.invoiceDate = invoice.invoiceDate;
    this.paymentDate = invoice.paymentDate;
    this.subTotal = invoice.subTotal;
    this.total = invoice.total;
    this.paymentDateMs = invoice.paymentDateMs;
    this.amountDue = invoice.amountDue;
    this.amountPayed = invoice.amountPayed;
    this.invoiceItem = invoice.invoiceItem;
    this.invoiceHead = invoice.invoiceHead;
    this.invoiceStatus = invoice.invoiceStatus;
    this.invoiceDiscount = invoice.invoiceDiscount;
  }

  get asJson(): IInvoice {
    return {
      id: this.id,
      customerId: this.customerId,
      invoiceName: this.invoiceName,
      description: this.description,
      companyImgUrl: this.companyImgUrl,
      proofOfPayment: this.proofOfPayment,
      invoiceDate: this.invoiceDate,
      paymentDate: this.paymentDate,
      customer: this.customer,
      subTotal: this.subTotal,
      total: this.total,
      paymentDateMs: this.paymentDateMs,
      amountDue: this.amountDue,
      amountPayed: this.amountPayed,
      invoiceItem: this.invoiceItem,
      invoiceHead: this.invoiceHead,
      invoiceStatus: this.invoiceStatus,
      invoiceDiscount: this.invoiceDiscount,
    };
  }
}
