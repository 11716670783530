import { runInAction } from "mobx";
import AppStore from "../AppStore";
import { BlobItemModel } from "../../models/BlobItem";
import IBlobItem from "../../interfaces/IBlobItem";

export default class FileViewerStore {
  selected: BlobItemModel | null = null;
  protected store: AppStore;

  constructor(store: AppStore) {
    this.store = store;
  }

  select(item: IBlobItem) {
    runInAction(() => {
      this.selected = new BlobItemModel(this.store, item);
    });
  }

  clearSelected() {
    runInAction(() => {
      this.selected = null;
    });
  }
}
