import { observer } from "mobx-react-lite";
import { IPayrollStatus } from "../../../../shared/interfaces/IPayroll";

interface ToolBarProps {
  title: string;
  status: IPayrollStatus;
  navigateBack: () => void;
  openApprovalDialog: () => void;
  inclusivesLength: number;
}
export const ApprovalToolBar = observer((props: ToolBarProps) => {
  const { title, status, navigateBack, openApprovalDialog, inclusivesLength } = props;

  return (
    <div className="section-toolbar uk-margin">
      <h4 className="section-heading uk-heading go-back" onClick={navigateBack}>
        <span data-uk-icon="chevron-left"></span>
        {title}
      </h4>
      <div className="controls">
        {status === "draft" && inclusivesLength !== 0 && (
          <button
            className="uk-button primary uk-margin-small-right"
            onClick={openApprovalDialog}
          // disabled={inclusivesLength === 0}
          >
            Add Approval
          </button>
        )}

        {/* {status !== "draft" && ( //either inprogress or completed
          <button className="uk-button uk-margin-small-right" disabled>
            Add Approval
          </button>
        )} */}
      </div>
    </div>
  );
});
