import { observer } from "mobx-react-lite";
import ProductItem from "./ProductItem";
import { useAppContext } from "../../../shared/functions/Context";
import ErrorBoundary from "../../shared/error-boundary/ErrorBoundary";

const ProductList = observer(() => {
  const { store } = useAppContext();

  const products = store.inventory.products.all;

  return (
    <ErrorBoundary>
      <div className="product-list">
        <ErrorBoundary>
          <div className="uk-overflow-auto">
            <table className="company-users-table uk-table uk-table-small uk-table-divider uk-table-middle uk-table-responsive">
              <thead className="table-header">
                <tr>
                  <th>#</th>
                  <th>SKU</th>
                  <th className="uk-table-expand">Name</th>
                  <th className="uk-table-expand">Category</th>
                  <th className="uk-table-expand">Price</th>
                  <th className="uk-table-expand">Price (Incl. VAT)</th>
                  <th className="uk-table-expand">Qty. on hand</th>
                  <th className="actions">Actions</th>
                </tr>
              </thead>
              <tbody className="table-body">
                {products.map((product, index) => (
                  <ProductItem index={index} product={product} />
                ))}
              </tbody>
            </table>
          </div>
        </ErrorBoundary>

        {/* Empty & not loading */}
        <ErrorBoundary>
          {!products.length && (
            <div className="no-products">
              <p className="uk-text-center">
                Empty (no data) <span>😔</span>
              </p>
            </div>
          )}
        </ErrorBoundary>
      </div>
    </ErrorBoundary>
  );
});

export default ProductList;
