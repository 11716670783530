export const defaultLeaveRequest: ILeaveRequest = {
  id: "",
  type: "",
  typeId: "",
  appliedOn: Date.now(),
  from: Date.now(),
  to: Date.now(),
  reasonForLeave: "",
  status: "PENDING",
  userName: "",
  userId: "",
  days: 1,
  departmentId: "",
  userRole: "",
  reasonForRejection: "",
  filePath: "",
  email:""
};

export type ILeaveStatus = "APPROVED" | "REJECTED" | "PENDING";

export default interface ILeaveRequest {
  id: string;
  type: string;
  typeId: string;
  appliedOn: number;
  from: number;
  to: number;
  days: number;
  reasonForLeave: string;
  reasonForRejection: string;
  status: ILeaveStatus;
  userName: string;
  userId: string;
  userRole: string;
  departmentId: string;
  filePath: string;
  email:string;
}
