import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useAppContext } from "../../../shared/functions/Context";
import LeaveList from "./LeaveList";

const CommonLeaves = observer(() => {
  const { api, store } = useAppContext();


  useEffect(() => {
    const load = async () => {
      await api.leave.settings.getAll();
    };
    load()
  }, [api.leave.settings]);

  return (
    <div>
      <LeaveList leaveItems={store.leave.settings.allCommonLeaves} />
    </div>
  );
});

export default CommonLeaves;
