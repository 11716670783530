export const defaultLeaveProfile: ILeaveProfile = {
  id: "",
  userId: "",
  userName: "",
  userRole: "",
  departmentId: "",
  leavesTaken: [],
};

export interface ILeaveTaken {
  id: string;
  type: string;
  days: number;
}

export default interface ILeaveProfile {
  id: string; //uid+year
  userId: string;
  userName: string;
  departmentId: string;
  userRole: string;
  leavesTaken: ILeaveTaken[];
}
