import { observer } from "mobx-react-lite";
import { Dispatch, FormEvent, SetStateAction } from "react";
import IPayroll from "../../../../shared/interfaces/IPayroll";

interface IProps {
  payroll: IPayroll;
  setPayroll: Dispatch<SetStateAction<IPayroll>>;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  loading: boolean;
}
const PayrollForm = observer((props: IProps) => {
  const { payroll, setPayroll, onSubmit, loading } = props;

  const onDeadlineChange = (value: string) => {
    const deadlineMs = new Date(value).getTime();
    setPayroll({ ...payroll, deadline: value, deadlineMs });
  };

  const currMonth = new Date().toLocaleString("en-US", { month: "long" });
  const year = new Date().getFullYear();

  return (
    <form
      className="uk-form-stacked uk-grid-small uk-child-width-1-1"
      data-uk-grid
      onSubmit={onSubmit}
    >
      <div className="uk-grid-small uk-child-width-1-1" data-uk-grid>
        <div>
          <div className="uk-margin">
            <label className="uk-form-label" htmlFor="form-stacked-text">
              Title
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input uk-form-small"
                type="text"
                placeholder={`Payroll title e.g. ${currMonth} ${year}`}
                value={payroll.title}
                onChange={(e) =>
                  setPayroll({ ...payroll, title: e.target.value })
                }
                required
              />
            </div>
          </div>
        </div>
        <div>
          <div className="uk-margin">
            <label className="uk-form-label" htmlFor="form-stacked-text">
              Deadline
            </label>
            <div className="uk-form-controls">
              <input
                className="uk-input uk-form-small"
                type="date"
                placeholder="Payroll deadline"
                value={payroll.deadline}
                onChange={(e) => onDeadlineChange(e.target.value)}
                required
              />
            </div>
          </div>
        </div>
        <div>
          <div className="uk-margin">
            <label className="uk-form-label" htmlFor="form-stacked-text">
              Description
            </label>
            <div className="uk-form-controls">
              <textarea
                className="uk-textarea uk-form-small"
                placeholder="Description"
                value={payroll.description}
                onChange={(e) =>
                  setPayroll({
                    ...payroll,
                    description: e.target.value,
                  })
                }
                required
              />
            </div>
          </div>
        </div>
        <div className="footer">
          <button className="uk-button secondary uk-modal-close" type="button">
            Cancel
          </button>
          <button className="uk-button uk-button-primary" type="submit">
            Save
            {loading && <div data-uk-spinner="ratio: .5"></div>}
          </button>
        </div>
      </div>
    </form>
  );
});

export default PayrollForm;
