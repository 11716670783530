import { FormEvent, useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import ProductCategoryForm from "./ProductCategoryForm";
import { IInventoryProductCategory, defaultInventoryProductCategory } from "../../../shared/models/InventoryProductCategory";
import { useAppContext } from "../../../shared/functions/Context";
import { hideModalFromId } from "../../../shared/functions/ModalShow";
import DIALOG_NAMES from "../Dialogs";

const DialogPayroll = observer(() => {
  const { api, store } = useAppContext();
  const [productCategory, setProducts] = useState<IInventoryProductCategory>({ ...defaultInventoryProductCategory });
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);
    const $productCategory: IInventoryProductCategory = {
      ...productCategory,
    };
    const selected = store.inventory.categories.selected;
    if (selected) await update($productCategory);
    else await create($productCategory);
    setLoading(false);
    onCancel();
  };

  const update = async (data: IInventoryProductCategory) => {
    try {
      await api.inventory.categories.update(data);
    } catch (error) {
      console.log(error);
    }
  };

  const create = async (data: IInventoryProductCategory) => {
    try {
      await api.inventory.categories.create(data);
    } catch (error) {
      console.log(error);
    }
  };

  const onCancel = () => {
    store.inventory.categories.clearSelected();
    setProducts({ ...defaultInventoryProductCategory });
    hideModalFromId(DIALOG_NAMES.INVENTORY.PRODUCT_CATEGORY_DIALOG);
  };

  useEffect(() => {
    if (store.inventory.categories.selected) setProducts(store.inventory.categories.selected);
    else setProducts({ ...defaultInventoryProductCategory });
    return () => { };
  }, [store.inventory.categories.selected]);

  return (
    <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
      <button className="uk-modal-close-default" type="button" data-uk-close />
      <div className="dialog-content uk-position-relative productCategory-dialog">
        <ProductCategoryForm
          productCategory={productCategory}
          setInventoryProduct={setProducts}
          onSubmit={handleSubmit}
          loading={loading}
        />
      </div>
    </div>
  );
});

export default DialogPayroll;
