import { observer } from "mobx-react-lite";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useAppContext } from "../../../shared/functions/Context";
import { addBusinessDaysToDate, businessDaysBetweenDates } from "../../../shared/functions/TimestampToDate";
import ILeaveProfile from "../../../shared/interfaces/ILeaveProfile";
import ILeaveRequest, { defaultLeaveRequest } from "../../../shared/interfaces/ILeaveRequest";
import useUploadToStorage from "../../shared/hooks/useUploadToStorage";
import LeaveApplicationForm from "./LeaveApplicationForm";
import { SuccessfulAction } from "../../../shared/models/Snackbar";
import { action } from "mobx";

const ToolBar = () => {
  const navigate = useNavigate();

  return (
    <div className="section-toolbar uk-margin">
      <h4 className="section-heading uk-heading">Leave application</h4>
      <div className="controls">
        <button className="uk-button primary" onClick={() => navigate("/C/leave/my-applications")} >
          View my applications
        </button>
      </div>
    </div>
  );
};

interface IProps {
  profile: ILeaveProfile;
}
const LeaveApplication = observer((props: IProps) => {
  const { api, store, ui } = useAppContext();
  const { profile } = props;
  const { userName, userId, userRole, departmentId, leavesTaken } = profile;
  const { isUploading, progress, uploadFile, onCancel } = useUploadToStorage();
  const navigate = useNavigate();
  const me = store.user.meJson;

  const [leaveRequest, setLeaveRequest] = useState<ILeaveRequest>({
    ...defaultLeaveRequest,
    userName,
    userRole,
    userId: me?.uid || "",
    departmentId: me?.departmentId || "",
    email: me?.email || "",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);
  const [daysLeft, setDaysLeft] = useState(10);
  // change value as leaveType changes

  // Get leave settings for the user i.e. Common Leaves + Departmental Leaves
  const commonLeaves = store.leave.settings.allCommonLeaves;
  const departmentLeaves = store.leave.settings.allDeparmentLeaves(departmentId);
  const leaveTypes = [...commonLeaves, ...departmentLeaves];

  const timeStampToDateString = (timestamp: number): string => {
    const date = new Date(timestamp);
    const yy = date.getFullYear();
    const mm = date.getMonth() + 1;
    const dd = date.getDate();
    return `${yy}-${mm < 10 ? "0" + mm : mm}-${dd < 10 ? "0" + dd : dd}`;
  };

  const handleFromChange = (timestamp: number) => {
    setLeaveRequest({
      ...leaveRequest,
      from: timestamp,
      to: timestamp,
      days: 0,
    });
  };

  const handleToChange = (timestamp: number) => {
    const days = businessDaysBetweenDates(leaveRequest.from, timestamp);
    const $days = days > daysLeft ? daysLeft : days;
    const to = addBusinessDaysToDate(leaveRequest.from, $days).getTime();
    setLeaveRequest({
      ...leaveRequest,
      to,
      days: $days,
    });
  };

  const handleDaysChange = useCallback((days: number = leaveRequest.days) => {
    const $days = days > daysLeft ? daysLeft : days;
    const to = addBusinessDaysToDate(leaveRequest.from, $days).getTime();
    setLeaveRequest({ ...leaveRequest, to, days: $days });
  }, [daysLeft, leaveRequest]);

  const handleLeaveTypeChange = (type: string) => {
    // Selected leave type
    const selectedLeaveType = leaveTypes.find((taken) => taken.type === type);
    const leaveTaken = leavesTaken.find((taken) => taken.type === type);

    // Selected leave types
    if (!selectedLeaveType) return;

    const allowedDays = selectedLeaveType.days;

    if (leaveTaken) {
      // Calculate left days
      // Has taken leave
      const takenDays = leaveTaken.days;

      const $daysLeft = allowedDays - takenDays;
      setDaysLeft($daysLeft);
    } else {
      // Haven't taken leave yet.
      setDaysLeft(allowedDays);
    }

    setLeaveRequest({ ...leaveRequest, type });
  };

  const handleFilesChange = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (e.target.files) {
      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i];
        setFiles((currentFiles) => {
          return [...currentFiles, file];
        });
      }
    }
  };

  const handleFileRemove = async (fileName: string) => {
    if (isUploading) onCancel();
  };

  const resetLeaveRequest = () => {
    setLeaveRequest({
      ...leaveRequest,
      from: Date.now(),
      to: Date.now(),
      days: 1,
      reasonForLeave: ""
    });
    store.leave.requests.selected = null;
    setFiles([]);
  };


  const onSubmit = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const request = { ...leaveRequest };

    const downloadURL = files.length ? await uploadFile(files[0], "/leave-requests") : "";
    request.filePath = downloadURL;

    try {
      const selected = store.leave.requests.selected;

      if (selected) await update(request);
      else await create(request);

      resetLeaveRequest();
      navigate("/C/leave/my-applications");
    } catch (error) {
      window.alert("There was an error applying for leave. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const update = async (request: ILeaveRequest) => {
    try {
      await api.leave.requests.update(request);
    } catch (error) {
      ui.snackbar.load({
        id: Date.now(),
        message: "Error! Failed to update.",
        type: "danger",
      });
    }
  };

  const create = async (request: ILeaveRequest) => {
    try {
      await api.leave.requests.create(request);
      await api.mail.leaveApplicant("Your leave application has been sent!", "Log into the system to view the details");
      await api.mail.leaveAdmin(`RE: ${userName} has applied for a leave.`, `Log into the system to view the details`);
    } catch (error) {
      ui.snackbar.load({
        id: Date.now(),
        message: "Error! Failed to create.",
        type: "danger",
      });
    }
  };

  useEffect(() => {
    handleDaysChange();
    return () => {
      handleDaysChange(0);
    };
  }, []);

  useEffect(() => {
    if (store.leave.requests.selected) {
      action(() => {
        setLeaveRequest(store.leave.requests.selected!);
      })();
    } else {
      action(resetLeaveRequest)();
    }
  }, [store.leave.requests.selected]);

  return (
    <div className="leave-application-section">
      <ToolBar />
      <LeaveApplicationForm
        profile={profile}
        timeStampToDateString={timeStampToDateString}
        handleFromChange={handleFromChange}
        handleToChange={handleToChange}
        handleDaysChange={handleDaysChange}
        handleFilesChange={handleFilesChange}
        handleFileRemove={handleFileRemove}
        handleLeaveTypeChange={handleLeaveTypeChange}
        onSubmit={onSubmit}
        resetLeaveRequest={resetLeaveRequest}
        leaveRequest={leaveRequest}
        setLeaveRequest={setLeaveRequest}
        leaveTypes={leaveTypes}
        daysLeft={daysLeft}
        files={files}
        setFiles={setFiles}
        loading={loading}
        progress={progress}
      />
    </div>
  );
});

export default LeaveApplication;
