import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../shared/components/Loading";
import NoData from "../../../shared/components/no-data/NoData";
import { useAppContext } from "../../../shared/functions/Context";
import PayrollList from "./PayrollList";

const EmployeePayrolls = observer(() => {
  const { api, store, ui } = useAppContext();
  const { folderId } = useParams<{ folderId: string }>();
  const navigate = useNavigate();
  
  const [loading, setLoading] = useState(true);
  const payrolls = store.payroll.batch.all.filter((p) => p.asJson.folderId === folderId);

  const onBack = () => {
    navigate(`/c/payroll/fy/`);
  };

  useEffect(() => {
    if (!folderId) return;
    setLoading(true);
    const loadData = async () => {
      try {
        await api.payroll.batch.getByFolderId(folderId);
      } catch (error) {
        ui.snackbar.load({
          id: Date.now(),
          message: "Error! Failed to load the payrolls",
          type: "danger",
        });
      }
    };
    loadData();
    setLoading(false);
  }, [api.payroll.batch, ui, folderId]);

  if (loading)
    return (
      <div className="uk-card uk-card-default uk-card-body">
        <Loading />
      </div>
    )
  return (
    <div className="uk-section payroll-page">
      <div className="uk-container uk-container-large">
        <div className="section-toolbar uk-margin">
          <h4 className="section-heading uk-heading go-back" onClick={onBack}>
            <span data-uk-icon="chevron-left"></span>Payrolls
          </h4>
        </div>
        <PayrollList payrolls={payrolls} />
        {!loading && payrolls.length === 0 && (
          <div className="uk-card uk-card-default uk-card-body">
            <NoData />
          </div>
        )}
      </div>
    </div>
  );
});

export default EmployeePayrolls;
