import { observer } from "mobx-react-lite";
import { IPayrollStatus } from "../../../../shared/interfaces/IPayroll";

interface ToolBarProps {
  including: boolean;
  title: string;
  status: IPayrollStatus;
  navigateBack: () => void;
  onNewEmployee: () => Promise<void>;
  includeAll: () => Promise<void>;
}
export const DraftToolBar = observer((props: ToolBarProps) => {
  const { title, navigateBack, status, onNewEmployee, includeAll, including } = props;

  return (
    <div className="section-toolbar uk-margin">
      <h4 className="section-heading uk-heading go-back" onClick={navigateBack}>
        <span data-uk-icon="chevron-left"></span>
        {title}
      </h4>
      <div className="controls">
        <button
          className="uk-button primary uk-margin-small-right"
          onClick={includeAll}
        >
          Include All
          {including && <div data-uk-spinner="ratio: .5"></div>}
        </button>
        {/* <button
          disabled={status !== "draft"}
          className="uk-button primary uk-margin-small-right"
          onClick={onNewEmployee}
        >
          Add Employee
        </button> */}
      </div>
    </div>
  );
});
