import { makeAutoObservable, toJS } from "mobx";
import AppStore from "../stores/AppStore";

export const defaultInventoryProductCategory: IInventoryProductCategory = {
  id:"",
  categoryId:"",
  categoryName:"",
  description: "",
};

export interface IInventoryProductCategory {
  id:string;
  categoryId:string,
  categoryName: string,
  description: string
}

export default class InventoryProductCategory {
  private _inventoryProduct: IInventoryProductCategory;

  constructor(private store: AppStore, InventoryProduct: IInventoryProductCategory) {
    makeAutoObservable(this);
    this._inventoryProduct = InventoryProduct;
  }

  get asJson(): IInventoryProductCategory {
    return toJS(this._inventoryProduct);
  }
}