import { makeAutoObservable } from "mobx";
import IRegion from "../interfaces/IRegion";
import AppStore from "../stores/AppStore";

export class Region implements IRegion {
  id: string;
  name: string;

  constructor(private store: AppStore, department: IRegion) {
    makeAutoObservable(this);
    this.id = department.id;
    this.name = department.name;
  }

  get asJson(): IRegion {
    return {
      id: this.id,
      name: this.name,
    };
  }
}
