// var payrollMonth = new Date();
// var month = payrollMonth.getMonth();
// var year = payrollMonth.getFullYear();
// var date = new Date(year, month + 1, 0);

export const defaultPayrollEmployee: IPayrollEmployee = {
  uid: "",
  firstName: "",
  lastName: "",
  salaryGrade: "",
  position: "",
  departmentId: "",
  departmentName: "",

  regionId: "",
  regionName: "",

  employmentDate: null,
  identityNo: "",
  payrollMonth: null,
  isExcluded: true,

  bankAccount: {
    bankName: "",
    accountNumber: "",
    branchName: "",
    accountType: "",
    branchCode: "",
  },

  paySummary: {
    totalBonus: 0,
    totalExpenses: 0,
    totalDeductions: 0,
    grossIncome: 0,
    taxGrossIncome: 0,
    netIncome: 0,
    professionalTax: 0,
  },

  fixedSalary: [],
  fixedBonus: [],
  fixedDeductions: [],
  fixedExpenses: [],
  flexiSalary: [],
  flexiBonus: [],
  flexiDeductions: [],
  flexiExpenses: [],
  payrollId: "",
};

export interface IBankAccount {
  bankName: string;
  accountNumber: string;
  accountType: string;
  branchName: string;
  branchCode: string;
}

export interface IFixedPaymentInput {
  name: string;
  amount: number;
  taxable: boolean;
  percentage: number;
  percentageAmount: number;
  finalAmount: number;
}

export interface IFlexiPaymentInput extends IFixedPaymentInput {
  hours: number;
  rate: number;
}

export interface IPaymentSummary {
  totalBonus: number;
  totalExpenses: number;
  totalDeductions: number;
  professionalTax: number;
  grossIncome: number;
  taxGrossIncome: number;
  netIncome: number;
}

export interface IPayrollBrief {
  id: string;
  title: string;
}

interface IPayrollEmployee {
  uid: string;
  firstName: string;
  lastName: string;
  salaryGrade: string;
  position: string;
  departmentId: string;
  departmentName: string;

  regionId: string;
  regionName: string;

  payrollId: string;
  employmentDate: number | null;
  identityNo: string;
  payrollMonth: number | null;
  bankAccount: IBankAccount;
  paySummary: IPaymentSummary;
  isExcluded: boolean;

  fixedSalary: IFixedPaymentInput[];
  flexiSalary: IFlexiPaymentInput[];

  fixedBonus: IFixedPaymentInput[];
  flexiBonus: IFlexiPaymentInput[];

  fixedDeductions: IFixedPaymentInput[];
  flexiDeductions: IFlexiPaymentInput[];

  fixedExpenses: IFixedPaymentInput[];
  flexiExpenses: IFlexiPaymentInput[];
}
export default IPayrollEmployee;
