export type IInvoiceStatus = "Unpaid" | "Paid" | "Partially";

export const defaultInvoice: IInvoice = {
  id: "",
  customerId: "",
  invoiceName: "",
  description: "",
  companyImgUrl: "",
  proofOfPayment: "",
  invoiceStatus: "Unpaid",
  invoiceDate: "",
  paymentDate: "",
  paymentDateMs: Date.now(),
  amountDue: 0,
  amountPayed: 0,
  invoiceDiscount: 0,
  subTotal: 0,
  total: 0,

  customer: {
    customerId: "",
    businessName: "",
    firstName: "",
    lastName: "",
    email: "",
    cellphone: "",
    status: "pending",
  },
  invoiceHead: {
    name: "Items",
    unit: "Quantity",
    price: "Price",
    amount: "Amount",
  },
  invoiceItem: [
    {
      itemName: "",
      itemDescription: "",
      itemUnit: 0,
      itemPrice: 0,
      itemAmount: 0,
      VATname: "",
      VATpercent: 0,
    },
    {
      itemName: "",
      itemDescription: "",
      itemUnit: 0,
      itemPrice: 0,
      itemAmount: 0,
      VATname: "",
      VATpercent: 0,
    },
  ],
};

export interface IFlexiInvoiceHeadInput {
  name: string;
  unit: string;
  price: string;
  amount: string;
}

export interface IFlexiInvoiceItemInput {
  itemName: string;
  itemDescription: string;
  itemUnit: number;
  itemPrice: number;
  itemAmount: number;
  VATname: string;
  VATpercent: number;
}

export interface ICustomerDetail {
  customerId: string;
  businessName: string;
  firstName: string;
  lastName: string;
  email: string;
  cellphone: string;
  status: string;
}

interface IInvoice {
  id: string;

  customerId: string;

  invoiceName: string;
  description: string;
  companyImgUrl: string;
  proofOfPayment: string;

  invoiceDate: string;
  paymentDate: string;
  invoiceStatus: IInvoiceStatus;
  invoiceDiscount: number;
  subTotal: number;
  total: number;
  paymentDateMs: number;
  amountDue: number;
  amountPayed: number;
  customer: ICustomerDetail;
  invoiceItem: IFlexiInvoiceItemInput[];
  invoiceHead: IFlexiInvoiceHeadInput;
}
export default IInvoice;
