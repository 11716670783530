import React from "react";
import { IPaymentSummary } from "../../../shared/interfaces/IPayrollEmployee";
import { CustomNumberFormat } from "../../../shared/functions/CustomNumberFormat";

interface ISummaryProps {
  loading: boolean;
  deleteLoader: boolean;
  isExpanded: boolean;
  paymentSummary: IPaymentSummary;
  isExcluded: boolean;
  status: string;
  onEdit: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onInclude: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onExclude: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onExport: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onDelete: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}
const EmployeeItemPaymentSummary = (props: ISummaryProps) => {
  const {
    loading,
    deleteLoader,
    isExpanded,
    paymentSummary,
    isExcluded,
    status,
    onEdit,
    onInclude,
    onExclude,
    onExport,
    onDelete,
  } = props;

  return (
    <div className={`summary ${isExpanded ? "expanded uk-margin" : ""}`}>
      <h5 className="label">Summary</h5>
      <table className="uk-table uk-table-small uk-table-divider">
        <tbody>
          <tr>
            <td className="item">Gross Salary</td>
            <td className="uk-text-right">
              N${CustomNumberFormat(paymentSummary.grossIncome || 0)}
            </td>
          </tr>
          {/* <tr>
            <td className="item">Basic Salary</td>
            <td className="uk-text-right">
              N${CustomNumberFormat(paymentSummary.totalSalary || 0)}
            </td>
          </tr> */}

          <tr>
            <td className="item">Deductions</td>
            <td className="uk-text-right">
              (-) N${CustomNumberFormat(paymentSummary.totalDeductions || 0)}
            </td>
          </tr>

          <tr>
            <td className="item">Net Income</td>
            <td className="uk-text-right">
              N${CustomNumberFormat(paymentSummary.netIncome || 0)}
            </td>
          </tr>
          <tr>
            <td className="gap" colSpan={2}></td>
          </tr>
        </tbody>
      </table>

      <div className="footer uk-margin">
        {status === "completed" ? (
          <>
            <button
              className={`uk-button uk-button-default uk-margin-small-right ${status}`}
              disabled
            >
              Edit
            </button>
            <button
              className="uk-button footer-button uk-margin-small-right"
              onClick={onExport}
            >
              Export PDF
            </button>
            {isExcluded && (
              <button
                className={`uk-button uk-button-default uk-margin-small-right ${status}`}
                disabled
              >
                Include in Payroll{" "}
                {loading && <div data-uk-spinner="ratio: .5"></div>}
              </button>
            )}
            {!isExcluded && (
              <button
                className={`uk-button uk-button-default uk-margin-small-right ${status}`}
                disabled
              >
                Exclude from Payroll{" "}
                {loading && <div data-uk-spinner="ratio: .5"></div>}
              </button>
            )}
            <button
              className={`uk-button uk-button-default uk-margin-small-right ${status}`}
              disabled
            >
              Delete
            </button>
          </>
        ) : (
          <div className="uk-margin">
            <button
              className="uk-button footer-button uk-margin-small-right"
              onClick={onEdit}
            >
              Edit
            </button>
            <button
              className="uk-button footer-button uk-margin-small-right"
              onClick={onExport}
            >
              Export PDF
            </button>
            {isExcluded && (
              <button
                className="uk-button uk-margin-small-right footer-button"
                onClick={onInclude}
              >
                Include in Payroll{" "}
                {loading && <div data-uk-spinner="ratio: .5"></div>}
              </button>
            )}
            {!isExcluded && (
              <button
                className="uk-button footer-button uk-margin-small-right"
                onClick={onExclude}
              >
                Exclude from Payroll{" "}
                {loading && <div data-uk-spinner="ratio: .5"></div>}
              </button>
            )}
            <button
              className="uk-button footer-button-delete uk-margin-small-right"
              onClick={onDelete}
            >
              Delete
              {deleteLoader && <div data-uk-spinner="ratio: .5"></div>}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmployeeItemPaymentSummary;
