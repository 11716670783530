import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useAppContext } from "../../shared/functions/Context";
import ILeaveRequest, { ILeaveStatus, } from "../../shared/interfaces/ILeaveRequest";
import LeaveGrid from "./application/LeaveGrid";
import { LeaveRequest } from "../../shared/models/LeaveRequest";

interface TabsProps {
  setTab: React.Dispatch<React.SetStateAction<ILeaveStatus>>;
}
const Tabs = (props: TabsProps) => {
  const { setTab } = props;

  return (
    <ul className="tabs" data-uk-tab>
      <li className="tab-item uk-active">
        <a href="/#" onClick={() => setTab("PENDING")}>
          Pending
        </a>
      </li>
      <li className="tab-item">
        <a href="/#" onClick={() => setTab("APPROVED")}>
          Approved
        </a>
      </li>
      <li className="tab-item">
        <a href="/#" onClick={() => setTab("REJECTED")}>
          Rejected
        </a>
      </li>
    </ul>
  );
};

const MyApplications = observer(() => {
  const { api, store, ui } = useAppContext();

  const [tab, setTab] = useState<ILeaveStatus>("PENDING");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const user = store.user.meJson;

  const onCancel = async (request: ILeaveRequest) => {
    if (!window.confirm("Cancel leave?")) return;
    await api.leave.requests.delete(request);
    await api.mail.leaveApplicant("Your leave application has been cancelled!", "");
    await api.mail.leaveAdmin(`RE: ${request.userName} has cancelled the  leave.`, ``);
    ui.snackbar.load({
      id: Date.now(),
      message: "Leave cancelled!",
      type: "success",
    });
  };

  const onEdit = async (request: LeaveRequest) => {
    store.leave.requests.select(request.asJson)
    navigate("/c/leave/apply")
  };

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      if (user) {
        await api.leave.requests.getAllByUID(user.uid);
        setLoading(false);
      } else {
        store.leave.requests.removeAll()
        setLoading(false);
      }
    };
    load();
  }, [api.leave.requests, store.leave.requests, user]);

  return (
    <div className="uk-section">
      <div className="uk-container uk-container-large">
        <div className="my-applications-section">
          <div className="section-toolbar uk-margin">
            <h4 className="section-heading uk-heading">My applications</h4>
            <div className="controls">
              <button
                className="uk-button primary"
                onClick={() => navigate("/c/leave/apply")}
              >
                Apply for leave
              </button>
            </div>
          </div>
          <Tabs setTab={setTab} />
          {tab === "PENDING" && (
            <LeaveGrid
              requests={store.leave.requests.allPending}
              loading={loading}
              onCancel={onCancel}
              onEdit={onEdit}
            />
          )}

          {tab === "APPROVED" && (
            <LeaveGrid
              requests={store.leave.requests.allApproved}
              loading={loading}
            />
          )}
          {tab === "REJECTED" && (
            <LeaveGrid
              requests={store.leave.requests.allRejected}
              loading={loading}
            />
          )}
        </div>
      </div>
    </div>
  );
});

export default MyApplications;
