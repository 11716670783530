import Store from "../Store";
import AppStore from "../AppStore";
import { runInAction } from "mobx";
import { TaxRate } from "../../models/TaxRate";
import ITaxRate from "../../interfaces/ITaxRate";

export default class TaxRateStore extends Store<ITaxRate, TaxRate> {
  items = new Map<string, TaxRate>();

  constructor(store: AppStore) {
    super(store);
    this.store = store;
  }

  load(items: ITaxRate[] = []) {
    runInAction(() => {
      items.forEach((item) =>
        this.items.set(item.id, new TaxRate(this.store, item))
      );
    });
  }
}
