import {
  ChangeEvent,
  Dispatch,
  FormEvent,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useAppContext } from "../../../shared/functions/Context";
import {
  IFixedPaymentInput,
  IFlexiPaymentInput,
} from "../../../shared/interfaces/IPayrollEmployee";
import PaymentFixedAmountItem from "./PaymentFixedAmountItem";
import PaymentFlexiAmountItem from "./PaymentFlexiAmountItem";
import { observer } from "mobx-react-lite";
import { runInAction } from "mobx";

interface IProps {
  title: string;
  flexiItems: IFlexiPaymentInput[];
  setFlexiItems: Dispatch<SetStateAction<IFlexiPaymentInput[]>>;
  fixedItems: IFixedPaymentInput[];
  setFixedItems: Dispatch<SetStateAction<IFixedPaymentInput[]>>;
}


const PaymentDetailSection = observer((props: IProps) => {
  const { title, flexiItems, setFlexiItems, fixedItems, setFixedItems } = props;
  const [name, setName] = useState("");
  const [inputType, setInputType] = useState<"Flexi" | "Fixed">("Fixed");
  const { api } = useAppContext();

  const addFlexiItem = () => {
    const newItem: IFlexiPaymentInput = {
      name,
      hours: 0,
      rate: 0,
      amount: 0,
      taxable: false,
      percentage: 0,
      percentageAmount: 0,
      finalAmount: 0,
    };
    const data = [...flexiItems];
    data.push(newItem);
    setFlexiItems(data);
  };

  const addFixedItem = () => {
    const newItem: IFixedPaymentInput = {
      name,
      amount: 0,
      taxable: false,
      percentage: 0,
      percentageAmount: 0,
      finalAmount: 0,
    };
    const data = [...fixedItems];

    data.push(newItem);
    setFixedItems(data);
  };

  const onAdd = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (inputType === "Flexi") addFlexiItem();
    else addFixedItem();
    setName(""); //Reset name
  };

  const onRateItemChange = (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name as "hours" | "rate";

    runInAction(() => {
      const data = [...flexiItems];
      data[index][name] = Number(e.target.value) || 0;
      data[index]["amount"] = data[index].hours * data[index].rate;
      setFlexiItems(data);

    })

  };

  const onRateItemRemove = (index: number) => {
    const data = [...flexiItems];
    data.splice(index, 1);
    setFlexiItems(data);
  };


  const onFixedItemChange = (index: number) => (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    runInAction(() => {
      const inputName = e.target.name as keyof IFixedPaymentInput;
      const data = [...fixedItems];

      if (inputName === "amount") {
        data[index][inputName] = Number(e.target.value);
      }

      if (inputName === "taxable") {
        data[index][inputName] = (e.target as HTMLInputElement).checked;
        if (!data[index]["taxable"]) {
          data[index]["finalAmount"] = data[index].amount;
          data[index]["percentage"] = 0;
          data[index]["percentageAmount"] = 0;
        }
      }

      if (inputName === "percentage") {
        data[index][inputName] = Number(e.target.value);
        data[index]["finalAmount"] = data[index].amount - (data[index].amount * data[index]["percentage"]) / 100;
        data[index]["percentageAmount"] = data[index].amount - data[index].finalAmount;
      }

      if (data[index]["percentage"] !== 0 && inputName !== "taxable") {
        data[index]["finalAmount"] = data[index].amount - (data[index].amount * data[index]["percentage"]) / 100;
      }

      if (data[index]["percentage"] === 0 && inputName !== "taxable") {
        data[index]["finalAmount"] = data[index].amount;
      }

      setFixedItems(data);
    });
  };

  const onFixedItemRemove = (index: number) => {
    const data = [...fixedItems];
    data.splice(index, 1);
    setFixedItems(data);
  };

  useEffect(() => {
    const loadRates = async () => {
      await api.payroll.taxRate.getAll();
    };
    loadRates();
  }, [api.payroll.taxRate]);

  useEffect(() => {
    if (title === "Salary") setInputType("Flexi");
    return () => { };
  }, [title]);

  return (
    <Fragment>
      <h4 className="section-heading">{title}</h4>
      {fixedItems.map((item, index) => (
        <div key={index}>
          <PaymentFixedAmountItem
            index={index}
            name={item.name}
            amount={item.amount}
            taxable={item.taxable}
            percentage={item.percentage}
            finalAmount={item.finalAmount}
            percentageAmount={item.percentageAmount}
            onChange={onFixedItemChange}
            onRemove={onFixedItemRemove}
          />
        </div>
      ))}
      {flexiItems.map((item, index) => (
        <div key={index}>
          <PaymentFlexiAmountItem
            index={index}
            name={item.name}
            hours={item.hours}
            rate={item.rate}
            amount={item.amount}
            onChange={onRateItemChange}
            onRemove={onRateItemRemove}
          />
        </div>
      ))}
      <div className="uk-inline">
        <button className="uk-button text" type="button">
          +Add {title} Item
        </button>
        <form
          className="uk-dropdown dropdown"
          data-uk-dropdown="mode: click"
          onSubmit={onAdd}
        >
          <input
            type="text"
            className="uk-input uk-form-small"
            placeholder={`E.g ${title}`}
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <select
            className="uk-select uk-form-small"
            value={inputType}
            onChange={(e) => setInputType(e.target.value as "Flexi" | "Fixed")}
          >
            <option value="Flexi">Rate Amount</option>
            <option value="Fixed">Fixed Amount</option>
          </select>

          <button
            className="uk-button uk-button-primary uk-button-small"
            type="submit"
          >
            Add
          </button>
        </form>
      </div>
    </Fragment>
  );
});

export default PaymentDetailSection;