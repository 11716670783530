import {
  CollectionReference,
  deleteDoc,
  doc,
  getDoc,
  onSnapshot,
  query,
  setDoc,
} from "firebase/firestore";
import ILeaveProfile from "../interfaces/ILeaveProfile";
import AppStore from "../stores/AppStore";
import AppApi from "./AppApi";

export default class LeaveProfilesApi {
  collectionRef: CollectionReference;
  constructor(
    private api: AppApi,
    private store: AppStore,
    collectionRef: CollectionReference
  ) {
    this.collectionRef = collectionRef;
  }

  async getAll() {
    const q = query(this.collectionRef);

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const items: ILeaveProfile[] = [];
      querySnapshot.forEach((doc) => {
        items.push({ id: doc.id, ...doc.data() } as ILeaveProfile);
      });

      this.store.leave.profiles.load(items);
    });

    return unsubscribe;
  }

  async getProfile(id: string) {
    const docSnap = await getDoc(doc(this.collectionRef, id));
    if (docSnap.exists()) {
      const profile = { ...docSnap.data(), id: docSnap.id } as ILeaveProfile;
      this.store.leave.profiles.load([profile]);
      return profile;
    } else return undefined;
  }

  // async create(profile: ILeaveProfile) {
  //   await setDoc(doc(this.collectionRef), profile, { merge: true });
  //   return profile;
  // }

  async update(profile: ILeaveProfile) {
    await setDoc(doc(this.collectionRef, profile.id), profile, {
      merge: true,
    });
    return profile;
  }

  async delete(id: string) {
    const docRef = doc(this.collectionRef, id);
    await deleteDoc(docRef);
    this.store.leave.profiles.remove(id);
  }
}
