const backgroundColor = "#fff";
const tableBordersNone = [false, false, false, false];

// Fonts
const h1FontSize = 14;
const h3FontSize = 10;
const bodyFontSize = 10;

export const header = (title: string) => {
  const docDef = {
    text: title,
    style: "subheader",
    fontSize: h1FontSize,
  };
  return docDef;
};

export const sectionHeader = (title: string, content?: string) => {
  const docDef = {
    style: "sectionHeader",
    table: {
      widths: ["*", "auto"],
      body: [
        [
          {
            text: title,
            margin: [5, 0],
          },
          {
            text: content ? content : "",
            noWrap: true,
            margin: [0, 0, 5, 0],
          },
        ],
      ],
    },
    layout: "noBorders",
  };
  return docDef;
};

export const subSectionHeader = (
  name: string,
  leaveType: string,
  days: string,
  from: string,
  to: string
) => {
  const docDef = {
    style: "subSectionHeader",
    table: {
      widths: ["*", "*", "*", "*", "*"],
      body: [
        [
          {
            text: name,
            margin: [5, 2],
            fontSize: bodyFontSize,
          },
          {
            text: leaveType,
            margin: [5, 2],
            noWrap: true,
            fontSize: bodyFontSize,
          },
          {
            text: days,
            margin: [5, 2],
            noWrap: true,
            fontSize: bodyFontSize,
          },
          {
            text: from,
            margin: [5, 2],
            noWrap: true,
            fontSize: bodyFontSize,
          },
          {
            text: to,
            margin: [5, 2],
            noWrap: true,
            fontSize: bodyFontSize,
          },
        ],
      ],
    },
    layout: "borders",
  };
  return docDef;
};

export const boldText = (content: string) => {
  const docDef = {
    text: content,
    bold: true,
    fontSize: h3FontSize,
    margin: [0, 5, 0, 0],
  };
  return docDef;
};

export const bodyText = (content: string) => {
  const docDef = {
    text: content,
    fontSize: 10,
    margin: [0, 5, 0, 0],
  };
  return docDef;
};

export const signatureHeader = () => {
  const docDef = {
    style: "signature",
    text: "Approved By:",
    fontSize: bodyFontSize,
    bold: true,
  };
  return docDef;
};

export const signatureTable = (supervivor?: string) => {
  const docDef = {
    style: "signatureTable",
    table: {
      widths: ["auto", "*", "auto", "*"],
      body: [
        [
          {
            border: tableBordersNone,
            text: "Print name:",
            bold: true,
            fontSize: bodyFontSize,
          },
          {
            border: [false, false, false, true],
            text: supervivor ? supervivor : "",
            bold: true,
            fontSize: bodyFontSize,
            noWrap: true,
          },
          {
            border: tableBordersNone,
            text: "Signature:",
            bold: true,
            fontSize: bodyFontSize,
          },
          {
            border: [false, false, false, true],
            text: "",
            bold: true,
            fontSize: bodyFontSize,
            noWrap: true,
          },
        ],
      ],
    },
  };
  return docDef;
};

export const marginTopBottom = () => {
  const docRef = {
    text: "",
    margin: [0, 10],
  };
  return docRef;
};

const LeaveDocDefinition = async (name: string, leaveDocRef: any) => {
  return {
    content: [
      // Logo
      // {
      //   image: await getBase64ImageFromURL(
      //     `${process.env.PUBLIC_URL}/logo2.png`
      //   ),
      //   fit: [80, 80],
      // },

      // Header
      marginTopBottom(),
      header(`Leave Report ${name}`), // Leave Report
      sectionHeader("Introduction"),
      bodyText(
        `This document summarizes the leave taken ${name}` // Leave taken from to
      ),
      marginTopBottom(),

      subSectionHeader("Name", "Leave Type", "Days", "From", "To"),
      ...leaveDocRef,
    ],

    styles: {
      header: {
        fontSize: 14,
        bold: true,
        margin: [0, 15, 0, 5],
      },
      sectionHeader: {
        fontSize: 12,
        margin: [0, 15, 0, 5],
        border: [false, false, false, false],
        bold: true,
        fillColor: backgroundColor,
      },
      subSectionHeader: {
        fontSize: 12,
        margin: 0,
        border: [true, true, true, true],
        bold: false,
        fillColor: backgroundColor,
      },
    },
    defaultStyle: {
      // alignment: 'justify'
    },
  };
};

export default LeaveDocDefinition;

