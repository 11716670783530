import AppStore from "../AppStore";
import CustomerStore from "../individualStore/CustomerStore";
import InvoiceStore from "../individualStore/InvoiceStore";

export default class InvoicingStore {
  invoice: InvoiceStore;
  customer: CustomerStore;

  constructor(store: AppStore) {
    this.invoice = new InvoiceStore(store);
    this.customer = new CustomerStore(store);
  }
}
