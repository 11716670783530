import { CustomNumberFormat } from "../../CustomNumberFormat";

export const heading2 = (title: string) => {
  const docDef = {
    style: "title2",
    margin: [0, 5, 0, 5],
    layout: {
      vLineColor: "#f5f5f5",
      hLineColor: "#f5f5f5",
      vLineWidth: function (i: any, node: any) {
        return 0;
      },
      hLineWidth: function (i: any, node: any) {
        return 0;
      },
    },
    table: {
      widths: ["*"],
      body: [[`${title}`]],
    },
  };
  return docDef;
};

export const marginTopBottom = () => {
  const docRef = {
    text: "",
    margin: [0, 6],
  };
  return docRef;
};

export const detailsTable = (
  displayName: string,
  bankName: string,
  identityNo: string,
  accNumber: string,
  department: string,
  branchName: string,
  position: string,
  accType: string,
  payrollMonth: string,
  branchNumber: string
) => {
  const docDef = {
    style: "detailTable",
    layout: {
      vLineColor: "grey",
      hLineColor: "grey",
      vLineWidth: function (i: any, node: any) {
        return 1;
      },
      hLineWidth: function (i: any, node: any) {
        return 1;
      },
    },
    table: {
      widths: ["*", "*", "*", "*"],
      body: [
        ["Employee Name ", `${displayName}`, "Bank Name ", `${bankName}`],
        ["Identity Number ", `${identityNo}`, "Account Number ", `${accNumber}`,],
        ["Department ", `${department}`, "Branch Name ", `${branchName}`],
        ["Designation ", `${position}`, "Branch Code ", `${branchNumber}`],
        ["Month ", `${payrollMonth}`, "Account Type ", `${accType}`],
      ],
    },
  };
  return docDef;
};

export const tableHeader = () => {
  const docDef = {
    style: "tableHead",
    layout: {
      vLineColor: "#616161",
      hLineColor: "#616161",
      vLineWidth: function (i: any, node: any) {
        return 0;
      },
      hLineWidth: function (i: any, node: any) {
        return 0;
      },
    },
    table: {
      widths: [200, "*", "*", "*"],
      body: [["Description ", "Hours", "Rate", "Amount"]],
    },
  };
  return docDef;
};

export const flexiDataTable = (
  name: string,
  hours: number,
  rate: number,
  amount: number
) => {
  const docDef = {
    style: "mapTable",
    layout: {
      vLineColor: "#f5f5f5",
      hLineColor: "#f5f5f5",
      vLineWidth: function (i: any, node: any) {
        return 0;
      },
      hLineWidth: function (i: any, node: any) {
        return 0;
      },
    },
    table: {
      widths: [200, "*", "*", "*"],
      body: [
        [`${name}`, `${hours}`, `${rate}`, `N$ ${CustomNumberFormat(amount)}`],
      ],
    },
  };
  return docDef;
};


export const fixedDataTable = (name: string, amount: number) => {
  const docDef = {
    style: "mapTable",
    layout: {
      vLineColor: "grey",
      hLineColor: "#f5f5f5",
      vLineWidth: function (i: any, node: any) {
        return 0;
      },
      hLineWidth: function (i: any, node: any) {
        return 0.5;
      },
    },
    table: {
      widths: [200, "*", "*", "*"],
      body: [[`${name}`, "", "", `N$ ${CustomNumberFormat(amount)}`]],
    },
  };
  return docDef;
};

export const totalsTable = (name: string, amount: number) => {
  const docDef = {
    style: "totalTable",
    layout: {
      vLineColor: "#616161",
      hLineColor: "#616161",
      vLineWidth: function (i: any, node: any) {
        return 0;
      },
      hLineWidth: function (i: any, node: any) {
        return 0.2;
      },
    },
    table: {
      widths: [200, "*", "*", "*"],
      body: [[`${name}`, "", "", `N$ ${CustomNumberFormat(amount)}`]],
    },
  };
  return docDef;
};

export const leaveDataTable = (type: string, days: number) => {
  const docDef = {
    style: "mapTable",
    layout: {
      vLineColor: "grey",
      hLineColor: "#f5f5f5",
      vLineWidth: function (i: any, node: any) {
        return 0;
      },
      hLineWidth: function (i: any, node: any) {
        return 0.5;
      },
    },
    table: {
      widths: [200, "*", "*", "*"],
      body: [[`${type}`, "", "", `${(days)} days`]],
    },
  };
  return docDef;
};

export const brandLogo = async () => {
  return {
    style: "brandLogo",
    image: await getBase64ImageFromURL(`${process.env.PUBLIC_URL}/Namene.svg`),
    fit: [76, 76],
  };
};

export const getBase64ImageFromURL = (url: string) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.setAttribute("crossOrigin", "anonymous");

    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext("2d");
      ctx?.drawImage(img, 0, 0);
      
      const dataURL = canvas.toDataURL("image/png");
      resolve(dataURL);
    };

    img.onerror = (error) => {
      console.log("Error loading image:", error);
      reject(error);
    };

    img.src = url;
  });
};

// export const getBase64ImageFromURL = (url: string, headers: Record<string, string> = {}) => {
//   return new Promise((resolve, reject) => {
//     const img = new Image();
//     img.setAttribute("crossOrigin", "anonymous");

//     img.onload = () => {
//       const canvas = document.createElement("canvas");
//       canvas.width = img.width;
//       canvas.height = img.height;

//       const ctx = canvas.getContext("2d");
//       if (ctx) {
//         const xhr = new XMLHttpRequest();
//         xhr.open("GET", url);
//         xhr.responseType = "blob";
//         for (const [key, value] of Object.entries(headers)) {
//           xhr.setRequestHeader(key, value);
//         }
//         xhr.onload = () => {
//           const blob = xhr.response;
//           const reader = new FileReader();
//           reader.readAsDataURL(blob);
//           reader.onloadend = () => {
//             const base64data = reader.result;
//             resolve(base64data as string);
//           };
//         };
//         xhr.onerror = (error) => {
//           reject(error);
//         };
//         xhr.send();
//       } else {
//         reject(new Error("Failed to create canvas context."));
//       }
//     };

//     img.onerror = (error) => {
//       reject(error);
//     };
//     img.src = url;
//   });
// };
